import { useReactTable } from '@tanstack/react-table';
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  ColDefBuilder,
  NewButton,
  ServerDataTable,
  TableSearch,
  VStack,
  useDataTableForQuery,
} from '@eluve/components';
import { ExternalArtifactTypesLookup } from '@eluve/graphql-types';
import { ResultOf, graphql } from '@eluve/graphql.tada';

export interface PatientDetailsImportedNotesProps {}

const searchImportedNotesQuery = graphql(`
  query searchImportedNotes(
    $filter: ExternalPatientArtifactsBoolExp
    $offset: Int
    $orderBy: [ExternalPatientArtifactsOrderBy!]
    $limit: Int
  ) {
    externalPatientArtifacts(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      id
      name
      externalArtifactType
      externalArtifactCreatedAt
    }
    externalPatientArtifactsAggregate(where: $filter) {
      __typename
      aggregate {
        __typename
        count
      }
    }
  }
`);

type ImportedChartsRow = {
  id: string;
  name: string | null;
  externalArtifactCreatedAt: string | null;
};

const columns = new ColDefBuilder<ImportedChartsRow>()
  .defaultSortable('name')
  .dateSortable('externalArtifactCreatedAt', 'Created At')
  .colDef({
    header: 'Actions',
    cell: () => {
      // TODO(jesse)[ELU-3458]: Implement PDF viewer
      return <NewButton text="View PDF" disabled />;
    },
  })
  .build();

const convertSearchResultsToRows = (
  results: ResultOf<typeof searchImportedNotesQuery>,
): ImportedChartsRow[] => {
  return results.externalPatientArtifacts.map<ImportedChartsRow>((p) => {
    return {
      id: p.id,
      name: p.name,
      externalArtifactCreatedAt: p.externalArtifactCreatedAt,
    };
  });
};

export const PatientDetailsImportedNotes: React.FC<
  PatientDetailsImportedNotesProps
> = () => {
  const { tenantId, patientId } = useParams() as {
    tenantId: string;
    patientId: string;
  };

  const { data, rows, search, setSearch, reactTableOptions, isPending } =
    useDataTableForQuery({
      query: searchImportedNotesQuery,
      convertToRows: convertSearchResultsToRows,
      convertSearchParamsToVariables: ({ limit, offset, search, sorting }) => {
        return {
          filter: {
            _and: [
              {
                tenantId: { _eq: tenantId },
                patientId: { _eq: patientId },
                externalArtifactType: {
                  _eq: ExternalArtifactTypesLookup.HISTORICAL_CHART,
                },
              },

              ...(search ? [{ name: { _ilike: `%${search}%` } }] : []),
            ],
          },
          offset,
          limit,
          orderBy: sorting.map((s) => ({
            [s.id]: s.desc ? 'DESC_NULLS_LAST' : 'ASC_NULLS_LAST',
          })),
        };
      },
    });

  const rowCount = data?.externalPatientArtifactsAggregate?.aggregate?.count;

  const table = useReactTable({
    data: rows,
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <VStack gap={0} className="rounded-md border">
      <TableSearch
        value={search ?? ''}
        onChange={setSearch}
        placeholder="Search by name..."
      />
      <ServerDataTable<ImportedChartsRow> table={table} isPending={isPending} />
    </VStack>
  );
};
