import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';
import { validate as validUUID } from 'uuid';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  H4,
  HStack,
  Input,
  NewButton,
  P,
  RadioGroup,
  RadioGroupItem,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { useTenantIdFromParams } from '@eluve/session-helpers';

enum AssetDeletionRequestTypesEnum {
  AUDIO_FILES = 'audio-files',
  ALL_ASSETS = 'all-assets',
}

export const SubmitAssetDeletionRequestPage: React.FC = () => {
  const logger = useNamedLogger('AssetDeletionDash');
  const tenantId = useTenantIdFromParams();
  const [requestType, setRequestType] = useState<AssetDeletionRequestTypesEnum>(
    AssetDeletionRequestTypesEnum.AUDIO_FILES,
  );
  const [appointmentId, setAppointmentId] = useState<string>('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const apiClient = useApiClient();

  const submitAssetDeletionRequest = async () => {
    logger.debug('submitAssetDeletionRequest');

    if (!appointmentId || !tenantId) {
      return;
    }

    setIsSubmitting(true);

    const deletionRequest = match(requestType)
      .with(AssetDeletionRequestTypesEnum.AUDIO_FILES, () => {
        return apiClient.adminAppointment.deleteAppointmentAudioFiles({
          body: {},
          params: {
            tenantId,
            appointmentId,
          },
        });
      })
      .with(AssetDeletionRequestTypesEnum.ALL_ASSETS, () => {
        return apiClient.adminAppointment.deleteAppointmentAssets({
          body: {},
          params: {
            tenantId,
            appointmentId,
          },
        });
      })
      .exhaustive();

    const response = await deletionRequest;

    if (response.status === 201) {
      setAppointmentId('');
      toast.success('Success!', {
        description: 'Job scheduled for deletion',
      });
    }

    if (response.status === 400) {
      toast.error('Failed to submit asset deletion request', {
        description: response.body.message,
      });
    }

    setIsSubmitting(false);
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      {/* SUBMIT DELETION FORM */}
      <VStack gap={8}>
        <H4>Submit an asset deletion request </H4>

        <VStack gap={2}>
          <p className={textStyles.body({ size: 'l' })}>
            Select which assets should be deleted
          </p>
          <RadioGroup
            defaultValue="audio-files"
            onValueChange={(value: AssetDeletionRequestTypesEnum) => {
              setRequestType(value);
            }}
          >
            <HStack justify="start" align="start">
              <RadioGroupItem
                value={AssetDeletionRequestTypesEnum.AUDIO_FILES}
                className="mt-1"
              />
              <VStack gap={0}>
                <p
                  className={textStyles.body({
                    size: 'm',
                    weight: 'bold',
                  })}
                >
                  Delete Audio Files Only
                </p>
                <p className={textStyles.body({ size: 'm' })}>
                  Permanently deletes audio files associated with the given
                  appointment. This job is executed 1 day from the time it is
                  submitted.
                </p>
              </VStack>
            </HStack>

            <HStack justify="start" align="start">
              <RadioGroupItem
                value={AssetDeletionRequestTypesEnum.ALL_ASSETS}
                className="mt-1"
              />
              <VStack gap={0}>
                <p className={textStyles.body({ size: 'm', weight: 'bold' })}>
                  Delete Audio and Transcrips
                </p>
                <p className={textStyles.body({ size: 'm' })}>
                  Permanently deletes audio files and transcripts associated
                  with the given appointment. This job is executed 5 days from
                  the time it is submitted.
                </p>
              </VStack>
            </HStack>
          </RadioGroup>
        </VStack>
        <VStack gap={2}>
          <p className={textStyles.body({ size: 'l' })}>
            Enter the appointment ID
          </p>
          <Input
            value={appointmentId}
            onChange={(e) => setAppointmentId(e.target.value)}
            className="w-[400px]"
          />
        </VStack>

        <NewButton
          text="Submit Deletion Request"
          onClick={() => setConfirmationDialogOpen(true)}
          disabled={!validUUID(appointmentId)}
        />
      </VStack>

      {/* SUBMIT DELETION DIALOG */}
      <Dialog
        open={confirmationDialogOpen}
        onOpenChange={setConfirmationDialogOpen}
      >
        <DialogContent className="gap-6">
          <H2>Are you sure?</H2>
          <P>
            Are you sure you want to delete{' '}
            {requestType === AssetDeletionRequestTypesEnum.ALL_ASSETS ? (
              <span className="text-negative600">
                all audio files and transcripts
              </span>
            ) : (
              <span className="text-negative600">all audio files</span>
            )}{' '}
            for appointment{' '}
            <Link
              to={`/admin/tenant/${tenantId}/appointment/${appointmentId}`}
              className="text-positive600"
            >
              {appointmentId}
            </Link>
            ?
          </P>
          <P className="text-xs">
            If yes, this job will be scheduled for execution{' '}
            <span className="text-negative600">
              {requestType === AssetDeletionRequestTypesEnum.ALL_ASSETS
                ? '5 days from now'
                : 'tomorrow'}
            </span>
            . If you'd like to cancel the job before it runs, you can delete the
            entry from the <code>graphile_worker.jobs</code> database table.
          </P>
          <DialogFooter>
            <NewButton
              type="subtle"
              disabled={isSubmitting}
              onClick={() => setConfirmationDialogOpen(false)}
              text="Cancel"
            />
            <NewButton
              onClick={submitAssetDeletionRequest}
              disabled={isSubmitting}
              text="Submit Request"
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
