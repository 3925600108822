import React, { useState } from 'react';

import { AudioPlayer } from '@eluve/audio-player';
import {
  HStack,
  NewButton,
  P,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@eluve/components';

import { useSegmentAudioContext } from './SegmentAudioContextStore';

export interface SegmentAudioControlsProps {
  audioUrl: string;
}

export const SegmentAudioControls: React.FC<SegmentAudioControlsProps> = ({
  audioUrl,
}) => {
  const { store, audioPlayerHandle } = useSegmentAudioContext();
  const [jumpDuration, setJumpDuration] = useState(10);

  return (
    <HStack gap={6}>
      <AudioPlayer
        audioUrl={audioUrl}
        ref={audioPlayerHandle}
        onTimeUpdate={(timestamp) => {
          store.send({ type: 'updateTimestamp', timestamp });
        }}
      />
      <HStack wFull={false}>
        <P>Jump:</P>
        <Select
          value={jumpDuration.toString()}
          onValueChange={(value) => setJumpDuration(Number(value))}
        >
          <SelectTrigger className="w-fit">
            <SelectValue placeholder="Jump" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={'1'}>1</SelectItem>
            <SelectItem value={'3'}>3</SelectItem>
            <SelectItem value={'5'}>5</SelectItem>
            <SelectItem value={'10'}>10</SelectItem>
            <SelectItem value={'20'}>20</SelectItem>
            <SelectItem value={'30'}>30</SelectItem>
          </SelectContent>
        </Select>
        <NewButton
          text={jumpDuration.toString()}
          icon={{
            name: 'Rewind',
          }}
          onClick={() => audioPlayerHandle.current?.rewind(jumpDuration)}
        />

        <NewButton
          text={jumpDuration.toString()}
          icon={{
            name: 'FastForward',
          }}
          onClick={() => audioPlayerHandle.current?.fastForward(jumpDuration)}
        />
      </HStack>
      <HStack wFull={false}>
        <P>Playback speed:</P>
        <Select
          defaultValue="1"
          onValueChange={(value) =>
            audioPlayerHandle.current?.setPlaybackSpeed(Number(value))
          }
        >
          <SelectTrigger className="w-fit">
            <SelectValue placeholder="Speed" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={'0.5'}>0.5x</SelectItem>
            <SelectItem value={'0.75'}>0.75x</SelectItem>
            <SelectItem value={'1'}>1x</SelectItem>
            <SelectItem value={'1.25'}>1.25x</SelectItem>
            <SelectItem value={'1.5'}>1.5x</SelectItem>
            <SelectItem value={'2'}>2x</SelectItem>
          </SelectContent>
        </Select>
      </HStack>
    </HStack>
  );
};
