import S, { ObjectSchema } from 'fluent-json-schema';
import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';
import { z } from 'zod';

import { AppointmentSummary } from '@eluve/utils';

const baseBlock = z.object({
  key: z.string(),
  label: z.string(),
  isRequired: z.boolean().optional(),
  description: z.string().optional(),
  importedDescription: z.string().optional(),
  isAiEnabled: z.boolean().optional(),
  isEmailEnabled: z.boolean().optional(),
});

const textField = baseBlock.extend({
  type: z.literal('text'),
  placeholderText: z.string().optional(),
  text: z.string().optional(),
});

export type TextFieldBlock = z.infer<typeof textField>;

const checkbox = baseBlock.extend({
  type: z.literal('checkbox'),
  options: z.array(
    z.object({
      label: z.string(),
      isChecked: z.boolean(),
    }),
  ),
});

export type CheckboxBlock = z.infer<typeof checkbox>;

const range = baseBlock.extend({
  type: z.literal('range'),
  min: z.number(),
  max: z.number(),
  step: z.number(),
  value: z.number().optional(),
});

export type RangeBlock = z.infer<typeof range>;

const groupableBlocks = z.discriminatedUnion('type', [
  textField,
  checkbox,
  range,
]);

type GroupableBlocks = z.infer<typeof groupableBlocks>;

type RecursiveGroup = z.infer<typeof baseBlock> & {
  type: 'group';
  blocks: Array<RecursiveGroup | GroupableBlocks>;
};

const group: z.ZodType<RecursiveGroup> = baseBlock.extend({
  type: z.literal('group'),
  blocks: z.array(z.union([groupableBlocks, z.lazy(() => group)])),
});

export type GroupBlock = z.infer<typeof group>;

const block = z.union([group, groupableBlocks]);
export type Block = z.infer<typeof block>;
export type BlockType = Block['type'];

export const dynamicArtifactTemplateSchema = z.object({
  name: z.string().default('Dynamic Artifact'),
  description: z.string().optional(),
  importedDescription: z.string().optional(),
  blocks: z.array(block).default([]),
  variantNotes: z.string().optional(),
});

export type DynamicArtifactTemplate = z.infer<
  typeof dynamicArtifactTemplateSchema
>;

/**
 * Given a dynamic artifact template and data, hydrate the template with the data
 * by recursively iterating through the blocks and setting the values from the corresponding
 * keys on the data
 */
export const hydrateDynamicArtifactTemplate = (
  template: DynamicArtifactTemplate,
  data: Record<string, unknown>,
) => {
  const mapDataToBlocks = (blocks: Block[], data: Record<string, unknown>) => {
    blocks.forEach((block) => {
      const isAiEnabled = block.isAiEnabled ?? true;
      switch (block.type) {
        case 'group': {
          if (data[block.key]) {
            mapDataToBlocks(
              block.blocks,
              data[block.key] as Record<string, unknown>,
            );
          }
          break;
        }
        case 'text': {
          block.text = isAiEnabled
            ? (data[block.key] as string)
            : block.description ?? block.importedDescription;
          break;
        }
        case 'checkbox': {
          const optionsFromData = data[block.key] as {
            label: string;
            isChecked: boolean;
          }[];
          block.options.forEach((option) => {
            const matchingOption = optionsFromData?.find(
              (o) => o.label === option.label,
            );
            option.isChecked = Boolean(matchingOption?.isChecked);
          });
          break;
        }
        case 'range': {
          block.value = data[block.key] as number;
          break;
        }
      }
    });
  };

  const result = produce(template, (draft) => {
    mapDataToBlocks(draft.blocks, data);
  });

  return result;
};

export const convertDynamicArtifactToJsonSchema = (
  artifact: DynamicArtifactTemplate,
  options: { strict?: boolean } = {},
): ObjectSchema<Record<string, any>> => {
  const { blocks } = artifact;
  const { strict = true } = options;
  const schema = S.object()
    .description(artifact.description ?? artifact.name)
    .additionalProperties(false);

  /* Only a subset of JSON schema is supported: https://platform.openai.com/docs/guides/structured-outputs/supported-schemas
   *
   * Unsupported as of 10/17/2024
   * For strings: minLength, maxLength, pattern, format
   * For numbers: minimum, maximum, multipleOf
   * For objects: patternProperties, unevaluatedProperties, propertyNames, minProperties, maxProperties
   * For arrays: unevaluatedItems, contains, minContains, maxContains, minItems, maxItems, uniqueItems
   */
  const convertBlocksToJsonSchema = (blocks: Block[], schema: ObjectSchema) => {
    for (const block of blocks) {
      const { key, isRequired = false } = block;

      const description = !isEmpty(block.description)
        ? block.description
        : block.importedDescription;
      const isAiEnabled = block.isAiEnabled ?? true;

      if (!isAiEnabled) {
        continue;
      }

      switch (block.type) {
        case 'group': {
          let groupObj = S.object().additionalProperties(false);

          groupObj = description ? groupObj.description(description) : groupObj;

          const result = convertBlocksToJsonSchema(block.blocks, groupObj);

          schema = schema.prop(key, result);
          if (strict) {
            schema = schema.required();
          }

          break;
        }
        case 'text': {
          let prop = S.string();
          prop = description ? prop.description(description) : prop;
          schema = schema.prop(
            key,
            isRequired ? prop : S.anyOf([prop, S.null()]),
          );

          if (strict) {
            schema = schema.required();
          }
          break;
        }
        case 'checkbox': {
          const { options } = block;
          const optionLabels = options
            ? options.map((option: { label: string }) => option.label)
            : [];

          let prop = S.array().items(
            S.object()
              .additionalProperties(false)
              .prop('label', S.string().enum(optionLabels))
              .prop('isChecked', S.boolean())
              .required(['label', 'isChecked']),
          );

          prop = description ? prop.description(description) : prop;

          schema = schema.prop(key, prop);
          if (strict) {
            schema = schema.required();
          }

          break;
        }
        case 'range': {
          const { min, max } = block;
          let prop = S.number();
          const rangeDefaultDescription = `Range from ${min} to ${max}.`;
          const propDescription = description
            ? `${rangeDefaultDescription} ${description}`
            : rangeDefaultDescription;
          prop = prop.description(propDescription);

          schema = schema.prop(
            key,
            isRequired ? prop : S.anyOf([prop, S.null()]),
          );
          if (strict) {
            schema = schema.required();
          }

          break;
        }
      }
    }
    return schema;
  };

  const resultSchema = convertBlocksToJsonSchema(blocks, schema);

  return resultSchema;
};

export type ClassicSummary = {
  type: 'SOAP';
  data: AppointmentSummary | null;
};

export type DynamicSummary = {
  type: 'DYNAMIC';
  blocks: Block[];
};

export const mergeDynamicArtifactDescriptions = (
  newDynamicArtifact: DynamicArtifactTemplate,
  existingDynamicArtifact: DynamicArtifactTemplate,
): DynamicArtifactTemplate => {
  const updateDescriptions = (
    newBlocks: Block[],
    existingBlocks: Block[],
  ): Block[] => {
    return newBlocks.map((newBlock) => {
      const existingBlock = existingBlocks.find(
        (block) => block.key === newBlock.key,
      );

      if (existingBlock) {
        const updatedBlock: Block = {
          ...newBlock,
          description: existingBlock.description,
          importedDescription:
            newBlock.importedDescription ?? existingBlock.importedDescription,
        };

        if (newBlock.type === 'group' && existingBlock.type === 'group') {
          (updatedBlock as GroupBlock).blocks = updateDescriptions(
            (newBlock as GroupBlock).blocks,
            (existingBlock as GroupBlock).blocks,
          );
        }

        return updatedBlock;
      }

      return newBlock;
    });
  };

  return {
    ...newDynamicArtifact,
    description: existingDynamicArtifact.description,
    blocks: updateDescriptions(
      newDynamicArtifact.blocks,
      existingDynamicArtifact.blocks,
    ),
  };
};

export const flattenBlocks = (blocks: Block[], label = ''): Block[] => {
  const flattenedBlocks: Block[] = [];
  for (const block of blocks) {
    if (block.type === 'group') {
      const newLabel = label ? `${label} - ${block.label}` : block.label;
      flattenedBlocks.push(...flattenBlocks(block.blocks, newLabel));
    } else {
      const newLabel = label ? `${label} - ${block.label}` : block.label;
      flattenedBlocks.push({ ...block, label: newLabel });
    }
  }
  return flattenedBlocks;
};
