import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { ColDefBuilder, DataTable } from '@eluve/components';
import {
  AssetDeletionJobTypesEnum,
  WorkerStatusTypesEnum,
} from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

const assetDeletionJobFragment = graphql(`
  fragment AssetDeletionJob on AssetDeletionJobs @_unmask {
    __typename
    id
    jobType
    tenantId
    tenant {
      name
    }
    appointmentId
    status
    scheduledToRunAt
    scheduledBy
    user {
      firstName
      lastName
    }
    numAttempts
    createdAt
    startedAt
    completedAt
    deletionReport
  }
`);

const getAssetDeletionJobsForTenantQuery = graphql(
  `
    query getAssetDeletionForTenantJobs($tenantId: uuid!) {
      assetDeletionJobs(
        where: { tenantId: { _eq: $tenantId } }
        limit: 50
        orderBy: { createdAt: DESC }
      ) {
        ...AssetDeletionJob
      }
    }
  `,
  [assetDeletionJobFragment],
);

const getAssetDeletionJobsQuery = graphql(
  `
    query getAssetDeletionJobs {
      assetDeletionJobs(limit: 50, orderBy: { createdAt: DESC }) {
        ...AssetDeletionJob
      }
    }
  `,
  [assetDeletionJobFragment],
);

type JobRow = {
  id: string;
  jobType: AssetDeletionJobTypesEnum;
  tenantId: string;
  tenantName: string;
  appointmentId: string | null;
  status: WorkerStatusTypesEnum;
  scheduledBy: string;
  scheduledById: string;
  scheduledToRunAt: string;
  numAttempts: number | null;
  createdAt: string;
  startedAt: string | null;
  completedAt: string | null;
  deletionReport: Record<string, any>;
};

export const AssetDeletionRequestsTable: React.FC<{
  tenantId?: string;
}> = ({ tenantId }) => {
  const { data } = useQuery(
    tenantId ? getAssetDeletionJobsForTenantQuery : getAssetDeletionJobsQuery,
    {
      variables: tenantId ? { tenantId } : undefined,
    },
  );

  const jobsColumns = new ColDefBuilder<JobRow>()
    .defaultSortable('id', 'Job Id')
    .linkSortable(
      'tenantName',
      (row) => `/admin/tenants/${row.tenantId}`,
      'Tenant',
    )
    .linkSortable(
      'appointmentId',
      (row) =>
        `/admin/tenants/${row.tenantId}/appointments/${row.appointmentId}`,
      'Appointment',
    )
    .defaultSortable('jobType', 'Job Type')
    .defaultSortable('status', 'Job Status')

    .linkSortable(
      'scheduledBy',
      (row) => `/admin/users/${row.scheduledById}`,
      'Scheduled By',
    )
    .dateSortable('scheduledToRunAt', 'Scheduled To Run At')
    .dateSortable('createdAt', 'Created At')
    .dateSortable('startedAt', 'Started At')
    .dateSortable('completedAt', 'Completed At')
    .defaultSortable('numAttempts', '# Attempts')
    .defaultSortable('deletionReport', {
      label: 'Deletion Report',
      cellRenderer: (row) => {
        return (
          <code>
            <pre>{JSON.stringify(row.deletionReport, null, 2)}</pre>
          </code>
        );
      },
    })
    .build();

  const rows = useMemo(() => {
    return (data?.assetDeletionJobs ?? []).map<JobRow>((r) => ({
      id: r.id,
      jobType: r.jobType,
      tenantId: r.tenantId,
      tenantName: r.tenant.name,
      appointmentId: r.appointmentId,
      status: r.status,
      scheduledBy: `${r.user.firstName} ${r.user.lastName}`,
      scheduledById: r.scheduledBy,
      scheduledToRunAt: r.scheduledToRunAt,
      numAttempts: r.numAttempts,
      createdAt: r.createdAt,
      startedAt: r.startedAt,
      completedAt: r.completedAt,
      deletionReport: r.deletionReport,
    }));
  }, [data]);

  return (
    <DataTable
      data={rows}
      columns={jobsColumns}
      isPaginated={false}
      enableGlobalSearch
      isCompact
      initialSortingState={[
        {
          id: 'createdAt',
          desc: true,
        },
      ]}
    />
  );
};
