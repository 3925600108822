import { useMutation } from '@apollo/client';
import { useSelector } from '@xstate/store/react';
import { useState } from 'react';

import { cacheUtils } from '@eluve/apollo-client';
import { toast } from '@eluve/components';
import { useDialog } from '@eluve/utility-hooks';

import { useSegmentAudioContext } from './SegmentAudioContextStore';
import {
  segmentUtterancesFragment,
  updateSpeakerNameMutation,
  updateUtteranceSpeakersMutation,
} from './operations';

export const useSegmentSpeakerControls = ({
  tenantId,
  segmentId,
  initialSpeakerNames = [],
}: {
  tenantId: string;
  segmentId: string;
  initialSpeakerNames?: string[];
}) => {
  const { store } = useSegmentAudioContext();
  const [speakerNames, setSpeakerNames] = useState<Set<string>>(
    new Set(initialSpeakerNames),
  );
  const [newSpeakerName, setNewSpeakerName] = useState('');
  const [renameSpeakerTo, setRenameSpeakerTo] = useState('');

  const { isOpen: isNewSpeakerOpen, toggle: toggleIsNewSpeakerOpen } =
    useDialog();

  const { isOpen: isSelectSpeakerOpen, toggle: toggleIsSelectSpeakerOpen } =
    useDialog();

  const selectedUtteranceIds = useSelector(
    store,
    (state) => state.context.selectedUtteranceIds,
  );

  const isReadonly = useSelector(store, (state) => state.context.isReadonly);

  const [updateSpeakerNameTo] = useMutation(updateSpeakerNameMutation, {
    onCompleted: () => {
      setRenameSpeakerTo('');
    },
  });

  const [updateUtterancesSpeaker] = useMutation(
    updateUtteranceSpeakersMutation,
    {
      onCompleted: () => {
        store.send({ type: 'clearSelectedUtteranceIds' });
        toggleIsSelectSpeakerOpen();
      },
      onError: () => toast.error('Failed to update speakers'),
      optimisticResponse: ({ speaker }) => ({
        updateUtterances: {
          returning: selectedUtteranceIds.map((id) => ({
            __typename: 'Utterances' as const,
            id,
            speaker,
          })),
        },
      }),
    },
  );

  const onUpdateSpeakerForUtterances = async (speaker: string) => {
    if (isReadonly) return;

    updateUtterancesSpeaker({
      variables: {
        tenantId,
        ids: selectedUtteranceIds,
        speaker,
      },
    });

    setSpeakerNames((prev) => new Set([...prev, speaker]));
  };

  /**
   * Updates a speaker's name which will affect
   * all utterances currently associatd with that speaker
   */
  const onRenameSpeaker = async (oldSpeakerName: string) => {
    if (isReadonly) return;

    const { utterances } = cacheUtils.readFragment({
      fragment: segmentUtterancesFragment,
      key: { id: segmentId },
    });

    const toUpdate = utterances.filter((u) => u.speaker === oldSpeakerName);

    updateSpeakerNameTo({
      variables: {
        tenantId,
        segmentId: segmentId,
        oldSpeakerName,
        newSpeakerName: renameSpeakerTo,
      },
      optimisticResponse: () => ({
        updateUtterances: {
          returning: toUpdate.map((u) => ({
            __typename: 'Utterances',
            id: u.id,
            speaker: renameSpeakerTo,
          })),
        },
      }),
    });

    setSpeakerNames(
      (prev) =>
        new Set(
          [...prev].map((name) =>
            name === oldSpeakerName ? renameSpeakerTo : name,
          ),
        ),
    );
  };

  return {
    onUpdateSpeakerForUtterances,
    onRenameSpeaker,
    isSelectSpeakerOpen,
    toggleIsSelectSpeakerOpen,
    newSpeakerName,
    setNewSpeakerName,
    renameSpeakerTo,
    setRenameSpeakerTo,
    speakerNames,
    setSpeakerNames,
    isNewSpeakerOpen,
    toggleIsNewSpeakerOpen,
  };
};
