export const formatSecondsToTime = (
  timeInSeconds: number,
  verbose = false,
): string => {
  const formatted = (time: number) =>
    verbose ? time.toString() : time.toString().padStart(2, '0');

  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  if (verbose) {
    let time = '';
    if (hours !== 0) {
      time += `${hours} hour${hours === 1 ? '' : 's'} `;
    }
    if (minutes !== 0) {
      time += `${minutes} minute${minutes === 1 ? '' : 's'} `;
    }
    return time + `${seconds} second${seconds === 1 ? '' : 's'}`;
  }

  return hours !== 0
    ? `${formatted(hours)}:${formatted(minutes)}:${formatted(seconds)}`
    : `${formatted(minutes)}:${formatted(seconds)}`;
};
