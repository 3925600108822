import { TriangleAlertIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { match } from 'ts-pattern';

import {
  Box,
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@eluve/components';
import { usePermission } from '@eluve/mic-utils';

type MicrophoneOption = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

interface MicrophoneDeviceSelectorProps {
  onDeviceValueChange?: (deviceId: string) => void;
  selectedMicId?: string | null;
  setHasPreviouslySelectedMicId?: (hasPreviouslySelectedMicId: boolean) => void;
}

export const MicrophoneDeviceSelector: React.FC<
  MicrophoneDeviceSelectorProps
> = ({ onDeviceValueChange, selectedMicId, setHasPreviouslySelectedMicId }) => {
  const state = usePermission({ name: 'microphone' });
  const [microphoneOptions, setMicrophoneOptions] = useState<
    MicrophoneOption[]
  >([]);

  const isPermissionApiSupported = navigator.permissions !== undefined;

  const loadMicrophoneOptions = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(
      (device) => device.kind === 'audioinput' && device.deviceId,
    );
    setMicrophoneOptions(audioDevices as MicrophoneOption[]);
  }, []);

  useEffect(() => {
    navigator.mediaDevices.addEventListener(
      'devicechange',
      loadMicrophoneOptions,
    );

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        loadMicrophoneOptions,
      );
    };
  }, [loadMicrophoneOptions]);

  useEffect(() => {
    if (state === 'granted') {
      loadMicrophoneOptions();
    }
  }, [loadMicrophoneOptions, state]);

  useEffect(() => {
    if (selectedMicId && setHasPreviouslySelectedMicId) {
      setHasPreviouslySelectedMicId(
        Boolean(
          microphoneOptions.find((mic) => mic.deviceId === selectedMicId),
        ),
      );
    }
  }, [microphoneOptions, selectedMicId, setHasPreviouslySelectedMicId]);

  let selectedMic = microphoneOptions.find(
    (mic) => mic.deviceId === selectedMicId,
  )?.label;

  if (selectedMicId && !selectedMic && microphoneOptions.length) {
    selectedMic = microphoneOptions.find((mic) => mic.label)?.label;
  }

  const selectedMicName = selectedMic ?? 'Select Microphone';

  const handleMicChange = (deviceId: string) => {
    onDeviceValueChange?.(deviceId);
  };

  const requestMicrophoneAccess = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((device) => {
      device.getTracks().forEach((track) => {
        track.stop();
      });

      loadMicrophoneOptions();
    });
  };

  return match({ state, microphoneOptions, isPermissionApiSupported })
    .with({ state: 'denied' }, () => (
      <Box
        vStack
        center
        fullWidth
        className="rounded-lg bg-negative100 p-4 text-negative600"
      >
        <TriangleAlertIcon size={24} />
        Microphone permissions denied
        <span className="text-xs">
          Please enable microphone permissions in your browser settings to
          enable audio input.
        </span>
      </Box>
    ))
    .with(
      { isPermissionApiSupported: true, state: 'prompt' },
      { isPermissionApiSupported: false, microphoneOptions: [] },
      () => (
        <Box
          vStack
          center
          fullWidth
          className="rounded-lg bg-warning100 p-4 text-warning600"
        >
          <TriangleAlertIcon size={24} />
          Microphone permissions required
          <span className="text-xs">
            Please grant microphone permissions to enable audio input.
          </span>
          <Button
            onClick={requestMicrophoneAccess}
            variant="gray"
            size="sm"
            className="cursor-pointer"
          >
            Request Permission
          </Button>
        </Box>
      ),
    )
    .otherwise(() => (
      <Select
        onValueChange={handleMicChange}
        onOpenChange={loadMicrophoneOptions}
      >
        <SelectTrigger>
          <SelectValue placeholder={selectedMicName} />
        </SelectTrigger>
        <SelectContent>
          {microphoneOptions.map((device) => (
            <SelectItem
              key={device.deviceId}
              className="pl-7 focus:bg-brand-4"
              value={device.deviceId}
            >
              {device.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    ));
};
