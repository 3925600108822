import { useApiClient } from '@eluve/api-client-provider';
import { VStack, toast } from '@eluve/components';
import {
  useAppointmentContext,
  useAppointmentStatus,
} from '@eluve/frontend-appointment-hooks';
import { SearchableDiagnoses } from '@eluve/frontend-feature-appointment';
import {
  AppointmentStatusTypesLookup,
  LlmOutputTypesLookup,
} from '@eluve/graphql-types';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import { DifferentialDiagnosesComponent } from './DifferentialDiagnosesComponent';

export const DifferentialDiagnosesTabContent: React.FC = () => {
  const { tenantId, appointmentId } = useAppointmentContext();
  const apiClient = useApiClient();
  const apptStatus = useAppointmentStatus();

  const onDiagnosesSelected = async (diagnosis: string) => {
    try {
      const result = await apiClient.llm.generateAppointmentOutput({
        params: { tenantId, appointmentId },
        body: {
          outputType: LlmOutputTypesLookup.DIAGNOSES_JUSTIFICATION,
          userInputs: {
            target_diagnosis: diagnosis,
          },
        },
      });

      if (result.status === 422) {
        toast.error(result.body.message);
        return;
      }

      if (result.status !== 201) {
        toast.error('Error generating output. Please try again later.');
        return;
      }
    } catch (e) {
      toast.error('Error generating output. Please try again later.');
    }
  };

  return (
    <VStack gap={3}>
      {apptStatus !== AppointmentStatusTypesLookup.COMPLETED && (
        <FeatureFlaggedComponent flag="USER_INPUT_DIFFERENTIAL_DIAGNOSIS">
          <SearchableDiagnoses
            onItemSelected={(dx) => onDiagnosesSelected(dx.name)}
          />
        </FeatureFlaggedComponent>
      )}
      <DifferentialDiagnosesComponent />
    </VStack>
  );
};
