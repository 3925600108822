import isString from 'lodash/isString';

type NestedObj = Record<
  string,
  string | string[] | Record<string, string | Record<string, any>>
>;

const isNestedObj = (value: unknown): value is NestedObj => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

const trimmed = (value: unknown) =>
  isString(value) ? value.trim() : undefined;

/**
 * converts an object key value pair to a markdown string and filters out any empty value or key pairs
 * @example
 * ```ts
 * {
 *  key: 'value',
 *  '': 'value3'
 *  'key4': ''
 *  key2: 'value2'
 * }
 * ```
 * will return
 * ```ts
 * [
 * 'key: value',
 * 'key2: value2'
 * ]
 */
const nestedObjToMarkdownChunks = (value: NestedObj): string[] => {
  const markdownChunks: string[] = [];

  for (const [subKey, subValue] of Object.entries(value)) {
    const trimmedValue = trimmed(subValue);
    const trimmedKey = trimmed(subKey);

    if (!trimmedValue || !trimmedKey) {
      continue;
    }

    markdownChunks.push(`${trimmedKey}: ${trimmedValue}`);
  }

  return markdownChunks;
};

/**
 * Converts a nested JS object to an object with top levels keys + markdown
 * { SUBJECTIVE: ['thing 1', 'thing 2']} => {SUBJECTIVE: "* thing 1\n*thing 2\n"}
 * See various use cases in the test suites
 * @param pojo
 * @returns Object with markdown formatted strings as values
 */
export const nestedObjToMarkdown = (
  pojo: Record<string, any>,
): Record<string, string> => {
  const markdownObject: Record<string, string> = {};

  for (const firstLevelKey in pojo) {
    let markdown = '';

    const firstLevelValue = pojo[firstLevelKey];

    if (typeof firstLevelValue === 'string') {
      markdown = firstLevelValue;
    } else if (isNestedObj(firstLevelValue)) {
      for (const secondLevelKey in firstLevelValue) {
        const secondLevelValue = firstLevelValue[secondLevelKey];

        if (!secondLevelValue) {
          continue;
        }

        if (Array.isArray(secondLevelValue) && secondLevelValue.length > 0) {
          const markdownSecondLevelChunks: string[] = [];

          // Append the list items in markdown format
          for (const listItem of secondLevelValue) {
            if (isString(listItem)) {
              const trimmedListItem = trimmed(listItem);
              if (trimmedListItem) {
                markdownSecondLevelChunks.push(`* ${trimmedListItem}`);
              }
            } else if (isNestedObj(listItem)) {
              markdownSecondLevelChunks.push(
                ...nestedObjToMarkdownChunks(listItem),
              );
            } else {
              // This probably shouldn't happen
              markdownSecondLevelChunks.push(`? ${listItem}`);
            }
          }

          if (markdownSecondLevelChunks.length) {
            // Append the second level key as markdown header
            markdown += `## ${secondLevelKey}\n`;
            markdown += `${markdownSecondLevelChunks.join('\n')}\n`;
          }
        } else if (isNestedObj(secondLevelValue)) {
          const markdownChunks = nestedObjToMarkdownChunks(secondLevelValue);
          if (markdownChunks.length) {
            markdown += `${markdownChunks.join('\n')}\n`;
          }
        } else if (trimmed(secondLevelValue)) {
          markdown += `## ${secondLevelKey}\n`;
          markdown += `* ${trimmed(secondLevelValue)}\n`;
        }
      }
      markdown += '\n';
    } else if (Array.isArray(firstLevelValue) && firstLevelValue.length) {
      for (const listItem of firstLevelValue) {
        markdown += `* ${listItem}\n`;
      }
    }

    markdownObject[firstLevelKey] = markdown;
  }

  return markdownObject;
};
