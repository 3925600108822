import React from 'react';

import { HStack, LinkButton, PageTitle } from '@eluve/components';
import { PromptTemplatesList } from '@eluve/frontend-feature-prompt-templates';

export interface PromptTemplatesListPageProps {}

export const PromptTemplatesListPage: React.FC<
  PromptTemplatesListPageProps
> = () => {
  return (
    <>
      <HStack justify="between">
        <div className="flex-1">
          <PageTitle>Prompt Templates</PageTitle>
        </div>
        <LinkButton
          to="create"
          text="Create a Prompt Template"
          icon={{ name: 'Plus' }}
        />
      </HStack>
      <PromptTemplatesList />
    </>
  );
};
