import React from 'react';
import { Outlet } from 'react-router-dom';

import { PageTitle } from '@eluve/components';
import { TabNav } from '@eluve/smart-blocks';

export const AssetDeletionDash: React.FC = () => {
  return (
    <div>
      <PageTitle>Asset Deletion Dash</PageTitle>

      <TabNav
        tabs={[
          {
            name: 'Submit New Request',
            link: 'new',
          },
          {
            name: 'Jobs History',
            link: 'history',
          },
        ]}
      />
      <div className="mt-4">
        <Outlet />
      </div>
    </div>
  );
};
