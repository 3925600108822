import React, { useState } from 'react';

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  HStack,
  Icon,
  NewButton,
  Skeleton,
  VStack,
  textStyles,
} from '@eluve/components';
import {
  DifferentialDiagnosisOutputSchema,
  differentialDiagnosesSchema,
} from '@eluve/llm-outputs';

import { OutputTitle } from './OutputTitle';

export interface DifferentialDiagnosesCardProps {
  diagnosis: string;
  positiveIndicators: string[];
  negativeIndicators: string[];
  unknownIndicators?: string[];
  onFeedback?: (isHelpful: boolean, diagnosis: string) => void;
  isOpenByDefault?: boolean;
}

export const DifferentialDiagnosisCard: React.FC<
  DifferentialDiagnosesCardProps
> = ({
  diagnosis,
  positiveIndicators,
  negativeIndicators,
  unknownIndicators,
  onFeedback,
  isOpenByDefault = false,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const [feedbackGiven, setFeedbackGiven] = useState<boolean>(false);
  const [isHelpful, setIsHelpful] = useState<boolean>(false);

  const feedbackHandler = (isHelpful: boolean) => {
    setFeedbackGiven(true);
    setIsHelpful(isHelpful);
    onFeedback?.(isHelpful, diagnosis);
  };

  const hasPositiveIndicators = positiveIndicators.length > 0;
  const hasNegativeIndicators = negativeIndicators.length > 0;
  const hasUnknownIndicators =
    unknownIndicators && unknownIndicators.length > 0;

  if (!hasPositiveIndicators && !hasNegativeIndicators) {
    return null;
  }

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      key={diagnosis}
      className="w-full rounded-xl bg-backgroundSecondary px-4 py-2"
    >
      <HStack
        justify="between"
        className={`hover:cursor-pointer ${isOpen ? 'pb-1' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p
          className={textStyles.body({
            size: 'm',
            color: 'secondary',
          })}
        >
          {diagnosis}
        </p>
        <CollapsibleTrigger asChild>
          <NewButton
            type="ghost"
            size="l"
            icon={{ name: isOpen ? 'ChevronUp' : 'ChevronDown' }}
          />
        </CollapsibleTrigger>
      </HStack>

      <CollapsibleContent>
        <VStack gap={5}>
          <div className="h-[1px] w-full bg-brandGray200" />

          {hasPositiveIndicators && (
            <VStack gap={2}>
              <p
                className={textStyles.label({
                  size: 'l',
                  weight: 'bold',
                  color: 'supporting',
                })}
              >
                EVIDENCE FOR
              </p>

              {positiveIndicators.map((indicator) => (
                <HStack key={indicator} gap={2}>
                  <Icon name="Check" size="xs" className="text-positive600" />
                  <span
                    className={textStyles.body({
                      weight: 'medium',
                      size: 'm',
                      color: 'secondary',
                    })}
                  >
                    {indicator}
                  </span>
                </HStack>
              ))}
            </VStack>
          )}

          {hasNegativeIndicators && (
            <VStack gap={2}>
              <p
                className={textStyles.label({
                  size: 'l',
                  weight: 'bold',
                  color: 'supporting',
                })}
              >
                EVIDENCE AGAINST
              </p>

              {negativeIndicators.map((indicator) => (
                <HStack key={indicator} gap={2}>
                  <Icon name="X" size="xs" className="text-brandGray800" />
                  <span
                    className={textStyles.body({
                      weight: 'medium',
                      size: 'm',
                      color: 'secondary',
                    })}
                  >
                    {indicator}
                  </span>
                </HStack>
              ))}
            </VStack>
          )}

          {hasUnknownIndicators && (
            <VStack gap={2}>
              <p
                className={textStyles.label({
                  size: 'l',
                  weight: 'bold',
                  color: 'supporting',
                })}
              >
                UNKNOWN / UNASKED INDICATORS
              </p>

              {unknownIndicators.map((indicator) => (
                <HStack key={indicator} gap={2}>
                  <Icon
                    name="CircleHelp"
                    size="xs"
                    className="text-warning600"
                  />
                  <span
                    className={textStyles.body({
                      weight: 'medium',
                      size: 'm',
                      color: 'secondary',
                    })}
                  >
                    {indicator}
                  </span>
                </HStack>
              ))}
            </VStack>
          )}

          <div className="h-[1px] w-full bg-brandGray200" />

          <HStack className="mb-2" justify="between">
            <p className={textStyles.body({ size: 's', color: 'tertiary' })}>
              Was this insight helpful?
            </p>
            <HStack className="w-fit">
              <NewButton
                text="Yes"
                type={
                  feedbackGiven && isHelpful === false
                    ? 'ghost'
                    : 'outlineSubtle'
                }
                size="s"
                disabled={feedbackGiven}
                onClick={() => feedbackHandler(true)}
              />
              <NewButton
                text="No"
                type={
                  feedbackGiven && isHelpful === true
                    ? 'ghost'
                    : 'outlineSubtle'
                }
                size="s"
                disabled={feedbackGiven}
                onClick={() => feedbackHandler(false)}
              />
            </HStack>
          </HStack>
        </VStack>
      </CollapsibleContent>
    </Collapsible>
  );
};

export const DifferentialDiagnosisCardLoading: React.FC = () => {
  return (
    <Collapsible
      open={false}
      key="placeholder"
      disabled={true}
      className="w-full rounded-xl bg-backgroundSecondary px-4 py-2"
    >
      <HStack justify="between">
        <p
          className={` animate-pulse ${textStyles.body({
            size: 'm',
            color: 'secondary',
          })}`}
        >
          Analyzing diagnosis...
          <Skeleton className="w-1/2" />
        </p>
        <CollapsibleTrigger asChild>
          <Icon
            name="LoaderCircle"
            size="sm"
            className="mx-3 h-[44px] animate-spin py-2"
          />
        </CollapsibleTrigger>
      </HStack>
    </Collapsible>
  );
};

export interface DifferentialDiagnosesOutputProps {
  userAddedDiagnoses?: DifferentialDiagnosisOutputSchema[];
  content: Record<string, unknown> | Record<string, unknown>[];
  showTitle?: boolean;
  onFeedback?: (isHelpful: boolean, diagnosis: string) => void;
  showLoadingPlaceholder?: boolean;
}

export const DifferentialDiagnosesOutput: React.FC<
  DifferentialDiagnosesOutputProps
> = ({
  content,
  showTitle = false,
  onFeedback,
  userAddedDiagnoses,
  showLoadingPlaceholder = false,
}) => {
  const diagnoses = differentialDiagnosesSchema.safeParse(content);

  const userAddedDdx: DifferentialDiagnosisOutputSchema[] = [];

  userAddedDiagnoses?.map((diagnosis) => {
    userAddedDdx.push({
      diagnosis: diagnosis.diagnosis,
      positiveIndicators: diagnosis.positiveIndicators,
      negativeIndicators: diagnosis.negativeIndicators,
      unknownIndicators: diagnosis.unknownIndicators,
    });
  });

  if (!diagnoses.success) {
    return null;
  }

  const hasMoreThanOneDiagnosis = diagnoses.data.length > 1;

  if (!hasMoreThanOneDiagnosis) {
    return null;
  }

  const mostLikely = diagnoses.data[0];
  const lessLikely = diagnoses.data.slice(1);

  return (
    <VStack gap={6} wFull>
      {showTitle && <OutputTitle title="Differential Diagnoses" />}

      <VStack gap={3} wFull>
        <p
          className={textStyles.body({
            size: 's',
            weight: 'semibold',
            color: 'tertiary',
          })}
        >
          Most Likely
        </p>

        {showLoadingPlaceholder && <DifferentialDiagnosisCardLoading />}

        {Boolean(userAddedDiagnoses?.length) &&
          userAddedDiagnoses!.map((diagnosis) => (
            <DifferentialDiagnosisCard
              key={diagnosis.diagnosis}
              {...diagnosis}
              onFeedback={onFeedback}
              isOpenByDefault={
                diagnosis.diagnosis === userAddedDdx[0]!.diagnosis
              }
            />
          ))}

        <DifferentialDiagnosisCard
          key="mostLikely"
          {...mostLikely!}
          onFeedback={onFeedback}
          isOpenByDefault={!userAddedDdx}
        />
      </VStack>

      <VStack gap={3} wFull>
        <p
          className={textStyles.body({
            size: 's',
            weight: 'semibold',
            color: 'tertiary',
          })}
        >
          Less Likely
        </p>

        {lessLikely.map((diagnosis) => (
          <DifferentialDiagnosisCard
            key={diagnosis.diagnosis}
            {...diagnosis}
            onFeedback={onFeedback}
          />
        ))}
      </VStack>
    </VStack>
  );
};
