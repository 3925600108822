import { useSuspenseQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { z } from 'zod';

import { FCC, toast } from '@eluve/components';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const getAppointmentDetailsQuery = graphql(`
  query getAppointmentDetails($tenantId: uuid!, $appointmentId: uuid!) {
    appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
      __typename
      id
    }
  }
`);

const guidSchema = z.string().uuid();
const isGuid = (id: string) => guidSchema.safeParse(id).success;

export const AppointmentGuard: FCC = ({ children }) => {
  const { tenantId, appointmentId } = useAppointmentContext();

  const { data } = useSuspenseQuery(getAppointmentDetailsQuery, {
    variables: { tenantId: tenantId ?? '', appointmentId: appointmentId ?? '' },
    skip: !isGuid(tenantId) || !isGuid(appointmentId),
  });

  if (!isGuid(tenantId) || !isGuid(appointmentId) || !data?.appointmentsByPk) {
    toast.error('Error', { description: 'Appointment Not Found' });

    return <Navigate to="/" />;
  }

  return children;
};
