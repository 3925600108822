import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useState } from 'react';
import { OptionProps, PlaceholderProps, components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { HStack, Icon } from '@eluve/components';
import { toTitleCase } from '@eluve/utils';

import { EmotionCacheProvider, searchableStyle } from './Searchable.styles';

export type Diagnosis = {
  id: string;
  name: string;
};

export const diagnoses: Diagnosis[] = [
  { id: '1', name: 'Influenza' },
  { id: '2', name: 'Acute Nasopharyngitis' },
  { id: '3', name: 'Pneumonia' },
  { id: '4', name: 'Acute Bronchitis' },
  { id: '5', name: 'Streptococcal Pharyngitis' },
  { id: '6', name: 'Infectious Mononucleosis' },
  { id: '7', name: 'Urinary Tract Infection' },
  { id: '8', name: 'Cellulitis' },
  { id: '9', name: 'Acute Gastroenteritis' },
  { id: '10', name: 'Acute Otitis Media' },
  { id: '11', name: 'Acute Sinusitis' },
  { id: '12', name: 'Chronic Sinusitis' },
  { id: '13', name: 'Conjunctivitis' },
  { id: '14', name: 'Herpes Zoster' },
  { id: '15', name: 'Herpes Simplex Virus Infection' },
  { id: '16', name: 'Human Immunodeficiency Virus Infection' },
  { id: '17', name: 'Type 2 Diabetes Mellitus' },
  { id: '18', name: 'Type 1 Diabetes Mellitus' },
  { id: '19', name: 'Hypothyroidism' },
  { id: '20', name: 'Hyperthyroidism' },
  { id: '21', name: 'Obesity' },
  { id: '22', name: 'Dyslipidemia' },
  { id: '23', name: 'Metabolic Syndrome' },
  { id: '24', name: 'Vitamin D Deficiency' },
  { id: '25', name: 'Polycystic Ovary Syndrome' },
  { id: '26', name: 'Cushing Syndrome' },
  { id: '27', name: 'Major Depressive Disorder' },
  { id: '28', name: 'Generalized Anxiety Disorder' },
  { id: '29', name: 'Panic Disorder' },
  { id: '30', name: 'Social Anxiety Disorder' },
  { id: '31', name: 'Bipolar Disorder' },
  { id: '32', name: 'Schizophrenia' },
  { id: '33', name: 'Attention-Deficit/Hyperactivity Disorder' },
  { id: '34', name: 'Autism Spectrum Disorder' },
  { id: '35', name: 'Post-Traumatic Stress Disorder' },
  { id: '36', name: 'Obsessive-Compulsive Disorder' },
  { id: '37', name: 'Alcohol Use Disorder' },
  { id: '38', name: 'Opioid Use Disorder' },
  { id: '39', name: 'Cocaine Use Disorder' },
  { id: '40', name: 'Anorexia Nervosa' },
  { id: '41', name: 'Bulimia Nervosa' },
  { id: '42', name: 'Migraine' },
  { id: '43', name: 'Tension-Type Headache' },
  { id: '44', name: 'Cluster Headache' },
  { id: '45', name: 'Epilepsy' },
  { id: '46', name: 'Parkinson Disease' },
  { id: '47', name: 'Multiple Sclerosis' },
  { id: '48', name: 'Carpal Tunnel Syndrome' },
  { id: '49', name: 'Alzheimer Disease' },
  { id: '50', name: 'Peripheral Neuropathy' },
  { id: '51', name: 'Trigeminal Neuralgia' },
  { id: '52', name: 'Cataract' },
  { id: '53', name: 'Glaucoma' },
  { id: '54', name: 'Age-Related Macular Degeneration' },
  { id: '55', name: 'Allergic Conjunctivitis' },
  { id: '56', name: 'Dry Eye Syndrome' },
  { id: '57', name: 'Otitis Externa' },
  { id: '58', name: 'Hearing Loss' },
  { id: '59', name: 'Tinnitus' },
  { id: '60', name: 'Eustachian Tube Dysfunction' },
  { id: '61', name: 'Essential Hypertension' },
  { id: '62', name: 'Hypertensive Heart Disease' },
  { id: '63', name: 'Coronary Artery Disease' },
  { id: '64', name: 'Angina Pectoris' },
  { id: '65', name: 'Acute Myocardial Infarction' },
  { id: '66', name: 'Atrial Fibrillation' },
  { id: '67', name: 'Congestive Heart Failure' },
  { id: '68', name: 'Peripheral Arterial Disease' },
  { id: '69', name: 'Varicose Veins' },
  { id: '70', name: 'Deep Vein Thrombosis' },
  { id: '71', name: 'Pulmonary Embolism' },
  { id: '72', name: 'Hypercholesterolemia' },
  { id: '73', name: 'Dilated Cardiomyopathy' },
  { id: '74', name: 'Aortic Stenosis' },
  { id: '75', name: 'Mitral Regurgitation' },
  { id: '76', name: 'Rheumatic Heart Disease' },
  { id: '77', name: 'Infective Endocarditis' },
  { id: '78', name: 'Atrial Flutter' },
  { id: '79', name: 'Supraventricular Tachycardia' },
  { id: '80', name: 'Ventricular Tachycardia' },
  { id: '81', name: 'Asthma' },
  { id: '82', name: 'Chronic Obstructive Pulmonary Disease' },
  { id: '83', name: 'Chronic Bronchitis' },
  { id: '84', name: 'Emphysema' },
  { id: '85', name: 'Allergic Rhinitis' },
  { id: '86', name: 'Acute Tonsillitis' },
  { id: '87', name: 'Acute Laryngitis' },
  { id: '88', name: 'Idiopathic Pulmonary Fibrosis' },
  { id: '89', name: 'Obstructive Sleep Apnea' },
  { id: '90', name: 'Acute Respiratory Failure' },
  { id: '91', name: 'Bronchiectasis' },
  { id: '92', name: 'Spontaneous Pneumothorax' },
  { id: '93', name: 'Pulmonary Edema' },
  { id: '94', name: 'Pleural Effusion' },
  { id: '95', name: 'Pulmonary Hypertension' },
  { id: '96', name: 'Gastroesophageal Reflux Disease' },
  { id: '97', name: 'Peptic Ulcer Disease' },
  { id: '98', name: 'Gastritis' },
  { id: '99', name: 'Celiac Disease' },
  { id: '100', name: 'Irritable Bowel Syndrome' },
  { id: '101', name: 'Crohn Disease' },
  { id: '102', name: 'Ulcerative Colitis' },
  { id: '103', name: 'Diverticulosis' },
  { id: '104', name: 'Diverticulitis' },
  { id: '105', name: 'Hemorrhoids' },
  { id: '106', name: 'Cholelithiasis' },
  { id: '107', name: 'Cholecystitis' },
  { id: '108', name: 'Acute Pancreatitis' },
  { id: '109', name: 'Chronic Pancreatitis' },
  { id: '110', name: 'Hepatitis B' },
  { id: '111', name: 'Hepatitis C' },
  { id: '112', name: 'Acne Vulgaris' },
  { id: '113', name: 'Atopic Dermatitis' },
  { id: '114', name: 'Psoriasis' },
  { id: '115', name: 'Allergic Contact Dermatitis' },
  { id: '116', name: 'Rosacea' },
  { id: '117', name: 'Urticaria' },
  { id: '118', name: 'Tinea Pedis' },
  { id: '119', name: 'Tinea Corporis' },
  { id: '120', name: 'Onychomycosis' },
  { id: '121', name: 'Herpes Labialis' },
  { id: '122', name: 'Scabies' },
  { id: '123', name: 'Impetigo' },
  { id: '124', name: 'Verrucae' },
  { id: '125', name: 'Alopecia Areata' },
  { id: '126', name: 'Pressure Ulcers' },
  { id: '127', name: 'Osteoarthritis' },
  { id: '128', name: 'Rheumatoid Arthritis' },
  { id: '129', name: 'Osteoporosis' },
  { id: '130', name: 'Gout' },
  { id: '131', name: 'Fibromyalgia' },
  { id: '132', name: 'Lumbar Strain' },
  { id: '133', name: 'Sciatica' },
  { id: '134', name: 'Bursitis' },
  { id: '135', name: 'Tendinitis' },
  { id: '136', name: 'Rotator Cuff Tendinopathy' },
  { id: '137', name: 'Plantar Fasciitis' },
  { id: '138', name: 'Spinal Stenosis' },
  { id: '139', name: 'Scoliosis' },
  { id: '140', name: 'Systemic Lupus Erythematosus' },
  { id: '141', name: 'Ankylosing Spondylitis' },
  { id: '142', name: 'Polymyalgia Rheumatica' },
  { id: '143', name: 'Temporomandibular Joint Dysfunction' },
  { id: '144', name: 'Juvenile Idiopathic Arthritis' },
  { id: '145', name: 'Polymyositis' },
  { id: '146', name: 'Pyelonephritis' },
  { id: '147', name: 'Nephrolithiasis' },
  { id: '148', name: 'Chronic Kidney Disease' },
  { id: '149', name: 'Benign Prostatic Hyperplasia' },
  { id: '150', name: 'Prostatitis' },
  { id: '151', name: 'Erectile Dysfunction' },
  { id: '152', name: 'Polycystic Kidney Disease' },
  { id: '153', name: 'Interstitial Cystitis' },
  { id: '154', name: 'Overactive Bladder' },
  { id: '155', name: 'Stress Urinary Incontinence' },
  { id: '156', name: 'Endometriosis' },
  { id: '157', name: 'Dysmenorrhea' },
  { id: '158', name: 'Pelvic Inflammatory Disease' },
  { id: '159', name: 'Menopausal Syndrome' },
  { id: '160', name: 'Normal Pregnancy' },
  { id: '161', name: 'Gestational Diabetes Mellitus' },
  { id: '162', name: 'Preeclampsia' },
  { id: '163', name: 'Eclampsia' },
  { id: '164', name: 'Hyperemesis Gravidarum' },
  { id: '165', name: 'Placenta Previa' },
  { id: '166', name: 'Abruptio Placentae' },
  { id: '167', name: 'Postpartum Hemorrhage' },
  { id: '168', name: 'Postpartum Depression' },
  { id: '169', name: 'Puerperal Mastitis' },
  { id: '170', name: 'Preterm Birth' },
  { id: '171', name: 'Low Birth Weight' },
  { id: '172', name: 'Neonatal Jaundice' },
  { id: '173', name: 'Neonatal Respiratory Distress Syndrome' },
  { id: '174', name: 'Neonatal Sepsis' },
  { id: '175', name: 'Down Syndrome' },
  { id: '176', name: 'Ventricular Septal Defect' },
  { id: '177', name: 'Cleft Lip and Palate' },
  { id: '178', name: 'Spina Bifida' },
  { id: '179', name: 'Clubfoot' },
  { id: '180', name: 'Fever of Unknown Origin' },
  { id: '181', name: 'Fatigue' },
  { id: '182', name: 'Chest Pain' },
  { id: '183', name: 'Palpitations' },
  { id: '184', name: 'Syncope' },
  { id: '185', name: 'Abdominal Pain' },
  { id: '186', name: 'Nausea and Vomiting' },
  { id: '187', name: 'Diarrhea' },
  { id: '188', name: 'Headache' },
  { id: '189', name: 'Vertigo' },
  { id: '190', name: 'Concussion' },
  { id: '191', name: 'Fracture of the Distal Radius' },
  { id: '192', name: 'Hip Fracture' },
  { id: '193', name: 'Ankle Sprain' },
  { id: '194', name: 'Shoulder Dislocation' },
  { id: '195', name: 'Thermal Burn' },
  { id: '196', name: 'Opioid Toxicity' },
  { id: '197', name: 'Benzodiazepine Toxicity' },
  { id: '198', name: 'Allergic Drug Reaction' },
  { id: '199', name: 'Accidental Laceration' },
  { id: '200', name: 'Acute Cervical Strain' },
  { id: '201', name: 'Basal Cell Carcinoma' },
  { id: '202', name: 'Squamous Cell Carcinoma' },
  { id: '203', name: 'Malignant Melanoma' },
  { id: '204', name: 'Breast Cancer' },
  { id: '205', name: 'Prostate Cancer' },
  { id: '206', name: 'Lung Cancer' },
  { id: '207', name: 'Colorectal Cancer' },
  { id: '208', name: 'Renal Cell Carcinoma' },
  { id: '209', name: 'Hodgkin Lymphoma' },
  { id: '210', name: 'Non-Hodgkin Lymphoma' },
  { id: '211', name: 'Chronic Lymphocytic Leukemia' },
  { id: '212', name: 'Chronic Myeloid Leukemia' },
  { id: '213', name: 'Multiple Myeloma' },
  { id: '214', name: 'Ovarian Cancer' },
  { id: '215', name: 'Endometrial Cancer' },
  { id: '216', name: 'Cervical Cancer' },
  { id: '217', name: 'Pancreatic Cancer' },
  { id: '218', name: 'Gastric Cancer' },
  { id: '219', name: 'Esophageal Cancer' },
  { id: '220', name: 'Hepatocellular Carcinoma' },
  { id: '221', name: 'Addison Disease' },
  { id: '222', name: 'Pheochromocytoma' },
  { id: '223', name: 'Diabetic Ketoacidosis' },
  { id: '224', name: 'Hyperosmolar Hyperglycemic State' },
  { id: '225', name: 'Hypoparathyroidism' },
  { id: '226', name: 'Hyperparathyroidism' },
  { id: '227', name: 'Subacute Thyroiditis' },
  { id: '228', name: 'Graves Disease' },
  { id: '229', name: 'Hashimoto Thyroiditis' },
  { id: '230', name: 'Allergic Bronchitis' },
  { id: '231', name: 'Diabetes Insipidus' },
  { id: '232', name: 'Hemochromatosis' },
  { id: '233', name: 'Wilson Disease' },
  { id: '234', name: 'Tuberculosis' },
  { id: '235', name: 'Latent Tuberculosis Infection' },
  { id: '236', name: 'Bacterial Meningitis' },
  { id: '237', name: 'Viral Meningitis' },
  { id: '238', name: 'Encephalitis' },
  { id: '239', name: 'Brain Abscess' },
  { id: '240', name: 'Subarachnoid Hemorrhage' },
  { id: '241', name: 'Intracerebral Hemorrhage' },
  { id: '242', name: 'Ischemic Stroke' },
  { id: '243', name: 'Transient Ischemic Attack' },
  { id: '244', name: 'Hemorrhagic Stroke' },
  { id: '245', name: 'Huntington Disease' },
  { id: '246', name: 'Charcot-Marie-Tooth Disease' },
  { id: '247', name: 'Guillain-Barré Syndrome' },
  { id: '248', name: 'Myasthenia Gravis' },
  { id: '249', name: 'Amyotrophic Lateral Sclerosis' },
  { id: '250', name: 'Giant Cell Arteritis' },
  { id: '251', name: 'Chronic Fatigue Syndrome' },
  { id: '252', name: 'Sarcoidosis' },
  { id: '253', name: 'Primary Sclerosing Cholangitis' },
  { id: '254', name: 'Primary Biliary Cholangitis' },
  { id: '255', name: 'Dermatomyositis' },
  { id: '256', name: 'Scleroderma' },
  { id: '257', name: 'Raynaud Phenomenon' },
  { id: '258', name: 'Mixed Connective Tissue Disease' },
  { id: '259', name: 'Eosinophilic Esophagitis' },
  { id: '260', name: 'Pelvic Organ Prolapse' },
  { id: '261', name: 'Chronic Pelvic Pain' },
  { id: '262', name: 'Vulvovaginal Candidiasis' },
  { id: '263', name: 'Bacterial Vaginosis' },
  { id: '264', name: 'Trichomoniasis' },
  { id: '265', name: 'Developmental Dysplasia of the Hip' },
  { id: '266', name: 'Patent Ductus Arteriosus' },
  { id: '267', name: 'Atrial Septal Defect' },
  { id: '268', name: 'Tetralogy of Fallot' },
  { id: '269', name: 'Coarctation of the Aorta' },
  { id: '270', name: 'Neonatal Abstinence Syndrome' },
  { id: '271', name: 'Klebsiella Pneumonia' },
  { id: '272', name: 'Necrotizing Enterocolitis' },
  { id: '273', name: 'Hirschsprung Disease' },
  { id: '274', name: 'Uterine Leiomyomas' },
  { id: '275', name: 'Hidradenitis Suppurativa' },
  { id: '276', name: 'Lichen Planus' },
  { id: '277', name: 'Vitiligo' },
  { id: '278', name: 'Pemphigus Vulgaris' },
  { id: '279', name: 'Bullous Pemphigoid' },
  { id: '280', name: 'Stevens-Johnson Syndrome' },
  { id: '281', name: 'Toxic Epidermal Necrolysis' },
  { id: '282', name: 'Measles' },
  { id: '283', name: 'Mumps' },
  { id: '284', name: 'Rubella' },
  { id: '285', name: 'Varicella' },
  { id: '286', name: 'Erythema Infectiosum' },
  { id: '287', name: 'Roseola Infantum' },
  { id: '288', name: 'Pertussis' },
  { id: '289', name: 'Diphtheria' },
  { id: '290', name: 'Tetanus' },
  { id: '291', name: 'Poliomyelitis' },
  { id: '292', name: 'Rheumatic Fever' },
  { id: '293', name: 'Lyme Disease' },
  { id: '294', name: 'Rocky Mountain Spotted Fever' },
  { id: '295', name: 'Malaria' },
  { id: '296', name: 'Schistosomiasis' },
  { id: '297', name: 'Amebiasis' },
  { id: '298', name: 'Giardiasis' },
  { id: '299', name: 'Blastomycosis' },
  { id: '300', name: 'Coccidioidomycosis' },
];

interface SearchableDiagnosesProps {
  disabled?: boolean;
  onItemSelected?: (option: Diagnosis) => void | Promise<void>;
}

const Placeholder = (props: PlaceholderProps<Diagnosis>) => {
  return (
    <HStack className="col-start-1 col-end-3 row-start-1 row-end-2 inline-flex gap-3 pl-2">
      <Icon size="xs" name="Search" />
      <components.Placeholder {...props} />
    </HStack>
  );
};

const Option = (props: OptionProps<Diagnosis>) => {
  const { data } = props;
  const dx = data as Diagnosis;

  return (
    <components.Option {...props} className="flex items-center gap-3">
      <div>{dx.name}</div>
    </components.Option>
  );
};

export const SearchableDiagnoses: React.FC<SearchableDiagnosesProps> = ({
  disabled,
  onItemSelected,
}) => {
  const [selectedValue, setSelectedValue] = useState<Diagnosis | null>(null);

  const filterDiagnoses = useCallback(
    (inputValue: string, callback: (options: Diagnosis[]) => void) => {
      const filtered = diagnoses.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      callback(filtered);
    },
    [diagnoses],
  );

  const loadOptions = useMemo(
    () => debounce(filterDiagnoses, 500),
    [filterDiagnoses],
  );

  const handleChange = async (input: Diagnosis | null) => {
    if (input) {
      await onItemSelected?.(input);
    }
    setSelectedValue(null);
  };

  const handleCreateOption = async (inputValue: string) => {
    const newOption: Diagnosis = {
      id: String(Date.now()),
      name: toTitleCase(inputValue),
    };

    setSelectedValue(newOption);

    await onItemSelected?.(newOption);
    setSelectedValue(null);
  };

  return (
    <EmotionCacheProvider>
      <AsyncCreatableSelect<Diagnosis, false>
        isDisabled={disabled}
        placeholder="Choose a diagnosis"
        noOptionsMessage={({ inputValue }) =>
          inputValue ? `No results for "${inputValue}"` : 'Search diagnosis'
        }
        loadingMessage={() => 'Searching...'}
        value={selectedValue}
        isClearable={false}
        isMulti={false}
        loadOptions={loadOptions}
        onChange={handleChange}
        onCreateOption={handleCreateOption}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        getNewOptionData={(inputValue) => ({
          id: String(Date.now()),
          name: toTitleCase(inputValue),
          // the __isNew__ flag just tells React-Select it is a newly created option
          __isNew__: true,
        })}
        components={{
          Option,
          Placeholder,
        }}
        classNames={{
          container: () => searchableStyle.container(),
          control: ({ isDisabled }) =>
            searchableStyle.control({ disabled: isDisabled }),
          valueContainer: () => searchableStyle.valueContainer(),
          menu: () => searchableStyle.menu(),
          menuList: () => searchableStyle.menuList(),
          placeholder: () => searchableStyle.placeholder(),
          indicatorSeparator: () => searchableStyle.indicatorSeparator(),
          dropdownIndicator: () => searchableStyle.dropdownIndicator(),
          loadingIndicator: () => searchableStyle.loadingIndicator(),
          indicatorsContainer: () => searchableStyle.indicatorsContainer(),
          option: ({ isFocused }) =>
            searchableStyle.option({ optionFocused: isFocused }),
          noOptionsMessage: () => searchableStyle.noOptionsMessage(),
        }}
      />
    </EmotionCacheProvider>
  );
};
