import { useQuery } from '@apollo/client';
import { match } from 'ts-pattern';

import { LlmOutputTypesEnum, LlmOutputTypesLookup } from '@eluve/graphql-types';
import {
  DifferentialDiagnosisOutputSchema,
  differentialDiagnosisSchema,
} from '@eluve/llm-outputs';

import { useAppointmentContext } from './appointment.context';
import { getAllAppointmentLlmOutputByOutputTypeQuery } from './insights.operations';

type SupportedOutputTypes = Extract<
  LlmOutputTypesEnum,
  'DIAGNOSES_JUSTIFICATION'
>;

type AppointmentLlmOutput = {
  content: DifferentialDiagnosisOutputSchema;
  llmOutputId: string;
  completedAt: string;
};

/**
 * Returns all appointment outputs for the given output type.
 *
 * Currently supports output types:
 *   - DIAGNOSES_JUSTIFICATION
 *
 * When adding support for more output types, add a new case to the parsing
 * logic in the match statement below.
 */
export const useAllAppointmentLlmOutputsForOutputType = ({
  outputType,
}: {
  outputType: SupportedOutputTypes;
}) => {
  const { appointmentId, tenantId } = useAppointmentContext();

  const { data, refetch } = useQuery(
    getAllAppointmentLlmOutputByOutputTypeQuery,
    {
      variables: {
        tenantId,
        appointmentId,
        outputType,
      },
    },
  );

  const outputs = data?.appointmentsByPk?.appointment_llm_outputs;

  if (!outputs) {
    return { outputs: null, refetch };
  }

  const parsedOutputs = outputs.map((apptOutput) =>
    match(apptOutput.output.metadata?.prompt_template?.outputType)
      .with(LlmOutputTypesLookup.DIAGNOSES_JUSTIFICATION, () => {
        const parsed = differentialDiagnosisSchema.safeParse(
          apptOutput.output.content,
        );

        return {
          llmOutputId: apptOutput.llmOutputId,
          content: parsed.success ? parsed.data : null,
          completedAt: apptOutput.output.completedAt!,
        };
      })
      .otherwise(() => {
        throw new Error('Unsupported output type');
      }),
  );

  return {
    outputs: parsedOutputs.filter(
      (output) => output.content !== null,
    ) as AppointmentLlmOutput[],
    refetch,
  };
};
