import last from 'lodash/last';
import React from 'react';
import {
  NavLink,
  useMatches,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { VariantProps } from 'tailwind-variants';

import { tv } from '@eluve/components';
import { FeatureFlagsEnum } from '@eluve/graphql-types';

import { useEnabledFeatureFlags } from './useIsFeatureFlagEnabled';

const tabNavLinkStyles = tv({
  base: 'whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium hover:border-gray-8',
  variants: {
    colorMode: {
      dark: 'text-gray-3',
      light: '',
    },
    isActive: {
      true: '',
    },
  },
  compoundVariants: [
    {
      colorMode: 'dark',
      isActive: true,
      className: 'border-brand-6 text-brand-9 hover:border-brand-7',
    },
    {
      colorMode: 'light',
      isActive: true,
      className: 'border-brand-8 text-brand-11 hover:border-brand-9',
    },
  ],
});

type TabNavLinkProps = VariantProps<typeof tabNavLinkStyles>;

type TabLink = {
  name: string;
  label?: React.ReactNode;
  link: string;
  featureFlag?: FeatureFlagsEnum;
  persistentQueryParams?: string[];
};

export type TabNavProps = {
  tabs: TabLink[];
} & Pick<TabNavLinkProps, 'colorMode'>;

const buildTabLink = (
  link: string,
  persistentQueryParams: string[],
  currentQueryParams: URLSearchParams,
) => {
  if (!persistentQueryParams.length) {
    return link;
  }

  const newQueryParams = new URLSearchParams();
  persistentQueryParams.forEach((param) => {
    const value = currentQueryParams.get(param);
    if (value) {
      newQueryParams.set(param, currentQueryParams.get(param) ?? '');
    }
  });

  return `${link}?${newQueryParams.toString()}`;
};

export const TabNav: React.FC<TabNavProps> = ({
  tabs,
  colorMode = 'light',
}) => {
  const navigate = useNavigate();
  const matches = useMatches();

  const [params] = useSearchParams();

  const lastMatch = last(matches);

  const matchedValue = tabs.find((tab) =>
    lastMatch?.pathname?.replaceAll('/', '')?.endsWith(tab.link),
  );

  const enabledFeatureFlags = useEnabledFeatureFlags();

  const visibleTabs = tabs.filter((t) => {
    if (!t.featureFlag) {
      return true;
    }
    return enabledFeatureFlags.includes(t.featureFlag);
  });

  return (
    <div className="w-full">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-borderSecondary py-2 pl-3 pr-10 text-base focus:border-brand-6 focus:outline-none focus:ring-brand-6 sm:text-sm"
          onChange={(e) => {
            const tab = tabs.find((tab) => tab.name === e.target.value);
            if (tab) {
              navigate(
                buildTabLink(tab.link, tab.persistentQueryParams ?? [], params),
              );
            }
          }}
          value={matchedValue?.name ?? tabs[0]?.name}
        >
          {visibleTabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden overflow-x-scroll sm:block">
        <div className="border-b border-borderPrimary">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {visibleTabs.map((tab) => (
              <NavLink
                key={tab.link}
                to={buildTabLink(
                  tab.link,
                  tab.persistentQueryParams ?? [],
                  params,
                )}
                className={({ isActive }) =>
                  tabNavLinkStyles({ isActive, colorMode })
                }
              >
                {tab.label ?? tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
