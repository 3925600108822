import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { PageTitle, VStack } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { TabNav } from '@eluve/smart-blocks';

import { promptTemplateUsersFragment } from './PromptTemplateUsers';
import {
  promptTemplateModelsFragment,
  promptTemplateVariantsFragment,
} from './prompt.operations';

export interface PromptTemplateDetailsLayoutProps {}

const getPromptDetailsQuery = graphql(
  `
    query getPromptDetails($id: uuid!) {
      promptTemplatesByPk(id: $id) {
        __typename
        id
        name
        description
        isActive
        outputType
        ...PromptTemplateModels
        ...PromptTemplateUsers
        ...PromptTemplateVariants
      }
    }
  `,
  [
    promptTemplateUsersFragment,
    promptTemplateModelsFragment,
    promptTemplateVariantsFragment,
  ],
);

export const PromptTemplateDetailsLayout: React.FC<
  PromptTemplateDetailsLayoutProps
> = () => {
  const { promptTemplateId } = useParams() as { promptTemplateId: string };

  const {
    data: { promptTemplatesByPk: promptTemplate },
  } = useSuspenseQuery(getPromptDetailsQuery, {
    variables: {
      id: promptTemplateId,
    },
  });

  return (
    <div>
      <PageTitle subtitle={promptTemplate?.description}>
        {promptTemplate?.name}
      </PageTitle>
      <VStack className="my-2">
        <p>
          Output Type:
          <span className="font-semibold"> {promptTemplate?.outputType}</span>
        </p>
        <p>
          Is Active:
          <span className="font-semibold">
            {' '}
            {promptTemplate?.isActive?.toString().toUpperCase()}
          </span>
        </p>
      </VStack>
      <TabNav
        tabs={[
          {
            name: 'Prompt',
            link: 'prompt',
          },
          {
            name: 'Model Args',
            link: 'model-args',
          },
          {
            name: 'Users',
            link: 'users',
          },
        ]}
      />
      <div className="mt-4">
        <Outlet />
      </div>
    </div>
  );
};
