import { Subscription, switchMap } from 'rxjs';
import { io } from 'socket.io-client';
import { fromCallback } from 'xstate';

import { cacheUtils } from '@eluve/apollo-client';
import {
  AUDIO_PACKET,
  AudioPacket,
  CONNECT,
  CONNECT_ERROR,
  DISCONNECT,
  INITIALIZE_AUDIO_HANDLER,
  InitializePacketHandler,
  STOP_RECORDING,
  TRANSCRIPT_EVENT,
  TranscriptEvent,
} from '@eluve/socket-messages';
import { SupportedAudioFormat } from '@eluve/user-local-files';

import { appConfig } from '../../../../../../../../config';
import { useIsSimulatedDegradedTranscription } from '../../../components/EluveAdminPanel/SimulateDegradedTranscription';
import { appointmentSegmentFragment } from '../../helpers/appointmentSegmentFragment';
import * as transcriptionTypes from '../types';

export type WebSocketEvents =
  | {
      type: 'connection.connect';
      authToken: string;
    }
  | {
      type: 'connection.connected';
    }
  | {
      type: 'connection.failed';
      message: string;
    }
  | {
      // when the connection is disconnected
      type: 'connection.disconnected';
    }
  | {
      type: 'connection.disconnected.unexpectedly';
      message: string;
    }
  | {
      type: 'connection.sendAudioPacket.failed';
      message: string;
    }
  | {
      type: 'connection.transcript.received';
      transcript: TranscriptEvent;
    };

type Input = {
  format: SupportedAudioFormat;
  tenantId: string;
  appointmentId: string;
  segmentId: string;
  microphoneName?: string;
  audioPacketSubject: transcriptionTypes.AudioPacketSubject;
  recordingStartedAt: string;
};

const MAX_CONSECUTIVE_FAILED_ATTEMPTS = 3;

export const websocket = fromCallback<WebSocketEvents, Input>(
  ({ input, sendBack, receive }) => {
    const { format, appointmentId, tenantId } = input;

    let packetNumber = 0;
    let audioPacketSubscription: Subscription | undefined;
    let consecutiveFailedAttemptCount = 0;
    let isClientInitiatedDisconnect = false;

    const sendAudioPacket = async (packet: transcriptionTypes.AudioPacket) => {
      if (useIsSimulatedDegradedTranscription.getState()) {
        isClientInitiatedDisconnect = true;
        socket.disconnect();
        sendBack({
          type: 'connection.sendAudioPacket.failed',
          message: 'Simulated degraded transcription',
        });
        return;
      }

      if (!socket.connected || isClientInitiatedDisconnect) {
        sendBack({
          type: 'connection.unavailable',
        });
        return;
      }

      let isResponseOk = true;
      try {
        const audioPacket: AudioPacket = {
          data: packet.data,
          timestamp: packet.timestamp,
          packetNumber: packetNumber++,
        };
        const response = await socket
          .timeout(3000)
          .emitWithAck(AUDIO_PACKET, audioPacket);

        if (!response.ok) {
          isResponseOk = false;
          throw new Error('Failed to send audio packet to server');
        }
        consecutiveFailedAttemptCount = 0;
      } catch (error) {
        if (!socket.connected || isClientInitiatedDisconnect) {
          // If the connection is closed or the client initiated the disconnect due to an error,
          // we don't need to handle the error here since we already logged this condition elsewhere.
          return;
        }

        consecutiveFailedAttemptCount += 1;
        // Make sure HEAD packet is sent and allow brief timeout errors.
        const failedToSendHeadPacket = packetNumber < 3;
        const exceededMaxConsecutiveFailedAttempts =
          consecutiveFailedAttemptCount > MAX_CONSECUTIVE_FAILED_ATTEMPTS;
        if (
          !isResponseOk ||
          failedToSendHeadPacket ||
          exceededMaxConsecutiveFailedAttempts
        ) {
          const capturedError = error as Error;
          const errorMessage = [
            `Message: ${capturedError.message}`,
            `failedToSendHeadPacket: ${failedToSendHeadPacket}`,
            `exceededMaxConsecutiveFailedAttempts: ${exceededMaxConsecutiveFailedAttempts}`,
            `isResponseOk: ${isResponseOk}`,
          ].join(', ');

          isClientInitiatedDisconnect = true;
          socket.disconnect();
          sendBack({
            type: 'connection.sendAudioPacket.failed',
            message: errorMessage,
          });
        }
      }
    };

    const socket = io(`${appConfig.VITE_API_DOMAIN}/live-transcription`, {
      query: { appointmentId },
      reconnection: false,
      reconnectionAttempts: 100,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      autoConnect: false,
    });

    socket.on(TRANSCRIPT_EVENT, (transcript: TranscriptEvent) => {
      // There's no point in doing a send back if we get an empty string
      if (transcript.transcript) {
        sendBack({
          type: 'connection.transcript.received',
          transcript,
        });
      }
    });

    socket.on(CONNECT, () => {
      initialize();
    });

    socket.on(CONNECT_ERROR, () => {
      sendBack({
        type: 'connection.failed',
        message: 'Received connect-error event',
      });
    });

    socket.on(DISCONNECT, () => {
      if (isClientInitiatedDisconnect) {
        sendBack({
          type: 'connection.disconnected',
        });
      } else {
        sendBack({
          type: 'connection.disconnected.unexpectedly',
          message: 'Connection was unexpectedly closed by the server.',
        });
      }
      audioPacketSubscription?.unsubscribe();
    });

    const initialize = async () => {
      try {
        const initPayload: InitializePacketHandler = {
          encoding: format.encoding,
          audioFormat: format.extension,
          segmentId: input.segmentId,
          userAgent: navigator.userAgent,
          recordingStartedAt: input.recordingStartedAt,
          microphoneName: input.microphoneName ?? null,
        };

        const response = await socket
          .timeout(10000)
          .emitWithAck(INITIALIZE_AUDIO_HANDLER, initPayload);

        if (!response.ok) {
          sendBack({
            type: 'connection.failed',
            message: 'Unable to initialize audio handler',
          });
          return;
        }

        // This we need to do since initializeAudioHandler is not apollo client operation
        // Information about the segment in cache can be used to determine if the segment is created successfully
        cacheUtils.writeFragment({
          fragment: appointmentSegmentFragment,
          key: { id: input.segmentId },
          data: {
            __typename: 'AppointmentSegments',
            id: input.segmentId,
            appointmentId: input.appointmentId,
            wasDegraded: false,
          },
        });

        audioPacketSubscription = input.audioPacketSubject
          .asObservable()
          .pipe(switchMap((packet) => sendAudioPacket(packet)))
          .subscribe();

        sendBack({
          type: 'connection.connected',
        });
      } catch (error) {
        sendBack({
          type: 'connection.failed',
          message: 'Timeout while initializing audio handler',
        });
      }
    };

    receive((event) => {
      if (event.type === 'connection.connect') {
        socket.auth = { token: event.authToken, tenantId };
        if (!socket.connected) {
          socket.connect();
        }
      }
    });

    return () => {
      audioPacketSubscription?.unsubscribe();

      if (socket.connected) {
        socket.emit(STOP_RECORDING);
        isClientInitiatedDisconnect = true;
        socket.disconnect();
      }
      socket.close();
    };
  },
);
