import * as React from 'react';

import { cn } from './cn';
import { textStyles } from './textStyles';
import { FCC } from './types';

type SpaceDirection = 'x' | 'y' | 't' | 'b' | 'l' | 'r' | '';
type SpaceSize = 0 | 1 | 2 | 3 | 4;

type PaddingSpaceClass = `p${SpaceDirection}-${SpaceSize}`;
type MarginSpaceClass = `m${SpaceDirection}-${SpaceSize}`;

type SpaceProps = {
  m?: MarginSpaceClass | MarginSpaceClass[];
  p?: PaddingSpaceClass | PaddingSpaceClass[];
  cn?: string;
};

type HeadingProps = SpaceProps & { children: React.ReactNode };

const spacePropsToClasses = ({ m = [], p = [], cn = '' }: SpaceProps) => {
  return [...(Array.isArray(m) ? m : [m]), ...(Array.isArray(p) ? p : [p]), cn];
};

export const H1: FCC<{ xl?: boolean } & SpaceProps> = ({
  children,
  xl = false,
  ...space
}) => {
  return (
    <h1
      className={cn(
        textStyles.heading({
          size: xl ? 'l' : 'm',
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h1>
  );
};

export const H2: FCC<SpaceProps> = ({ children, ...space }) => {
  return (
    <h2
      className={cn(
        textStyles.heading({
          size: 'xs',
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h2>
  );
};

export const H3: FCC<SpaceProps> = ({ children, ...space }) => {
  return (
    <h3
      className={cn(
        textStyles.title({
          size: 'l',
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h3>
  );
};

export const H4: FCC<SpaceProps> = ({ children, ...space }) => {
  return (
    <h4
      className={cn(
        textStyles.title({
          size: 'm',
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h4>
  );
};

export const H5: FCC<SpaceProps> = ({ children, ...space }) => {
  return (
    <h5
      className={cn(
        textStyles.title({
          size: 's',
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h5>
  );
};

export const H6 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ children, ...space }, ref) => {
    return (
      <h6
        ref={ref}
        className={cn(
          textStyles.body({
            size: 'l',
          }),
          spacePropsToClasses(space),
        )}
      >
        {children}
      </h6>
    );
  },
);

H6.displayName = 'H6';

export const P: FCC<{ className?: string; onClick?: () => void }> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <p onClick={onClick} className={textStyles.body({ size: 'm', className })}>
      {children}
    </p>
  );
};
