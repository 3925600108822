import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useParams } from 'react-router-dom';

import { ReskinContent, ReskinMain } from '@eluve/components';
import { AppointmentContextProvider } from '@eluve/frontend-appointment-hooks';
import {
  LoggerArgs,
  LoggingContextProvider,
  useExtendedLogger,
} from '@eluve/logger';

import { AppointmentGuard } from './AppointmentGuard';
import { AppointmentHeader } from './components/AppointmentHeader';

export const AppointmentsMainLayoutContent = () => {
  return (
    <ReskinMain>
      <AppointmentHeader />
      <ReskinContent variant="fullWidth" className="overflow-auto p-0">
        <Outlet />
      </ReskinContent>
    </ReskinMain>
  );
};

export const AppointmentsMainLayout: React.FC = () => {
  const { appointmentId, tenantId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const appointmentLoggerArgs: LoggerArgs = useMemo(
    () => ({
      name: 'AppointmentScoped',
      metadata: { tenantId, appointmentId },
    }),
    [tenantId, appointmentId],
  );
  const appointmentLogger = useExtendedLogger(appointmentLoggerArgs);

  return (
    <LoggingContextProvider loggerInput={appointmentLogger}>
      <AppointmentContextProvider
        tenantId={tenantId}
        appointmentId={appointmentId}
      >
        <AppointmentGuard>
          <Helmet>
            <title>Appointments | Eluve</title>
          </Helmet>
          <AppointmentsMainLayoutContent />
        </AppointmentGuard>
      </AppointmentContextProvider>
    </LoggingContextProvider>
  );
};
