import { useMutation } from '@apollo/client';
import { useRef } from 'react';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { cacheUtils } from '@eluve/apollo-client';
import {
  ComboboxNewOption,
  toast,
  useComboboxInputValue,
  useSetComboboxInputValue,
} from '@eluve/components';

import { PatientOption } from './PatientOption';
import { addNewPatientMutation, tenantPatientsFragment } from './operations';

export const NewPatientOption = React.forwardRef<
  HTMLDivElement,
  {
    tenantId: string;
    onCreateNewPatient?: (patientId?: string) => void;
  }
>(({ tenantId, onCreateNewPatient }, ref) => {
  const inputValue = useComboboxInputValue();
  const setInputValue = useSetComboboxInputValue();

  const id = useRef(uuid());

  const [createNewPatient] = useMutation(addNewPatientMutation, {
    optimisticResponse: ({ input }) => ({
      insertPatientsOne: {
        __typename: 'Patients' as const,
        dateOfBirth: null,
        id: id.current,
        firstName: input.firstName!,
        lastName: input.lastName ?? null,
        external_patients_info: [],
      },
    }),
    update: (_, { data }) => {
      if (!data?.insertPatientsOne) {
        return;
      }

      const { id, firstName, lastName } = data.insertPatientsOne;

      const currentTenantPatients = cacheUtils.readFragment({
        fragment: tenantPatientsFragment,
        key: { id: tenantId },
        strict: false,
      });

      cacheUtils.writeFragment({
        fragment: tenantPatientsFragment,
        key: { id: tenantId },
        data: {
          __typename: 'Tenants' as const,
          id: tenantId,
          patients: [
            ...(currentTenantPatients?.patients ?? []),
            {
              __typename: 'Patients' as const,
              external_patients_info: [],
              dateOfBirth: null,
              id,
              firstName,
              lastName,
            },
          ],
        },
      });
    },
    onCompleted: (data) => {
      const patient = data.insertPatientsOne;
      if (!patient) {
        return;
      }

      toast.success(`Patient ${patient.firstName} added`);
      onCreateNewPatient?.(patient.id);
    },
    onError: () => {
      toast.error('Failed to add new patient');
    },
  });

  const handleNewPatient = () => {
    const [firstName, ...lastWords] = inputValue.split(' ');

    const newPatient = {
      id: id.current,
      firstName,
      lastName: lastWords.join(' ') ?? null,
    };

    createNewPatient({
      variables: {
        input: {
          ...newPatient,
          users_patients: {
            data: [
              // patient_id is automatically inferred from the nested insert
              // user_id and tenant_id are derived from column presets in our
              // permissions
              {
                createdAt: new Date().toISOString(),
              },
            ],
          },
        },
      },
    });

    setInputValue('');
  };

  return (
    <ComboboxNewOption onSelect={handleNewPatient} ref={ref}>
      <PatientOption
        id={id.current}
        firstName={inputValue}
        lastName={null}
        external_patients_info={[]}
        dateOfBirth={null}
        new
      />
    </ComboboxNewOption>
  );
});
