import React from 'react';

import { H4, VStack } from '@eluve/components';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { AssetDeletionRequestsTable } from './AssetDeletionRequestsTable';

export const TenantAssetDeletionHistoryPage: React.FC = () => {
  const tenantId = useTenantIdFromParams();

  return (
    <VStack gap={8}>
      <H4>Jobs history for this tenant </H4>
      <AssetDeletionRequestsTable tenantId={tenantId!} />
    </VStack>
  );
};
