// Private Functions

const getMaxTouchPoints = () =>
  typeof window !== 'undefined' ? window?.navigator?.maxTouchPoints : 0;

const getUserAgent = () =>
  typeof window !== 'undefined' ? window?.navigator?.userAgent : '';

// Browser

export const isChrome =
  /Chrome|CriOS|CrMo/i.test(getUserAgent()) &&
  !/Edge|Edg|OPR|Brave/i.test(getUserAgent());

export const isEdge = /Edg|Edge/i.test(getUserAgent());

export const isSafari = /^((?!Chrome|Android).)*Safari/i.test(getUserAgent());

// Device

export const isMobileDevice = /Mobile|Android|iPhone|iPad|iPod/i.test(
  getUserAgent(),
);

// OS

export const isIOS =
  /iPhone|iPad|iPod/i.test(getUserAgent()) ||
  (/Macintosh/i.test(getUserAgent()) && getMaxTouchPoints() > 1);

// Miscellaneous

export const isAndroidWebView =
  /Android.*wv.*Version\/[\d.]+.*Chrome\/[\d.]+/i.test(getUserAgent());

// Versions

export const getAndroidWebViewVersion = (): number | null => {
  if (!isAndroidWebView) {
    return null;
  }

  const match = getUserAgent().match(/Chrome\/(\d+)/);
  return match && match[1] ? parseInt(match[1], 10) : null;
};

export const getIOSVersion = (): number | null => {
  if (!isIOS) {
    return null;
  }

  const match = getUserAgent().match(/OS (\d+)_\d+/);
  return match && match[1] ? parseInt(match[1], 10) : null;
};
