import { useSuspenseQuery } from '@apollo/client';
import { Label } from '@radix-ui/react-context-menu';
import sumBy from 'lodash/sumBy';
import React, { useState } from 'react';
import { useMatches } from 'react-router-dom';

import { useApiClient } from '@eluve/api-client-provider';
import { reviewerHasuraContext } from '@eluve/apollo-client';
import { TranscriptText, TranscriptTimestampDivider } from '@eluve/blocks';
import {
  Box,
  Card,
  EluveCardContent,
  EluveCardHeader,
  HStack,
  NewButton,
  P,
  Switch,
  VStack,
  toast,
} from '@eluve/components';
import { Translator } from '@eluve/translation';
import { toSentences } from '@eluve/utils';

import { SegmentAudioContextProvider } from './SegmentAudio';
import { getReviewerAppointmentDetails } from './operations';
import { useAppointmentParams } from './useAppointmentParams';

export interface AppointmentTranscriptsProps {}
export const AppointmentTranscripts: React.FC<
  AppointmentTranscriptsProps
> = () => {
  const { tenantId, appointmentId } = useAppointmentParams();
  const [showConfidenceStyles, setShowConfidenceStyles] = useState(true);
  const [translatedContent, setTranslatedContent] = useState<string | null>(
    null,
  );
  const client = useApiClient();
  const [isLoadingUtteranceData, setIsLoadingUtteranceData] = useState(false);

  const { data, refetch } = useSuspenseQuery(getReviewerAppointmentDetails, {
    variables: {
      tenantId,
      appointmentId,
    },
    context: reviewerHasuraContext,
  });

  const availableUtterances = sumBy(
    data?.appointmentsByPk?.segments,
    (s) => s.utterances.length,
  );

  const prepareUtteranceData = async () => {
    setIsLoadingUtteranceData(true);
    await client.reviewer.prepareUtterances({
      params: {
        tenantId,
        appointmentId,
      },
    });

    refetch();

    setIsLoadingUtteranceData(false);
  };

  const { appointmentsByPk: appointment } = data;
  const segments = appointment?.segments ?? [];

  const transcripts = segments.map((seg) => ({
    segmentId: seg.id,
    transcript: seg.transcript,
    recordingStartedAt: seg.recordingStartedAt,
    pausedAt: seg.recordingDuration
      ? new Date(
          new Date(seg.recordingStartedAt).getTime() + seg.recordingDuration,
        ).toISOString()
      : undefined,
  }));

  const fullTranscript = transcripts.map((t) => t.transcript).join('\n');

  const parsedTranslatedContent: typeof transcripts = translatedContent
    ? JSON.parse(translatedContent)
    : null;

  const matches = useMatches();
  const isOnAdminPage = matches.some((m) => m.pathname.startsWith('/admin'));

  return (
    <div>
      <Box className="w-1/6 p-2">
        <Label>Need translation?</Label>
        <Translator
          setTranslatedContent={setTranslatedContent}
          originalContent={JSON.stringify(transcripts)}
        />
      </Box>
      <HStack align="start">
        <Card className="w-full">
          <EluveCardHeader>
            <HStack justify="between" wFull>
              <P className="whitespace-nowrap">
                Click on the text to jump to that portion of the audio
              </P>
              <HStack wFull={false}>
                <P className="whitespace-nowrap">Show confidence styles</P>
                <Switch
                  checked={showConfidenceStyles}
                  onCheckedChange={(checked) =>
                    setShowConfidenceStyles(checked)
                  }
                />
              </HStack>
            </HStack>
          </EluveCardHeader>
          <EluveCardContent>
            <VStack gap={5}>
              <HStack>
                {availableUtterances === 0 && (
                  <NewButton
                    size="s"
                    text="Load enriched data"
                    icon={{
                      name: 'CloudDownload',
                    }}
                    disabled={isLoadingUtteranceData}
                    onClick={prepareUtteranceData}
                  />
                )}
                <NewButton
                  size="s"
                  text="Copy Transcript"
                  icon={{
                    name: 'Copy',
                  }}
                  onClick={async () => {
                    await navigator.clipboard.writeText(fullTranscript);
                    toast.success('Transcript copied to clipboard');
                  }}
                />
              </HStack>
              {segments.map((s, i) => {
                return (
                  <SegmentAudioContextProvider
                    key={s.id}
                    index={i}
                    appointmentId={appointmentId}
                    seg={s}
                    segmentId={s.id}
                    tenantId={tenantId}
                    isReadonly={isOnAdminPage}
                  />
                );
              })}
            </VStack>
          </EluveCardContent>
        </Card>
        {Boolean(parsedTranslatedContent?.length) && (
          <Card className="w-full">
            <EluveCardHeader>
              <HStack justify="between" wFull>
                <P className="whitespace-nowrap">Translation</P>
              </HStack>
            </EluveCardHeader>
            <EluveCardContent>
              <VStack gap={5}>
                {transcripts.map((t, i) => {
                  return (
                    <VStack key={t.segmentId}>
                      <TranscriptTimestampDivider
                        timestamp={t.recordingStartedAt}
                        action={i === 0 ? 'started' : 'resumed'}
                      />
                      <TranscriptText>
                        {toSentences(
                          parsedTranslatedContent.find(
                            (pt) => pt.segmentId === t.segmentId,
                          )?.transcript ?? '',
                        )}
                      </TranscriptText>
                      {t.pausedAt && (
                        <TranscriptTimestampDivider
                          timestamp={t.pausedAt}
                          action="paused"
                        />
                      )}
                    </VStack>
                  );
                })}
              </VStack>
            </EluveCardContent>
          </Card>
        )}
      </HStack>
    </div>
  );
};
