import { useMount } from 'react-use';

import { FCC, bannerToast } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { getAndroidWebViewVersion, getIOSVersion } from '@eluve/utils';

// Public Component

export const CompatibilityChecker: FCC = ({ children }) => {
  const logger = useNamedLogger('CompatibilityChecker');

  useMount(() => {
    const iOSVersion = getIOSVersion();
    const isOSIncompatible = iOSVersion && iOSVersion <= 16;

    const androidWebViewVersion = getAndroidWebViewVersion();
    const isWebViewIncompatible =
      androidWebViewVersion && androidWebViewVersion <= 131;

    if (isOSIncompatible || isWebViewIncompatible) {
      logger.info('Notifying user of: incompatible operating system');
      bannerToast.warning('System Update Recommended', {
        id: 'incompatible-os',
        description:
          'Your current operating system is missing essential features needed for Eluve to work properly. Please update to a newer version if possible for full functionality!',
      });
    }
  });

  return children;
};
