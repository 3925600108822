import React from 'react';
import isEqual from 'react-fast-compare';
import { VariantProps } from 'tailwind-variants';

import { TooltipLabel } from '@eluve/blocks';
import { textStyles, tv } from '@eluve/components';

import { useSegmentAudioContext } from './SegmentAudioContextStore';

const transcriptConfidenceStyles = tv({
  base: '',
  variants: {
    confidence: {
      10: 'opacity-100',
      9: 'opacity-90',
      8: 'opacity-80',
      7: 'opacity-70',
      6: 'opacity-60',
      5: 'opacity-50',
      4: 'opacity-40',
      3: 'opacity-30',
      2: 'opacity-20',
    },
  },
});

type TranscriptConfidenceStyleVariantProps = VariantProps<
  typeof transcriptConfidenceStyles
>;

const TranscriptWord: React.FC<{
  className?: string;
  showConfidenceStyles: boolean;
  w: {
    confidence: number | null;
    punctuated_word?: string;
    word: string;
    start: number | null;
    end: number | null;
  };
}> = ({ w, showConfidenceStyles, className }) => {
  const { audioPlayerHandle } = useSegmentAudioContext();

  return (
    <TooltipLabel
      label={`Word: "${w.punctuated_word ?? w.word}", Confidence: ${w.confidence}`}
    >
      <span
        onClick={() => {
          if (w.start === null) return;
          audioPlayerHandle?.current?.seekTo(w.start);
        }}
        className={textStyles.body({
          className: [
            className,
            'inline-flex cursor-pointer hover:underline',
            showConfidenceStyles && w.confidence
              ? transcriptConfidenceStyles({
                  confidence: Math.floor(
                    w.confidence * 10,
                  ) as TranscriptConfidenceStyleVariantProps['confidence'],
                })
              : '',
          ],
        })}
      >
        {w.punctuated_word ?? w.word}
      </span>
    </TooltipLabel>
  );
};

export const MemoizedTranscriptWord = React.memo(TranscriptWord, isEqual);
