import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { FragmentOf } from '@eluve/graphql.tada';
import { deepgramRawMetadataSchema } from '@eluve/utils';

import { segmentFragment } from './operations';

export type Utterance = {
  id: string;
  updatedById: string | null;
  text: string | null;
  words: {
    word: string;
    punctuated_word?: string;
    start: number;
    end: number;
    confidence: number;
  }[];
};

type SegmentAudioContext = {
  audioUrl: string | null;
  segmentId: string;
  startedAt: string;
  pausedAt?: string;
  seekTo?: number;
  rawMetadata?: Record<string, unknown>;
};

type Result =
  | {
      type: 'context';
      context: SegmentAudioContext;
    }
  | {
      type: 'no_audio';
    };

export const useSegmentAudioData = ({
  tenantId,
  appointmentId,
  seg,
}: {
  tenantId: string;
  appointmentId: string;
  seg: FragmentOf<typeof segmentFragment>;
}) => {
  const api = useApiClient();
  const [ctx, setCtx] = useState<
    | { isLoading: true; result: null }
    | {
        isLoading: false;
        result: Result;
      }
  >({ isLoading: true, result: null });

  let audioUrl: string | null = null;

  useEffect(() => {
    let rawMetadata: Record<string, unknown> = {};
    const processAudio = async () => {
      try {
        const rawTranscript = seg.raw_transcript?.[0];
        const audioFile = rawTranscript?.user_upload;
        if (audioFile) {
          const audioFileId = audioFile.id;
          const result = await api.reviewer.getAppointmentFileSignedUrl({
            params: { tenantId, appointmentId, fileId: audioFileId! },
          });

          const rawMetadataFile = rawTranscript?.metadata_file;

          if (rawMetadataFile) {
            const metaDataResult =
              await api.reviewer.getAppointmentFileSignedUrl({
                params: {
                  tenantId,
                  appointmentId,
                  fileId: rawMetadataFile.id,
                },
              });

            if (
              metaDataResult.status === 200 &&
              metaDataResult.body.presignedUrl
            ) {
              // We need to download the raw metadata and make the JSON available
              const meta = await fetch(metaDataResult.body.presignedUrl);
              const json = await meta.json();
              // We currently only support the Deepgram metadata format
              const parsed = deepgramRawMetadataSchema.safeParse(json);
              if (parsed.success) {
                rawMetadata = json;
              }
            }

            if (result.status === 200 && result.body.presignedUrl) {
              audioUrl = result.body.presignedUrl;
            }
          }

          setCtx({
            isLoading: false,
            result: {
              type: 'context',
              context: {
                rawMetadata,
                audioUrl,
                segmentId: seg.id,
                startedAt: seg.recordingStartedAt,
                pausedAt: seg.recordingDuration
                  ? new Date(
                      new Date(seg.recordingStartedAt).getTime() +
                        seg.recordingDuration,
                    ).toISOString()
                  : undefined,
              },
            },
          });
        } else {
          setCtx({
            isLoading: false,
            result: { type: 'no_audio' },
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    processAudio();
  }, []);

  return ctx;
};
