import {
  OperationVariables,
  skipToken,
  useSuspenseQuery,
} from '@apollo/client';
import {
  ColumnFiltersState,
  PaginationState,
  Row,
  TableOptions,
  Updater,
  getCoreRowModel,
} from '@tanstack/react-table';
import { useDebounce } from '@uidotdev/usehooks';
import { TadaDocumentNode } from 'gql.tada';
import {
  Parser,
  createParser,
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  useQueryState,
  useQueryStates,
} from 'nuqs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { useDebounce as useDebounceEffect, useLatest } from 'react-use';
import { z } from 'zod';

import { DataTableFilterField, ExtendedSortingState } from './datatable-types';

export const sortingItemSchema = z.object({
  id: z.string(),
  desc: z.boolean(),
});

/**
 * Creates a parser for TanStack Table sorting state.
 * @param originalRow The original row data to validate sorting keys against.
 * @returns A parser for TanStack Table sorting state.
 */
export const getSortingStateParser = <TData extends Record<string, unknown>>(
  originalRow?: Row<TData>['original'],
) => {
  const validKeys = originalRow ? new Set(Object.keys(originalRow)) : null;

  return createParser<ExtendedSortingState<TData>>({
    parse: (value) => {
      try {
        const parsed = JSON.parse(value);
        const result = z.array(sortingItemSchema).safeParse(parsed);

        if (!result.success) return null;

        if (validKeys && result.data.some((item) => !validKeys.has(item.id))) {
          return null;
        }

        return result.data as ExtendedSortingState<TData>;
      } catch {
        return null;
      }
    },
    serialize: (value) => JSON.stringify(value),
    eq: (a, b) =>
      a.length === b.length &&
      a.every(
        (item, index) =>
          item.id === b[index]?.id && item.desc === b[index]?.desc,
      ),
  });
};

type Obj = Record<string, unknown>;

const DEFAULT_SORT: ExtendedSortingState<Obj> = [];
const DEFAULT_FILTER: DataTableFilterField<Obj>[] = [];

/**
 * A managed data table provider that knows how to
 * operate on a specified GraphQL query in order to
 * tie it to URL query params.
 *
 * This hook internally wraps a React Transition so that changes to the
 * query params are batched together and the old values remain in the UI
 * while the new network query resolves
 */
export const useDataTableForQuery = <
  TQueryData extends Obj,
  TQueryVariables extends OperationVariables,
  TRowData extends Obj = TQueryData,
>(args: {
  query: TadaDocumentNode<TQueryData, TQueryVariables>;
  convertToRows: (queryData: TQueryData) => TRowData[];
  convertSearchParamsToVariables: (args: {
    search: string | null;
    page: number;
    limit: number;
    sorting: ExtendedSortingState<TRowData>;
    filters: Record<string, string | string[] | null>;
    offset: number;
  }) => TQueryVariables;
  filterFields?: DataTableFilterField<TRowData>[];
  /**
   * It is important that this value is stable otherwise it will
   * trigger unnecessary network requests. Ensure you pass in a value
   * defined outside of render or a stable value computed with useMemo.
   */
  defaultSort?: ExtendedSortingState<TRowData>;
}) => {
  const isMount = useRef(true);
  const [isPending, startTransition] = useTransition();
  const latestIsPending = useLatest(isPending);

  const {
    query,
    convertToRows,
    convertSearchParamsToVariables,
    filterFields = DEFAULT_FILTER as DataTableFilterField<TRowData>[],
    defaultSort = DEFAULT_SORT as ExtendedSortingState<TRowData>,
  } = args;

  // Capture callback functions as refs in case we get passed some non stable
  // values to ensure we don't trigger unnecessary network requests.
  const latestConvertToRows = useLatest(convertToRows);
  const latestConvertParamsToVariables = useLatest(
    convertSearchParamsToVariables,
  );

  const { data, refetch } = useSuspenseQuery(
    query,
    isMount.current ? skipToken : undefined,
  );

  const rows = useMemo(() => {
    if (!data) {
      return [];
    }
    return latestConvertToRows.current(data as TQueryData);
  }, [data, latestConvertToRows]);

  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const latestPage = useLatest(page);
  const latestSetPage = useLatest(setPage);
  const [perPage, setPerPage] = useQueryState(
    'perPage',
    parseAsInteger.withDefault(10),
  );

  const [search, setSearch] = useQueryState('search', parseAsString);
  const debouncedSearch = useDebounce(search, 300);

  useEffect(
    function resetPaginationWhenSearchChanges() {
      if (latestPage.current !== 1) {
        latestSetPage.current(1);
      }
    },
    [debouncedSearch, latestSetPage, latestPage],
  );

  const pagination: PaginationState = {
    pageIndex: page - 1, // zero-based index -> one-based index
    pageSize: perPage,
  };

  const [sorting, setSorting] = useQueryState(
    'sort',
    getSortingStateParser<TRowData>().withDefault(defaultSort),
  );

  // Create parsers for each filter field
  const filterParsers = useMemo(() => {
    return filterFields.reduce<
      Record<string, Parser<string> | Parser<string[]>>
    >((acc, field) => {
      if (field.options) {
        // Faceted filter
        acc[field.id] = parseAsArrayOf(parseAsString, ',');
      } else {
        // Search filter
        acc[field.id] = parseAsString;
      }
      return acc;
    }, {});
  }, [filterFields]);

  // TODO(jesse)[ELU-2882] Improve type safety
  const [filters, setFilters] = useQueryStates(filterParsers);
  const initialColumnFilters: ColumnFiltersState = useMemo(() => {
    return Object.entries(filters).reduce<ColumnFiltersState>(
      (filters, [key, value]) => {
        if (value !== null) {
          filters.push({
            id: key,
            value: Array.isArray(value) ? value : [value],
          });
        }
        return filters;
      },
      [],
    );
  }, [filters]);

  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>(initialColumnFilters);

  // Memoize computation of searchableColumns and filterableColumns
  const { searchableColumns, filterableColumns } = React.useMemo(() => {
    return {
      searchableColumns: filterFields.filter((field) => !field.options),
      filterableColumns: filterFields.filter((field) => field.options),
    };
  }, [filterFields]);

  const onColumnFiltersChange = useCallback(
    (updaterOrValue: Updater<ColumnFiltersState>) => {
      setColumnFilters((prev) => {
        const next =
          typeof updaterOrValue === 'function'
            ? updaterOrValue(prev)
            : updaterOrValue;

        const filterUpdates = next.reduce<
          Record<string, string | string[] | null>
        >((acc, filter) => {
          if (searchableColumns.find((col) => col.id === filter.id)) {
            // For search filters, use the value directly
            acc[filter.id] = filter.value as string;
          } else if (filterableColumns.find((col) => col.id === filter.id)) {
            // For faceted filters, use the array of values
            acc[filter.id] = filter.value as string[];
          }
          return acc;
        }, {});

        prev.forEach((prevFilter) => {
          if (!next.some((filter) => filter.id === prevFilter.id)) {
            filterUpdates[prevFilter.id] = null;
          }
        });

        setPage(1);
        setFilters(filterUpdates);
        return next;
      });
    },
    [filterableColumns, searchableColumns, setFilters, setPage],
  );

  const onPaginationChange = (updaterOrValue: Updater<PaginationState>) => {
    if (typeof updaterOrValue === 'function') {
      const newPagination = updaterOrValue(pagination);
      setPage(newPagination.pageIndex + 1);
      setPerPage(newPagination.pageSize);
    } else {
      setPage(updaterOrValue.pageIndex + 1);
      setPerPage(updaterOrValue.pageSize);
    }
  };

  useDebounceEffect(
    function reRunQueryWhenSearchParamsChange() {
      if (latestIsPending.current) {
        // We want to return early if there is already a transition in progress
        // This is largely on a problem when in Strict mode in local dev
        return;
      }

      const variables = latestConvertParamsToVariables.current({
        sorting,
        search: debouncedSearch,
        page,
        limit: perPage,
        filters,
        offset: perPage * (page - 1),
      });

      isMount.current = false;

      startTransition(() => {
        refetch(variables);
      });
    },
    100,
    [
      page,
      perPage,
      sorting,
      filters,
      debouncedSearch,
      latestConvertParamsToVariables,
      latestIsPending,
      refetch,
    ],
  );

  const reactTableOptions: Omit<TableOptions<TRowData>, 'data' | 'columns'> = {
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange,
    onColumnFiltersChange,
    onSortingChange: (updaterOrValue) => {
      if (typeof updaterOrValue === 'function') {
        const newSorting = updaterOrValue(
          sorting,
        ) as ExtendedSortingState<TRowData>;
        setSorting(newSorting);
      }
    },
  };

  return {
    data,
    rows,
    pagination,
    filters,
    reactTableOptions,
    search,
    setSearch,
    isPending,
  };
};
