import { useReactTable } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { match } from 'ts-pattern';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import { AppointmentStatusLabel } from '@eluve/blocks';
import {
  ColDefBuilder,
  DataTableFilterField,
  ExtendedSortingState,
  HStack,
  PageTitle,
  ServerDataTable,
  Tag,
  useDataTableForQuery,
} from '@eluve/components';
import {
  AppointmentStatusEnum,
  AppointmentsBoolExp,
  AppointmentsOrderBy,
} from '@eluve/graphql-types';
import { ResultOf } from '@eluve/graphql.tada';

import { formatSnakeCaseLabel } from './formatSnakeCaseLabel';
import {
  appointmentTagOptionsFragment,
  reviewerTenantsFragment,
  reviewerUsersFragment,
  searchAppointmentsQuery,
} from './operations';
import { useLowQualityFactors } from './useLowQualityFactors';

type TableRow = {
  tenantId: string;
  tenantName: string;
  appointmentId: string;
  appointmentName: string;
  userId: string;
  userEmail: string;
  updatedAt: string;
  status: AppointmentStatusEnum;
  lowQualityFactors: string[];
  tags: { id: string; tag: string }[];
  annotationCount: number;
};

const columns = new ColDefBuilder<TableRow>()
  .detailsLink((row) => `${row.tenantId}/${row.appointmentId}`, 'Appointment')
  .defaultSortable('appointmentName', 'Name')
  .defaultSortable('tenantId', {
    label: 'Tenant',
    cellRenderer: (row) => row.tenantName,
  })
  .defaultSortable('userId', {
    label: 'User',
    cellRenderer: (row) => row.userEmail,
  })
  .dateSortable('updatedAt', 'Updated At')
  .defaultSortable('status', {
    cellRenderer: (row) => (
      <AppointmentStatusLabel
        // TODO(jesse)[ELU-2881]: Make these value available without having to do so many joins
        // in the query
        isSigned={false}
        isSummarized={false}
        status={row.status}
      />
    ),
  })
  .defaultSortable('annotationCount', '# Annotations')
  .colDef({
    id: 'tags',
    header: 'Tags',
    cell: ({ row }) => {
      return (
        <HStack>
          {row.original.tags.map((t) => (
            <Tag
              color="teal"
              size="xs"
              pill
              key={t.id}
              label={formatSnakeCaseLabel(t.tag)}
            />
          ))}
        </HStack>
      );
    },
  })
  .colDef({
    id: 'lowQualityFactors',
    header: 'Low Quality Factors',
    cell: ({ row }) => {
      return (
        <HStack>
          {row.original.lowQualityFactors.map((lqf) => (
            <Tag
              color="negative"
              size="xs"
              pill
              key={lqf}
              label={formatSnakeCaseLabel(lqf)}
            />
          ))}
        </HStack>
      );
    },
  })
  .build();

const convertToRows = (results: ResultOf<typeof searchAppointmentsQuery>) => {
  return (
    (results?.appointments ?? []).map<TableRow>((a) => {
      return {
        tenantId: a.tenant.id,
        tenantName: a.tenant.name,
        appointmentId: a.id,
        appointmentName: a.name,
        userId: a.user?.id ?? '',
        userEmail: a.user?.email ?? '',
        updatedAt: a.updatedAt,
        status: a.status!,
        lowQualityFactors: a.low_quality_factors.map((lqf) => lqf.factor),
        annotationCount: a.annotationsAggregate?.aggregate?.count ?? 0,
        tags: a.tags.map((t) => t.tag),
      };
    }) ?? []
  );
};

const defaultSort: ExtendedSortingState<TableRow> = [
  {
    id: 'updatedAt',
    desc: true,
  },
];

export const ReviewAppointmentsPage: React.FC = () => {
  const { tenants } = useCompleteFragment({
    fragment: reviewerTenantsFragment,
    key: QUERY_ROOT_ID,
  });

  const { users } = useCompleteFragment({
    fragment: reviewerUsersFragment,
    key: QUERY_ROOT_ID,
  });

  const lowQualityFactors = useLowQualityFactors();

  const { appointmentTagOptions: tagOptions } = useCompleteFragment({
    fragment: appointmentTagOptionsFragment,
    key: QUERY_ROOT_ID,
  });

  const filterFields: DataTableFilterField<TableRow>[] = useMemo(() => {
    const fields: DataTableFilterField<TableRow>[] = [
      {
        id: 'tenantId',
        label: 'Tenant',
        options: tenants.map((t) => ({
          label: t.name,
          value: t.id,
        })),
      },
      {
        id: 'userId',
        label: 'User',
        options: users.map((u) => ({
          label: u.email,
          value: u.id,
        })),
      },
      {
        id: 'status',
        label: 'Status',
        options: [
          { label: 'ACTIVE', value: 'ACTIVE' },
          { label: 'COMPLETED', value: 'COMPLETED' },
          { label: 'NOT_STARTED', value: 'NOT_STARTED' },
        ],
      },
      {
        id: 'tags',
        label: 'Tags',
        options: tagOptions.map((t) => ({
          label: t.tag,
          value: t.id,
        })),
      },
      {
        id: 'lowQualityFactors',
        label: 'Low Quality Factors',
        options: lowQualityFactors.map((lqf) => ({
          label: lqf.name.replaceAll('_', ' '),
          value: lqf.name,
        })),
      },
    ];

    return fields;
  }, [tenants, users, tagOptions, lowQualityFactors]);

  const { reactTableOptions, isPending, data, rows } = useDataTableForQuery({
    query: searchAppointmentsQuery,
    convertToRows,
    filterFields,
    defaultSort,
    convertSearchParamsToVariables: ({ limit, offset, sorting, filters }) => {
      const filterConditions: AppointmentsBoolExp[] = [];

      if (filters.tenantId && filters.tenantId.length) {
        filterConditions.push({
          tenantId: {
            _in: filters.tenantId as string[],
          },
        });
      }

      if (filters.userId && filters.userId.length) {
        filterConditions.push({
          userId: {
            _in: filters.userId as string[],
          },
        });
      }

      if (filters.status && filters.status.length) {
        filterConditions.push({
          status: {
            _in: filters.status as AppointmentStatusEnum[],
          },
        });
      }

      if (filters.tags && filters.tags.length) {
        filterConditions.push({
          tags: {
            tagId: {
              _in: filters.tags as string[],
            },
          },
        });
      }

      if (filters.lowQualityFactors && filters.lowQualityFactors.length) {
        filterConditions.push({
          low_quality_factors: {
            factor: {
              _in: filters.lowQualityFactors as string[],
            },
          },
        });
      }

      return {
        filter: {
          _and: filterConditions,
        },
        offset,
        limit,
        orderBy:
          sorting && sorting.length
            ? sorting.map(({ desc, id }) => ({
                ...match<keyof TableRow, AppointmentsOrderBy>(id)
                  .with('userId', () => ({
                    user: {
                      email: desc ? 'DESC' : 'ASC',
                    },
                  }))
                  .with('appointmentName', () => ({
                    name: desc ? 'DESC' : 'ASC',
                  }))
                  .with('tenantId', () => ({
                    tenant: {
                      name: desc ? 'DESC' : 'ASC',
                    },
                  }))
                  .with('annotationCount', () => ({
                    annotationsAggregate: {
                      count: desc ? 'DESC' : 'ASC',
                    },
                  }))
                  .otherwise(() => ({
                    [id]: desc ? 'DESC' : 'ASC',
                  })),
              }))
            : null,
      };
    },
  });

  const rowCount = data?.appointmentsAggregate?.aggregate?.count;

  const table = useReactTable({
    data: rows,
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <div>
      <PageTitle>Appointments</PageTitle>
      <ServerDataTable<TableRow>
        table={table}
        filterFields={filterFields}
        isPending={isPending}
      />
    </div>
  );
};
