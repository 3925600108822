import React from 'react';

import { PageTitle } from '@eluve/components';

import { AssetDeletionRequestsTable } from './AssetDeletionRequestsTable';

export const GlobalAssetDeletionHistoryPage: React.FC = () => {
  return (
    <div>
      <PageTitle> All Asset Deletion Requests </PageTitle>

      <AssetDeletionRequestsTable />
    </div>
  );
};
