import React, { useImperativeHandle, useState } from 'react';
import { useEffect, useRef } from 'react';
import { useLatest } from 'react-use';

import { useNamedLogger } from '@eluve/logger';

export type AudioPlayerHandle = {
  rewind: (seconds: number) => void;
  fastForward: (seconds: number) => void;
  seekTo: (seconds: number) => void;
  setPlaybackSpeed: (speed: number) => void;
};

export type AudioPlayerProps = {
  audioUrl?: string;
  onTimeUpdate?: (time: number) => void;
};

export const AudioPlayer = React.forwardRef<
  AudioPlayerHandle,
  AudioPlayerProps
>(({ audioUrl, onTimeUpdate }, ref) => {
  const logger = useNamedLogger('AudioPlayer');
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined);

  const latestOnTimeUpdate = useLatest(onTimeUpdate);

  useImperativeHandle(ref, () => ({
    rewind(seconds: number) {
      if (audioRef.current) {
        audioRef.current.currentTime = Math.max(
          0,
          audioRef.current.currentTime - seconds,
        );
      }
    },
    fastForward(seconds: number) {
      if (audioRef.current) {
        audioRef.current.currentTime = Math.min(
          audioRef.current.duration,
          audioRef.current.currentTime + seconds,
        );
      }
    },
    seekTo: (timestamp) => {
      if (audioRef.current) {
        audioRef.current.currentTime = timestamp;
      }
    },
    setPlaybackSpeed: (speed) => {
      if (audioRef.current) {
        audioRef.current.playbackRate = speed;
      }
    },
  }));

  useEffect(() => {
    let blobAudioUrl: string | undefined = undefined;
    const audioElement = audioRef.current;
    if (!audioElement || !audioUrl) return;

    if (latestOnTimeUpdate.current) {
      audioElement.addEventListener('timeupdate', () => {
        latestOnTimeUpdate.current?.(audioElement.currentTime);
      });
    }

    if ('MediaSource' in window) {
      const mediaSource = new MediaSource();
      const url = URL.createObjectURL(mediaSource);
      blobAudioUrl = url;
      setAudioSrc(url);

      let codecs: string;
      let format: string;
      if (audioUrl.includes('webm')) {
        codecs = 'opus';
        format = 'webm';
      } else {
        codecs = 'mp4a.40.2';
        format = 'mp4';
      }

      mediaSource.addEventListener('sourceopen', async () => {
        try {
          const sourceBuffer = mediaSource.addSourceBuffer(
            `audio/${format}; codecs="${codecs}"`,
          );
          const response = await fetch(audioUrl);
          const data = await response.arrayBuffer();
          sourceBuffer.appendBuffer(data);

          sourceBuffer.addEventListener('updateend', () => {
            mediaSource.endOfStream();
          });
        } catch {
          logger.warn(`Failed to open audio for URL ${audioUrl}`);
        }
      });
    }

    return () => {
      if (blobAudioUrl) {
        URL.revokeObjectURL(blobAudioUrl);
      }
    };
  }, [audioUrl, latestOnTimeUpdate, logger]);

  return <audio ref={audioRef} src={audioSrc} controls preload="metadata" />;
});
