import { useMutation } from '@apollo/client';
import React from 'react';

import {
  useAllAppointmentLlmOutputsForOutputType,
  useAppointmentContext,
  useAppointmentStatus,
  useLatestAppointmentLlmOutput,
  useListenForAppointmentLlmJobStatus,
  useListenForAppointmentLlmJobs,
} from '@eluve/frontend-appointment-hooks';
import { DifferentialDiagnosesOutput } from '@eluve/frontend-feature-appointment';
import {
  AppointmentStatusTypesLookup,
  FeedbackTypeLookup,
  LlmOutputTypesLookup,
  WorkerStatusTypesLookup,
} from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { RedFlagsOutputSchema } from '@eluve/llm-outputs';

const insertFeedbackMutation = graphql(`
  mutation insertSummaryFeedback(
    $appointmentId: uuid!
    $llmOutputId: uuid
    $type: FeedbackTypeEnum!
    $summarySection: String
  ) {
    insertSummaryFeedbackOne(
      object: {
        appointmentId: $appointmentId
        llmOutputId: $llmOutputId
        type: $type
        summarySection: $summarySection
      }
    ) {
      id
      type
      __typename
    }
  }
`);

export const DifferentialDiagnosesComponent: React.FC = () => {
  const { appointmentId } = useAppointmentContext();
  const appointmentStatus = useAppointmentStatus();

  // ai generated diagnoses
  const {
    appointmentLlmOutput: ddxAndRedFlagsOutput,
    refetch: refetchDDxAndRedFlags,
  } = useLatestAppointmentLlmOutput({
    outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
  });

  useListenForAppointmentLlmJobs({
    outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
    lastJobCompletedAt: ddxAndRedFlagsOutput?.completedAt ?? null,
    onDataReceived: refetchDDxAndRedFlags,
  });

  // user added diagnoses
  const { outputs: userAddedDDxOutput, refetch: refetchUserAddedDDx } =
    useAllAppointmentLlmOutputsForOutputType({
      outputType: LlmOutputTypesLookup.DIAGNOSES_JUSTIFICATION,
    });

  useListenForAppointmentLlmJobs({
    outputType: LlmOutputTypesLookup.DIAGNOSES_JUSTIFICATION,
    lastJobCompletedAt: null,
    onDataReceived: refetchUserAddedDDx,
  });

  const { status: ddxStatus } = useListenForAppointmentLlmJobStatus({
    outputType: LlmOutputTypesLookup.DIAGNOSES_JUSTIFICATION,
    shouldSubscribe: appointmentStatus === AppointmentStatusTypesLookup.ACTIVE,
  });

  // feedback handler
  const [insertFeedback] = useMutation(insertFeedbackMutation);

  const handleFeedback = async (isHelpful: boolean, diagnosis: string) => {
    await insertFeedback({
      variables: {
        appointmentId,
        summarySection: diagnosis,
        type: isHelpful
          ? FeedbackTypeLookup.POSITIVE
          : FeedbackTypeLookup.NEGATIVE,
        llmOutputId: ddxAndRedFlagsOutput?.llmOutputId,
      },
    });
  };

  const ddxAndRedFlags = ddxAndRedFlagsOutput?.content
    ? (ddxAndRedFlagsOutput.content as RedFlagsOutputSchema)
    : null;

  if (!ddxAndRedFlags) {
    return null;
  }

  const hasUserAddedDDx = Boolean(userAddedDDxOutput?.length);

  return (
    <DifferentialDiagnosesOutput
      showLoadingPlaceholder={ddxStatus === WorkerStatusTypesLookup.IN_PROGRESS}
      userAddedDiagnoses={
        hasUserAddedDDx
          ? userAddedDDxOutput!.map((output) => output.content)
          : undefined
      }
      content={ddxAndRedFlags.differential_diagnoses}
      showTitle={false}
      onFeedback={handleFeedback}
    />
  );
};
