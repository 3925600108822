import React, { useMemo } from 'react';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import { ColDefBuilder, DataTable } from '@eluve/components';
import { LlmOutputTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { isLlmOutputSchedulable } from '@eluve/llm-outputs';

import {
  TenantLlmOutputToggle,
  tenantLlmOutputFragment,
} from './TenantLlmOutputToggle';

export const tenantLlmOutputsFragment = graphql(
  `
    fragment TenantLlmOutputs on Tenants @_unmask {
      __typename
      id
      tenant_llm_outputs {
        ...TenantLlmOutput
      }
    }
  `,
  [tenantLlmOutputFragment],
);

export const llmOutputTypesFragment = graphql(`
  fragment LlmOutputTypes on query_root @_unmask {
    llmOutputTypes {
      __typename
      name
      description
    }
  }
`);

type TableRow = {
  name: LlmOutputTypesEnum;
  isEnabled: boolean;
  tenantId: string;
};

const colDefs = new ColDefBuilder<TableRow>()
  .defaultSortable('name')
  .defaultSortable('isEnabled', {
    label: 'Is Enabled',
    cellRenderer: ({ name, tenantId }) => (
      <TenantLlmOutputToggle tenantId={tenantId} outputType={name} />
    ),
  })
  .build();

export const TenantLlmOutputs: React.FC<{ tenantId: string }> = ({
  tenantId,
}) => {
  const outputTypes = useCompleteFragment({
    fragment: llmOutputTypesFragment,
    key: QUERY_ROOT_ID,
  });

  const tenantOutputTypes = useCompleteFragment({
    fragment: tenantLlmOutputsFragment,
    key: {
      id: tenantId,
    },
  });

  const rows = useMemo(() => {
    return (outputTypes?.llmOutputTypes ?? [])
      .filter((o) => isLlmOutputSchedulable(o.name))
      .map<TableRow>((o) => {
        const tenantOutputType = tenantOutputTypes?.tenant_llm_outputs.find(
          (t) => t.llmOutputName === o.name,
        );

        return {
          isEnabled: tenantOutputType?.isEnabled ?? false,
          name: o.name as LlmOutputTypesEnum,
          tenantId: tenantId,
        };
      });
  }, [
    outputTypes?.llmOutputTypes,
    tenantId,
    tenantOutputTypes?.tenant_llm_outputs,
  ]);

  return (
    <DataTable
      data={rows}
      columns={colDefs}
      enableGlobalSearch
      isCompact
      isPaginated={false}
      initialSortingState={[{ id: 'name', desc: false }]}
    />
  );
};
