import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { BackBreadcrumbItem } from '@eluve/blocks';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  SidebarTrigger,
} from '@eluve/components';
import { basicPatientDetailsFragment } from '@eluve/frontend-appointment-hooks';
import { formatHumanName } from '@eluve/utils';

import { PatientDetailsPage } from './PatientDetailsPage';

export const PatientDetailsPageLayout: React.FC = () => {
  const { tenantId, patientId } = useParams() as {
    tenantId: string;
    patientId: string;
  };

  const basicDetails = useCompleteFragment({
    fragment: basicPatientDetailsFragment,
    key: {
      id: patientId,
    },
    strict: false,
  });

  return (
    <ReskinMain>
      <ReskinHeader>
        <SidebarTrigger />
        <Breadcrumb className="flex-1">
          <BreadcrumbList>
            <BackBreadcrumbItem />
            <BreadcrumbItem>
              <Link to={`/tenants/${tenantId}/patients`}>Patients</Link>
            </BreadcrumbItem>

            <BreadcrumbSeparator>/</BreadcrumbSeparator>
            <BreadcrumbItem className="text-gray-12 privacy-text">
              {basicDetails?.firstName
                ? formatHumanName(basicDetails.firstName, basicDetails.lastName)
                : 'Loading...'}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </ReskinHeader>
      <ReskinContent variant="fullWidth">
        <PatientDetailsPage />
      </ReskinContent>
    </ReskinMain>
  );
};
