import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const adminAppointmentContract = c.router(
  {
    deleteAppointmentAssets: {
      method: 'POST',
      path: '/asset-deletion/:tenantId/:appointmentId',
      body: z.object({}).optional(),
      responses: {
        201: z.object({
          jobId: z.string(),
        }),
        400: z.object({
          message: z.string(),
        }),
      },
    },
    deleteAppointmentAudioFiles: {
      method: 'POST',
      path: '/asset-deletion/audio-files/:tenantId/:appointmentId',
      body: z.object({}).optional(),
      responses: {
        201: z.object({
          jobId: z.string(),
        }),
        400: z.object({
          message: z.string(),
        }),
      },
    },
    getAppointmentFileSignedUrl: {
      method: 'GET',
      path: '/file/:tenantId/:appointmentId/:fileId',
      responses: {
        200: z.object({
          presignedUrl: z.string().nullable(),
        }),
      },
      summary:
        'Requests a presigned GCS Url for downloading an appointment file',
    },
  },
  { pathPrefix: '/admin-appointment' },
);
