import { useState } from 'react';

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Divider,
  HStack,
  Icons,
  NewButton,
  P,
  VStack,
  textStyles,
} from '@eluve/components';
import { redFlagsSchema } from '@eluve/llm-outputs';

// Private Components

interface RedFlagCardProps {
  callout: string;
  context: string;
  onFeedback?: (isHelpful: boolean, redFlag: string) => void;
}

const RedFlagCard: React.FC<RedFlagCardProps> = ({
  callout,
  context,
  onFeedback,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState<boolean>(false);
  const [isHelpful, setIsHelpful] = useState<boolean>(false);

  const feedbackHandler = (isHelpful: boolean) => {
    setFeedbackGiven(true);
    setIsHelpful(isHelpful);
    onFeedback?.(isHelpful, callout);
  };

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="w-full pl-4 pr-3"
    >
      <CollapsibleTrigger className="w-full p-2">
        <HStack justify="between">
          <P className="text-contentSecondary">{callout}</P>
          <Icons.ChevronDown
            className='size-5 text-contentSupporting transition-transform duration-300 ease-in-out data-[state="open"]:-rotate-180'
            data-state={isOpen ? 'open' : 'closed'}
          />
        </HStack>
      </CollapsibleTrigger>
      <CollapsibleContent className="p-2">
        <VStack className="rounded-xl bg-backgroundSecondary p-4">
          <P
            className={textStyles.body({
              weight: 'regular',
              size: 'm',
              className: 'text-contentTertiary',
            })}
          >
            {context}
          </P>
          <Divider className="border-border1" />
          <HStack justify="between">
            <P
              className={textStyles.body({
                weight: 'medium',
                size: 's',
                color: 'tertiary',
              })}
            >
              Was this insight helpful?
            </P>
            <HStack className="w-fit">
              <NewButton
                text="Yes"
                type={
                  feedbackGiven && isHelpful === false
                    ? 'ghost'
                    : 'outlineSubtle'
                }
                size="s"
                disabled={feedbackGiven}
                onClick={() => feedbackHandler(true)}
              />
              <NewButton
                text="No"
                type={
                  feedbackGiven && isHelpful === true
                    ? 'ghost'
                    : 'outlineSubtle'
                }
                size="s"
                disabled={feedbackGiven}
                onClick={() => feedbackHandler(false)}
              />
            </HStack>
          </HStack>
        </VStack>
      </CollapsibleContent>
    </Collapsible>
  );
};

// Public Components

export interface RedFlagsOutputProps {
  content: Record<string, unknown> | Record<string, unknown>[];
  onFeedback: (isHelpful: boolean, redFlag: string) => void;
  onShowDiagnoses: () => void;
}

export const RedFlagsOutput: React.FC<RedFlagsOutputProps> = ({
  content,
  onFeedback,
  onShowDiagnoses,
}) => {
  const redFlags = redFlagsSchema.safeParse(content);

  if (!redFlags.success) {
    return null;
  }

  if (redFlags.data.length === 0) {
    return (
      <VStack className="px-6 py-2">
        <P
          className={textStyles.body({
            weight: 'semibold',
            size: 'm',
            className: 'text-contentPrimary',
          })}
        >
          No red flags identified
        </P>
        <P
          className={textStyles.body({
            weight: 'regular',
            size: 'm',
            className: 'mb-3 text-contentSecondary',
          })}
        >
          Review the Differential Diagnosis tab to see how the key indicators
          were assessed based on the conversation and evidence.
        </P>
        <NewButton
          onClick={onShowDiagnoses}
          size="s"
          text="View DDx"
          type="outline"
        />
      </VStack>
    );
  }

  return (
    <VStack gap={1}>
      {redFlags.data.map((redFlag) => (
        <RedFlagCard
          key={redFlag.callout}
          callout={redFlag.callout}
          context={redFlag.context}
          onFeedback={onFeedback}
        />
      ))}
    </VStack>
  );
};
