import { fromCallback } from 'xstate';

import { CorrelatedLogger } from '@eluve/logger';
import { SupportedAudioFormat } from '@eluve/user-local-files';

import { AudioPacketSubject } from '../types';

import { MuteDetector } from './MuteDetector';

export type MediaRecorderEvents =
  | {
      type: 'recorder.stop';
    }
  | {
      type: 'recorder.started';
      mediaStream: MediaStream;
    }
  | {
      type: 'recorder.error';
      error: Error;
    }
  | { type: 'microphone.disconnected'; microphoneName?: string }
  | { type: 'microphone.muted' }
  | { type: 'microphone.unmuted' };

type Input = {
  supportedAudioFormat: SupportedAudioFormat;
  audioPacketSubject: AudioPacketSubject;
  deviceId?: string;
  logger: CorrelatedLogger;
};

export const mediaRecorder = fromCallback<MediaRecorderEvents, Input>(
  ({ input, sendBack, receive }) => {
    const { audioPacketSubject, supportedAudioFormat, deviceId, logger } =
      input;
    let mediaRecorder: MediaRecorder | null = null;
    let mediaStream: MediaStream | null = null;
    let muteDetector: MuteDetector | null = null;

    const handleDisconnectEvent = () =>
      sendBack({ type: 'microphone.disconnected' });

    const handleMuteEvent = () => {
      logger('warn', 'Microphone is muted.', { deviceId });
      sendBack({ type: 'microphone.muted' });
    };

    const handleUnmuteEvent = () => {
      sendBack({ type: 'microphone.unmuted' });
    };

    const handleDataAvailable = (event: BlobEvent) => {
      audioPacketSubject.next({
        data: event.data,
        timestamp: event.timeStamp,
      });
    };

    const handleMediaRecorderError = (event: Event) => {
      logger('error', 'Error occurred while recording audio.', {
        event,
      });

      sendBack({
        type: 'recorder.error',
        error: new Error('An error occurred while recording audio.'),
      });
    };

    const stopRecorder = () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener('dataavailable', handleDataAvailable);
        mediaRecorder.removeEventListener('error', handleMediaRecorderError);
      }

      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
          track.removeEventListener('ended', handleDisconnectEvent);
        });
      }

      if (muteDetector) {
        muteDetector.stop();
        muteDetector.removeEventListener('mute', handleMuteEvent);
        muteDetector.removeEventListener('unmute', handleUnmuteEvent);
      }

      mediaRecorder = null;
      mediaStream = null;
      muteDetector = null;
    };

    const startRecorder = async () => {
      // Getting the media stream from the audio input device
      try {
        mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: deviceId
            ? {
                deviceId,
              }
            : true,
        });
        mediaStream.getAudioTracks().forEach((track) => {
          track.addEventListener('ended', handleDisconnectEvent);
        });
      } catch (error) {
        sendBack({
          type: 'recorder.error',
          error: new Error(
            `An error occurred while getting stream from audio input device. Error: ${(error as Error)?.message}`,
          ),
        });

        return;
      }

      mediaRecorder = new MediaRecorder(mediaStream, {
        mimeType: supportedAudioFormat.mimeType,
      });

      mediaRecorder.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorder.addEventListener('error', handleMediaRecorderError);

      mediaRecorder.start(100);

      muteDetector = new MuteDetector(mediaStream);
      muteDetector.addEventListener('mute', handleMuteEvent);
      muteDetector.addEventListener('unmute', handleUnmuteEvent);

      muteDetector.start();

      sendBack({
        type: 'recorder.started',
        mediaStream,
      });
    };

    startRecorder();

    receive((event) => {
      if (event.type === 'recorder.stop') {
        stopRecorder();
        return;
      }
    });

    return () => {
      stopRecorder();
    };
  },
);
