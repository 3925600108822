import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { ExternalArtifactTypesEnum } from './types';

export const ExternalArtifactTypesLookup = {
  INTAKE_FORM: 'INTAKE_FORM',
  LAB_RESULTS: 'LAB_RESULTS',
  TEMP_MEDICAL_NOTES: 'TEMP_MEDICAL_NOTES',
  HISTORICAL_CHART: 'HISTORICAL_CHART',
} as const satisfies MatchingRecord<ExternalArtifactTypesEnum>;

export const externalArtifactTypesSchema = z.nativeEnum(
  ExternalArtifactTypesLookup,
);
