import { useLazyQuery, useMutation } from '@apollo/client';
import { Editor as EditorClass } from '@tiptap/core';
import { Extension } from '@tiptap/react';
import capitalize from 'lodash/capitalize';
import { Loader2 } from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import { useLatest } from 'react-use';

import { CopyRichTextButton } from '@eluve/blocks';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  cn,
  textStyles,
} from '@eluve/components';
import {
  BillingCodeExtension,
  EluveEditor,
  EluveEditorHandle,
  SearchableCode,
} from '@eluve/eluve-editor';
import { graphql } from '@eluve/graphql.tada';
import { areStringsEqualWithoutSpecialCharacters } from '@eluve/utils';
import { AppointmentSummaryKeys } from '@eluve/utils';

import './SummarySectionMarkdown.css';
import { useIsFeatureFlagEnabled } from './useIsFeatureFlagEnabled';

const searchCodesQuery = graphql(`
  query searchCodes($query: String!) {
    searchMedicalCodes(args: { search_term: $query }) {
      __typename
      id
      code
      codeType
      description
    }
  }
`);

const addAppointmentCodeMutation = graphql(`
  mutation addAppointmentCode($appointmentId: uuid!, $codeId: uuid!) {
    insertAppointmentBillingCodesOne(
      object: { appointmentId: $appointmentId, billingCodeId: $codeId }
    ) {
      __typename
      id
    }
  }
`);

export type SummarySectionMarkdownAction = React.FC<{
  getSummary: () => string | null | undefined;
}>;

export interface SummarySectionMarkdownProps {
  appointmentId: string;
  className?: string;
  summaryKey: AppointmentSummaryKeys;
  sectionTitle: string;
  content?: string;
  loading?: boolean;
  handleContentChange: (
    key: AppointmentSummaryKeys,
    newContent: string,
  ) => void;
  disabled: boolean;
  additionalActions?: SummarySectionMarkdownAction[];
}

export const SummarySectionMarkdown: React.FC<SummarySectionMarkdownProps> = ({
  appointmentId,
  className,
  summaryKey,
  sectionTitle: title,
  content,
  loading,
  handleContentChange,
  disabled,
  additionalActions,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<EluveEditorHandle | null>(null);
  const billingCodesEnabled = useIsFeatureFlagEnabled('INLINE_BILLING_CODES');
  const latestContent = useLatest(content);

  const [searchCodes] = useLazyQuery(searchCodesQuery);
  const [addAppointmentCode] = useMutation(addAppointmentCodeMutation, {});

  const onSearchCodes = async (query: string): Promise<SearchableCode[]> => {
    if (!query) {
      return [];
    }

    const { data } = await searchCodes({
      variables: {
        query,
      },
    });

    return (data?.searchMedicalCodes ?? []).map((code) => ({
      id: code.id,
      title: code.code,
      type: code.codeType,
      description: code.description ?? '',
    }));
  };

  // TODO(jesse)[ELU-981]: This should be debounced so we don't spam
  // updates across the tree with every keystroke
  const onEditorContentUpdated = (
    editor?: EditorClass | undefined,
  ): void | Promise<void> => {
    if (editor) {
      if (
        areStringsEqualWithoutSpecialCharacters(
          latestContent.current,
          editor.storage.markdown.getMarkdown(),
        )
      ) {
        return;
      }
      const content = editor.storage.markdown.getMarkdown();
      handleContentChange(summaryKey, content);
    }
  };

  useEffect(() => {
    editorRef?.current?.setEditable(!loading && !disabled);
  }, [editorRef, disabled, loading]);

  const additionalExtensions: Extension[] = [];
  if (billingCodesEnabled) {
    additionalExtensions.push(
      BillingCodeExtension.configure({
        codeOptions: {
          searchCodes: onSearchCodes,
          onCodeAdded: async (code) => {
            await addAppointmentCode({
              variables: {
                appointmentId,
                codeId: code.id,
              },
            });
          },
        },
      }),
    );
  }

  const showEditor = Boolean(!loading || (loading && content));

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className={cn('viewer [&_div[id^=tippy-]]:!z-20', className)}>
      <Card className="w-full rounded-xl border-gray-4">
        <CardHeader className="flex h-10 flex-row place-items-center justify-between rounded-t-xl border-b bg-gray-1 p-0 px-5 tracking-normal">
          <Box hStack className="gap-2">
            <h3 className={textStyles.body({ size: 'm', weight: 'semibold' })}>
              <b className="font-semibold">
                {title
                  .split('_')
                  .map((s) => capitalize(s))
                  .join(' ')}
              </b>
            </h3>
            {!loading && Boolean(content?.length) && (
              <>
                <CopyRichTextButton
                  getContentElement={() => contentRef.current}
                  hideContentInTooltip
                  markdownContent={content}
                />
                {additionalActions?.map((Action, i) => (
                  <Action key={i} getSummary={() => latestContent.current} />
                ))}
              </>
            )}
          </Box>
          {loading && <Loader2 className="h-5 animate-spin text-brand-8" />}
        </CardHeader>
        <hr className="sr-only" />
        <CardContent
          className="flex flex-col justify-between gap-5 rounded-b-xl bg-white p-4"
          ref={contentRef}
        >
          {loading && !content && (
            <>
              <Skeleton className="h-3" />
              <Skeleton className="h-3" />
            </>
          )}

          {showEditor && (
            <EluveEditor
              ref={editorRef}
              content={content}
              onUpdate={onEditorContentUpdated}
              extensions={additionalExtensions}
            />
          )}
        </CardContent>
      </Card>
      <br className="sr-only" />
    </div>
  );
};
