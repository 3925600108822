import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import React, { useState } from 'react';
import { match } from 'ts-pattern';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  Card,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Divider,
  EluveCardContent,
  EluveCardHeader,
  H5,
  H6,
  HStack,
  Icon,
  LinearLogo,
  LinkButton,
  NewButton,
  P,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Textarea,
  VStack,
  createSidebar,
  toast,
} from '@eluve/components';
import { timestampToHumanReadable } from '@eluve/date-utils';
import { useSession } from '@eluve/session-helpers';
import { EluveAdminOnly } from '@eluve/smart-blocks';
import { isLinearIssueLink } from '@eluve/utils';

import { AddAppointmentReviewLinkDialogForm } from './AddAppointmentReviewLinkDialogForm';
import { AppointmentTags } from './AppointmentTags';
import { formatSnakeCaseLabel } from './formatSnakeCaseLabel';
import {
  addLowQualityFactorMutation,
  appointmentAnnotationsFragment,
  appointmentLowQualityFactorsFragment,
  appointmentReviewLinksFragment,
  createAppointmentAnnotationMutation,
  removeAppointmentReviewLinkMutation,
  removeLowQualityFactorMutation,
} from './operations';
import { useAppointmentParams } from './useAppointmentParams';
import { useLowQualityFactors } from './useLowQualityFactors';

export interface AppointmentAnnotationToolsProps {}

const { DetailsSidebar, DetailsSidebarTrigger } = createSidebar({
  mode: 'drawer',
});

export const AppointmentAnnotationTools: React.FC<
  AppointmentAnnotationToolsProps
> = () => {
  const { tenantId, appointmentId } = useAppointmentParams();
  const [annotation, setAnnotation] = useState('');
  const [factorsOpen, setFactorsOpen] = useState(false);
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [toolsPanelOpen, setToolsPanelOpen] = useState(true);
  const email = useSession().email;

  const { annotations } = useCompleteFragment({
    fragment: appointmentAnnotationsFragment,
    key: {
      id: appointmentId,
    },
  });

  const { low_quality_factors: selectedQualityFactors } = useCompleteFragment({
    fragment: appointmentLowQualityFactorsFragment,
    key: {
      id: appointmentId,
    },
  });

  const { review_links } = useCompleteFragment({
    fragment: appointmentReviewLinksFragment,
    key: {
      id: appointmentId,
    },
  });

  const allLowQualityFactors = useLowQualityFactors();

  const availableFactors = allLowQualityFactors.filter(
    (f) => !selectedQualityFactors.some((lqf) => lqf.factor === f.name),
  );

  const [removeAppointmentReviewLink] = useMutation(
    removeAppointmentReviewLinkMutation,
    {
      onError: () => toast.error('Error removing link.'),
      optimisticResponse: ({ id }) => ({
        deleteAppointmentReviewLinksByPk: {
          __typename: 'AppointmentReviewLinks' as const,
          id,
        },
      }),
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentReviewLinksFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            const removedFactor = data?.deleteAppointmentReviewLinksByPk;
            if (!existing || !removedFactor) {
              return existing;
            }

            return {
              ...existing,
              review_links: existing.review_links.filter(
                (l) => l.id !== removedFactor.id,
              ),
            };
          },
        );
      },
    },
  );

  const [createAppointmentAnnotation] = useMutation(
    createAppointmentAnnotationMutation,
    {
      onError: () => toast.error('Error saving annotation.'),
      optimisticResponse: ({ input }) => ({
        insertAppointmentAnnotationsOne: {
          __typename: 'AppointmentAnnotations' as const,
          id: 'temp-id',
          createdAt: new Date().toISOString(),
          annotation: {
            __typename: 'Annotations' as const,
            id: 'temp-id',
            annotation: input.annotation?.data.annotation ?? '',
          },
        },
      }),
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentAnnotationsFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing || !data?.insertAppointmentAnnotationsOne) {
              return existing;
            }

            const newAnnotation = data.insertAppointmentAnnotationsOne;

            return produce(existing, (draft) => {
              draft.annotations.push({
                ...newAnnotation,
                userEmail: email,
              });
            });
          },
        );
      },
    },
  );

  const [removeLowQualityFactor] = useMutation(removeLowQualityFactorMutation, {
    onError: () => toast.error('Error removing Low Quality Factor.'),
    optimisticResponse: ({ id }) => ({
      deleteAppointmentLowQualityFactorsByPk: {
        __typename: 'AppointmentLowQualityFactors' as const,
        id,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: appointmentLowQualityFactorsFragment,
          key: {
            id: appointmentId,
          },
        },
        (existing) => {
          const removedFactor = data?.deleteAppointmentLowQualityFactorsByPk;
          if (!existing || !removedFactor) {
            return existing;
          }

          return {
            ...existing,
            low_quality_factors: existing.low_quality_factors.filter(
              (lqf) => lqf.id !== removedFactor.id,
            ),
          };
        },
      );
    },
  });

  const [addLowQualityFactor] = useMutation(addLowQualityFactorMutation, {
    onCompleted: () => toast.success('Low Quality Factor added.'),
    onError: () => toast.error('Error adding Low Quality Factor.'),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: appointmentLowQualityFactorsFragment,
          key: {
            id: appointmentId,
          },
        },
        (existing) => {
          const newFactor = data?.insertAppointmentLowQualityFactorsOne;
          if (!existing || !newFactor) {
            return existing;
          }

          return {
            ...existing,
            low_quality_factors: [...existing.low_quality_factors, newFactor],
          };
        },
      );
    },
  });

  const handleSaveAnnotation = () => {
    createAppointmentAnnotation({
      variables: {
        input: {
          tenantId,
          appointmentId,
          annotation: {
            data: {
              annotation,
            },
          },
        },
      },
    });
    setAnnotation('');
  };

  const handleAddLowQualityFactor = (factor: string) => {
    addLowQualityFactor({
      variables: {
        input: {
          tenantId,
          appointmentId,
          factor,
        },
      },
    });
  };

  return (
    <>
      <DetailsSidebar className="lg:w-[50vw]" blurBackground={false}>
        <VStack>
          <H5>Annotations</H5>
          <VStack>
            {annotations.map((a) => (
              <Card key={a.id}>
                <EluveCardHeader>
                  <HStack wrap justify="between">
                    <P>{a.userEmail}</P>
                    <P>
                      {timestampToHumanReadable(
                        new Date(a.createdAt).getTime(),
                      )}
                    </P>
                  </HStack>
                </EluveCardHeader>
                <EluveCardContent>{a.annotation.annotation}</EluveCardContent>
              </Card>
            ))}
          </VStack>
          <Textarea
            value={annotation}
            onChange={(e) => setAnnotation(e.target.value)}
          />
          <NewButton
            text="Save"
            disabled={!annotation}
            onClick={handleSaveAnnotation}
          />
        </VStack>
      </DetailsSidebar>
      <Card>
        <Collapsible open={toolsPanelOpen} onOpenChange={setToolsPanelOpen}>
          <EluveCardHeader>
            <CollapsibleTrigger asChild>
              <HStack justify="between" className="cursor-pointer">
                Tools
                <NewButton
                  type="outline"
                  size="s"
                  icon={{
                    name: toolsPanelOpen ? 'ChevronUp' : 'ChevronDown',
                  }}
                />
              </HStack>
            </CollapsibleTrigger>
          </EluveCardHeader>
          <CollapsibleContent>
            <EluveCardContent>
              <VStack>
                <EluveAdminOnly>
                  <VStack className="mb-4 rounded-lg border border-warning700 p-3 shadow-md">
                    <H6>Eluve Admin</H6>
                    <HStack>
                      <LinkButton
                        text="View in Admin Dashboard"
                        to={`/admin/tenants/${tenantId}/appointments/${appointmentId}`}
                        type="outline"
                      />
                      <LinkButton
                        text="View in App"
                        to={`/tenants/${tenantId}/appointments/${appointmentId}`}
                        type="outline"
                      />
                    </HStack>
                  </VStack>
                </EluveAdminOnly>
                <VStack>
                  <H6>Low Quality Factors</H6>
                  <HStack wrap>
                    {selectedQualityFactors.map((lqf) => (
                      <Tag
                        key={lqf.factor}
                        color="negative"
                        size="s"
                        label={formatSnakeCaseLabel(lqf.factor)}
                        pill
                      >
                        <button
                          onClick={() =>
                            removeLowQualityFactor({
                              variables: {
                                id: lqf.id,
                              },
                            })
                          }
                          className="-m-2 -mr-2.5 h-8 w-8 cursor-pointer rounded-full border-8 border-transparent bg-clip-padding p-px hover:bg-negative950 hover:text-negative400"
                        >
                          <Icon name="X" size="xxs" />
                        </button>
                      </Tag>
                    ))}
                    <Popover open={factorsOpen} onOpenChange={setFactorsOpen}>
                      <PopoverTrigger asChild>
                        <NewButton
                          disabled={availableFactors.length === 0}
                          icon={{ name: 'Plus' }}
                          size="s"
                          text="Add"
                          type="outline"
                        />
                      </PopoverTrigger>
                      <PopoverContent side="right" align="start">
                        <Command>
                          <CommandInput placeholder="Select factor" />
                          <CommandList>
                            <CommandGroup>
                              {availableFactors.map((f) => (
                                <CommandItem
                                  key={f.name}
                                  onSelect={() =>
                                    handleAddLowQualityFactor(f.name)
                                  }
                                >
                                  {formatSnakeCaseLabel(f.name)}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </HStack>
                </VStack>
                <Divider />
                <VStack>
                  <H6>Tags</H6>
                  <AppointmentTags
                    tenantId={tenantId}
                    appointmentId={appointmentId}
                  />
                </VStack>
                <Divider />
                <VStack>
                  <H6>Links</H6>
                  <HStack wrap>
                    {review_links.map((l) => (
                      <HStack
                        wFull={false}
                        key={l.link}
                        className="rounded-[8px] border border-brandGray200 bg-white px-2 text-brandGray800 hover:border-brandGray100"
                      >
                        <a
                          className="flex items-center gap-2 hover:text-brandGray700"
                          href={l.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {match(isLinearIssueLink(l.link))
                            .with(true, () => <LinearLogo className="size-5" />)
                            .otherwise(() => (
                              <Icon name="ExternalLink" size="xxs" />
                            ))}

                          {l.label ?? l.link}
                        </a>
                        <div
                          className="-m-2 -mr-2.5 h-8 w-8 cursor-pointer rounded-full border-8 border-transparent bg-clip-padding p-px hover:bg-brandGray950 hover:text-brandGray400"
                          onClick={() =>
                            removeAppointmentReviewLink({
                              variables: {
                                id: l.id,
                              },
                            })
                          }
                        >
                          <Icon name="X" size="xxs" />
                        </div>
                      </HStack>
                    ))}
                    <NewButton
                      icon={{ name: 'Plus' }}
                      size="s"
                      text="Add"
                      type="outline"
                      onClick={() => setIsAddingLink(true)}
                    />
                    <AddAppointmentReviewLinkDialogForm
                      isDialogOpen={isAddingLink}
                      setIsDialogOpen={setIsAddingLink}
                    />
                  </HStack>
                </VStack>
                <Divider />
                <VStack>
                  <DetailsSidebarTrigger alwaysShown asChild>
                    <NewButton
                      type="outline"
                      icon={{
                        name: 'Pencil',
                      }}
                      text={`Annotate (${annotations.length})`}
                    />
                  </DetailsSidebarTrigger>
                </VStack>
              </VStack>
            </EluveCardContent>
          </CollapsibleContent>
        </Collapsible>
      </Card>
    </>
  );
};
