import { useReactTable } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import {
  ColDefBuilder,
  DataTableFilterField,
  ExtendedSortingState,
  PageTitle,
  ServerDataTable,
  useDataTableForQuery,
} from '@eluve/components';
import {
  FeedbackTypeEnum,
  SummaryFeedbackBoolExp,
  SummaryFeedbackOrderBy,
} from '@eluve/graphql-types';
import { ResultOf } from '@eluve/graphql.tada';

import {
  reviewerPromptTemplatesFragment,
  reviewerTenantsFragment,
  reviewerUsersFragment,
  searchFeedbackQuery,
} from './operations';

type TableRow = {
  tenantId: string;
  tenantName: string;
  appointmentId: string;
  appointmentName: string;
  userId: string;
  userEmail: string;
  createdAt: string;
  rating: number | null;
  comment: string | null;
  type: FeedbackTypeEnum;
  promptTemplateId: string;
  promptTemplateName: string;
};

const columns = new ColDefBuilder<TableRow>()
  .defaultSortable('tenantId', {
    label: 'Tenant',
    cellRenderer: (row) => row.tenantName,
  })
  .defaultSortable('userId', {
    label: 'User',
    cellRenderer: (row) => row.userEmail,
  })
  .colDef({
    header: 'Appointment',
    cell: ({ row: { original } }) => (
      <Link
        to={`../appointments/${original.tenantId}/${original.appointmentId}`}
        className="underline"
      >
        {original.appointmentName}
      </Link>
    ),
  })
  .defaultSortable('type')
  .defaultSortable('rating')
  .defaultSortable('promptTemplateId', {
    label: 'Template',
    cellRenderer: (row) => row.promptTemplateName,
  })
  .colDef({
    header: 'Comment',
    cell: (def) => def.row.original.comment,
  })
  .dateSortable('createdAt', 'Created At')
  .build();

const convertToRows = (
  results: ResultOf<typeof searchFeedbackQuery>,
): TableRow[] => {
  return (
    (results?.summaryFeedback ?? []).map<TableRow>((s) => {
      return {
        tenantId: s.tenant.id,
        tenantName: s.tenant.name,
        appointmentId: s.appointment.id,
        appointmentName: s.appointment.name,
        userId: s.user?.id ?? '',
        userEmail: s.user?.email ?? '',
        createdAt: s.createdAt,
        rating: s.rating,
        comment: s.comment,
        type: s.type!,
        promptTemplateId: s.llm_output.modelPromptTemplate.template.id,
        promptTemplateName: s.llm_output.modelPromptTemplate.template.name,
      };
    }) ?? []
  );
};

const defaultSort: ExtendedSortingState<TableRow> = [
  { id: 'createdAt', desc: true },
];

export const ReviewFeedbackPage: React.FC = () => {
  const { tenants } = useCompleteFragment({
    fragment: reviewerTenantsFragment,
    key: QUERY_ROOT_ID,
  });

  const { users } = useCompleteFragment({
    fragment: reviewerUsersFragment,
    key: QUERY_ROOT_ID,
  });

  const { promptTemplates } = useCompleteFragment({
    fragment: reviewerPromptTemplatesFragment,
    key: QUERY_ROOT_ID,
  });

  const filterFields: DataTableFilterField<TableRow>[] = useMemo(() => {
    const fields: DataTableFilterField<TableRow>[] = [
      {
        id: 'tenantId',
        label: 'Tenant',
        options: tenants.map((t) => ({
          label: t.name,
          value: t.id,
        })),
      },
      {
        id: 'userId',
        label: 'User',
        options: users.map((u) => ({
          label: u.email,
          value: u.id,
        })),
      },
      {
        id: 'type',
        label: 'Type',
        options: [
          { label: 'POSITIVE', value: 'POSITIVE' },
          { label: 'NEGATIVE', value: 'NEGATIVE' },
        ],
      },
      {
        id: 'promptTemplateId',
        label: 'Prompt Template',
        options: promptTemplates.map((p) => ({
          label: p.name,
          value: p.id,
        })),
      },
    ];

    return fields;
  }, [tenants, users, promptTemplates]);

  const { reactTableOptions, isPending, data, rows } = useDataTableForQuery({
    query: searchFeedbackQuery,
    filterFields,
    convertToRows,
    defaultSort,
    convertSearchParamsToVariables: ({ offset, limit, filters, sorting }) => {
      const filterConditions: SummaryFeedbackBoolExp[] = [];

      if (filters.tenantId && filters.tenantId.length) {
        filterConditions.push({
          tenantId: {
            _in: filters.tenantId as string[],
          },
        });
      }

      if (filters.userId && filters.userId.length) {
        filterConditions.push({
          userId: {
            _in: filters.userId as string[],
          },
        });
      }

      if (filters.type && filters.type.length) {
        filterConditions.push({
          type: {
            _in: filters.type as FeedbackTypeEnum[],
          },
        });
      }

      if (filters.promptTemplateId && filters.promptTemplateId.length) {
        filterConditions.push({
          llm_output: {
            modelPromptTemplate: {
              promptTemplateId: {
                _in: filters.promptTemplateId as string[],
              },
            },
          },
        });
      }

      return {
        offset,
        limit,
        filter: {
          _and: filterConditions,
        },
        orderBy:
          sorting && sorting.length
            ? sorting.map(({ desc, id }) => ({
                ...match<keyof TableRow, SummaryFeedbackOrderBy>(id)
                  .with('promptTemplateId', () => ({
                    llm_output: {
                      modelPromptTemplate: {
                        template: {
                          name: desc ? 'DESC' : 'ASC',
                        },
                      },
                    },
                  }))
                  .with('userId', () => ({
                    user: {
                      email: desc ? 'DESC' : 'ASC',
                    },
                  }))
                  .with('tenantId', () => ({
                    tenant: {
                      name: desc ? 'DESC' : 'ASC',
                    },
                  }))
                  .otherwise(() => ({
                    [id]: desc ? 'DESC' : 'ASC',
                  })),
              }))
            : null,
      };
    },
  });

  const rowCount = data?.summaryFeedbackAggregate?.aggregate?.count;

  const table = useReactTable({
    data: rows,
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <div>
      <PageTitle>Feedback</PageTitle>
      <ServerDataTable<TableRow>
        table={table}
        filterFields={filterFields}
        isPending={isPending}
      />
    </div>
  );
};
