import { z } from 'zod';

export const deepgramRawMetadataSchema = z.object({
  results: z.object({
    channels: z.array(
      z.object({
        alternatives: z.array(z.any()),
      }),
    ),
  }),
});
