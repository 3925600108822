import { AlertCircle } from 'lucide-react';
import { match } from 'ts-pattern';

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
} from '@eluve/components';
import { usePermission } from '@eluve/mic-utils';

export const MicrophoneRequestAlert = () => {
  const microphonePermissionStatus = usePermission({ name: 'microphone' });

  const requestMicrophoneAccess = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((device) => {
      device.getTracks().forEach((track) => {
        track.stop();
      });
    });
  };

  return match(microphonePermissionStatus)
    .with('granted', () => null)
    .with('denied', () => (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Microphone permission denied</AlertTitle>
        <AlertDescription>
          Please check your browser settings to enable microphone access.
        </AlertDescription>
      </Alert>
    ))
    .otherwise(() => (
      <Alert variant="warning">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Microphone Permission</AlertTitle>
        <AlertDescription>
          <Box vStack>
            Microphone permission is required to continue.
            <Button onClick={requestMicrophoneAccess} variant="gray" size="sm">
              Request Permission
            </Button>
          </Box>
        </AlertDescription>
      </Alert>
    ));
};
