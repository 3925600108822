import { useQuery } from '@apollo/client';
import { useReactTable } from '@tanstack/react-table';
import { PlusIcon } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  ColDefBuilder,
  DataTableFilterField,
  HStack,
  P,
  PageTitle,
  ServerDataTable,
  useDataTableForQuery,
} from '@eluve/components';
import {
  EvalSourceArtifactBoolExp,
  EvalSourceArtifactTypeLookup,
  EvalSourceArtifactTypesEnum,
} from '@eluve/graphql-types';
import { ResultOf, graphql } from '@eluve/graphql.tada';

export const searchArtifactsQuery = graphql(`
  query searchArtifacts(
    $filter: EvalSourceArtifactBoolExp
    $offset: Int
    $orderBy: [EvalSourceArtifactOrderBy!]
    $limit: Int
  ) {
    evalSourceArtifact(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      id
      name
      type
      description
      content
      createdAt
      sourceArtifactAppointment {
        tenantId
        appointmentId
      }
    }
    evalSourceArtifactAggregate(where: $filter) {
      __typename
      aggregate {
        __typename
        count
      }
    }
  }
`);

export const getAllHashedTenantAndAppointmentIdsQuery = graphql(`
  query getAllHashedTenantAndAppointmentIds {
    hashedTenantIds: evalAppointmentSourceArtifact(distinctOn: [tenantId]) {
      __typename
      tenantId
    }
    hashedAppointmentIds: evalAppointmentSourceArtifact(
      distinctOn: [appointmentId]
    ) {
      __typename
      appointmentId
    }
  }
`);

type ArtifactRow = {
  id: string;
  name: string | null;
  type: EvalSourceArtifactTypesEnum;
  description: string | null;
  content: string;
  createdAt: string;
  hashedTenantId: string | null | undefined;
  hashedAppointmentId: string | null | undefined;
};

const columns = new ColDefBuilder<ArtifactRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .defaultSortable('type')
  .colDef({
    id: 'hashedTenantId',
    accessorKey: 'hashedTenantId',
    header: 'Hashed Tenant ID',
  })
  .colDef({
    id: 'hashedAppointmentId',
    accessorKey: 'hashedAppointmentId',
    header: 'Hashed Appt ID',
  })
  .defaultSortable('description')
  .defaultSortable('content', {
    cellRenderer: (row) => (
      <P>
        {' '}
        {row.content.length > 200
          ? `${row.content.substring(0, 200)}...`
          : row.content}
      </P>
    ),
  })
  .dateSortable('createdAt', 'Created At')
  .build();

const convertSearchResultsToRows = (
  results: ResultOf<typeof searchArtifactsQuery>,
): ArtifactRow[] => {
  return results.evalSourceArtifact.map<ArtifactRow>((artifact) => {
    return {
      ...artifact,
      hashedTenantId: artifact.sourceArtifactAppointment?.tenantId,
      hashedAppointmentId: artifact.sourceArtifactAppointment?.appointmentId,
    };
  });
};

export const ArtifactListPage: React.FC = () => {
  const { data: appointmentData } = useQuery(
    getAllHashedTenantAndAppointmentIdsQuery,
  );

  const hashedTenantIds = appointmentData?.hashedTenantIds ?? [];
  const hashedAppointmentIds = appointmentData?.hashedAppointmentIds ?? [];

  const filterFields: DataTableFilterField<ArtifactRow>[] = [
    {
      id: 'type',
      label: 'Artifact Type',
      options: Object.keys(EvalSourceArtifactTypeLookup)
        .sort()
        .map((type) => ({
          label: type,
          value: type,
        })),
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'description',
      label: 'Description',
    },
    ...(hashedTenantIds.length
      ? [
          {
            id: 'hashedTenantId' as keyof ArtifactRow,
            label: 'Hashed Tenant ID',
            options: hashedTenantIds.map(({ tenantId }) => ({
              label: tenantId,
              value: tenantId,
            })),
          },
        ]
      : []),

    ...(hashedAppointmentIds.length
      ? [
          {
            id: 'hashedAppointmentId' as keyof ArtifactRow,
            label: 'Hashed Appointment ID',
            options: hashedAppointmentIds.map((info) => ({
              label: info.appointmentId,
              value: info.appointmentId,
            })),
          },
        ]
      : []),
  ];

  const { data, rows, reactTableOptions, isPending } = useDataTableForQuery({
    query: searchArtifactsQuery,
    filterFields,
    convertToRows: convertSearchResultsToRows,
    convertSearchParamsToVariables: ({ offset, limit, sorting, filters }) => {
      const filterConditions: EvalSourceArtifactBoolExp[] = [];

      if (filters.type) {
        filterConditions.push({
          type: {
            _in: filters.type as (keyof typeof EvalSourceArtifactTypeLookup)[],
          },
        });
      }

      if (filters.name) {
        filterConditions.push({
          name: {
            _ilike: `%${filters.name}%`,
          },
        });
      }

      if (filters.description) {
        filterConditions.push({
          description: {
            _ilike: `%${filters.description}%`,
          },
        });
      }

      if (filters.hashedTenantId) {
        filterConditions.push({
          sourceArtifactAppointment: {
            tenantId: {
              _in: Array.isArray(filters.hashedTenantId)
                ? filters.hashedTenantId
                : [filters.hashedTenantId],
            },
          },
        });
      }

      if (filters.hashedAppointmentId) {
        filterConditions.push({
          sourceArtifactAppointment: {
            appointmentId: {
              _in: Array.isArray(filters.hashedAppointmentId)
                ? filters.hashedAppointmentId
                : [filters.hashedAppointmentId],
            },
          },
        });
      }

      return {
        offset,
        limit,
        filter: {
          _and: filterConditions,
        },
        orderBy:
          sorting && sorting.length
            ? sorting.map(({ desc, id }) => ({
                [id]: desc ? 'DESC' : 'ASC',
              }))
            : null,
      };
    },
  });

  const rowCount = data?.evalSourceArtifactAggregate?.aggregate?.count;

  const table = useReactTable({
    columns,
    data: rows,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <div className="">
      <HStack justify="between">
        <div className="flex-1">
          <PageTitle>Source Artifacts</PageTitle>
        </div>
        <Link to="create">
          <Button>
            <PlusIcon className="mr-2 h-5" />
            Add an artifact
          </Button>
        </Link>
      </HStack>
      <ServerDataTable<ArtifactRow>
        table={table}
        filterFields={filterFields}
        isPending={isPending}
      />
    </div>
  );
};
