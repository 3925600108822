import { z } from 'zod';

import { differentialDiagnosesSchema } from './differential-diagnoses';

export const redFlagSchema = z.object({
  callout: z.string(),
  context: z.string(),
});

export const redFlagsSchema = z.array(redFlagSchema);

export const redFlagsOutputSchema = z.object({
  differential_diagnoses: differentialDiagnosesSchema,
  red_flags: redFlagsSchema,
});

export type RedFlagsOutputSchema = z.infer<typeof redFlagsOutputSchema>;
