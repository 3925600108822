import { useSelector } from '@xstate/store/react';
import React from 'react';

import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Divider,
  H6,
  HStack,
  Input,
  NewButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@eluve/components';

import { useSegmentAudioContext } from './SegmentAudioContextStore';
import { useSegmentSpeakerControls } from './useSegmentSpeakerControls';

export interface SegmentSpeakerControlsProps {
  tenantId: string;
  segmentId: string;
  initialSpeakerNames?: string[];
}

export const SegmentSpeakerControls: React.FC<SegmentSpeakerControlsProps> = ({
  tenantId,
  segmentId,
  initialSpeakerNames,
}) => {
  const { store } = useSegmentAudioContext();
  const selectedUtteranceIds = useSelector(
    store,
    ({ context: { selectedUtteranceIds } }) => selectedUtteranceIds,
  );

  const {
    onUpdateSpeakerForUtterances,
    onRenameSpeaker,
    isSelectSpeakerOpen,
    toggleIsSelectSpeakerOpen,
    newSpeakerName,
    setNewSpeakerName,
    speakerNames,
    setSpeakerNames,
    renameSpeakerTo,
    setRenameSpeakerTo,
    isNewSpeakerOpen,
    toggleIsNewSpeakerOpen,
  } = useSegmentSpeakerControls({ tenantId, segmentId, initialSpeakerNames });

  return (
    <VStack className="rounded-lg border p-2">
      <H6>Speakers</H6>
      <HStack gap={1} wrap>
        {[...speakerNames].map((speaker) => (
          <Popover key={speaker}>
            <PopoverTrigger asChild>
              <NewButton
                key={speaker}
                type="outline"
                text={speaker}
                size="s"
                icon={{
                  name: 'Pencil',
                  position: 'right',
                }}
              />
            </PopoverTrigger>
            <PopoverContent>
              <HStack>
                <Input
                  placeholder="New Speaker Name"
                  onChange={(e) => setRenameSpeakerTo(e.target.value)}
                  value={renameSpeakerTo}
                />
                <NewButton
                  text="Rename"
                  size="s"
                  onClick={() => onRenameSpeaker(speaker)}
                  disabled={
                    renameSpeakerTo === '' || renameSpeakerTo === speaker
                  }
                />
              </HStack>
            </PopoverContent>
          </Popover>
        ))}
        <Popover open={isNewSpeakerOpen} onOpenChange={toggleIsNewSpeakerOpen}>
          <PopoverTrigger asChild>
            <NewButton
              icon={{ name: 'Plus' }}
              size="s"
              text="New"
              type="outline"
            />
          </PopoverTrigger>
          <PopoverContent side="right" align="start">
            <HStack>
              <Input
                placeholder="Speaker Name"
                onChange={(e) => setNewSpeakerName(e.target.value)}
                value={newSpeakerName}
              />
              <NewButton
                text="Add"
                size="s"
                onClick={() => {
                  setSpeakerNames((prev) => new Set([...prev, newSpeakerName]));
                  setNewSpeakerName('');
                }}
                disabled={Boolean(
                  !newSpeakerName || speakerNames.has(newSpeakerName),
                )}
              />
            </HStack>
          </PopoverContent>
        </Popover>
      </HStack>
      <Divider className="my-1" />
      <Popover
        open={isSelectSpeakerOpen}
        onOpenChange={toggleIsSelectSpeakerOpen}
      >
        <PopoverTrigger asChild>
          <NewButton
            type="primary"
            size="s"
            disabled={selectedUtteranceIds.length === 0}
            text={`Change speaker for ${selectedUtteranceIds.length}`}
          />
        </PopoverTrigger>
        <PopoverContent>
          <Command>
            <CommandInput placeholder="Select speaker" />
            <CommandList>
              <CommandGroup>
                {[...new Set([...speakerNames, 'Doctor', 'Nurse'])].map(
                  (speaker) => (
                    <CommandItem
                      key={speaker}
                      onSelect={() => onUpdateSpeakerForUtterances(speaker)}
                    >
                      {speaker}
                    </CommandItem>
                  ),
                )}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </VStack>
  );
};
