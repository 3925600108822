import { createStoreWithProducer } from '@xstate/store';
import { produce } from 'immer';
import React from 'react';

import { AudioPlayerHandle } from '@eluve/audio-player';

type DefaultContext = {
  currentTimestamp: number;
  selectedUtteranceIds: string[];
  tenantId: string;
  segmentId: string;
  isReadonly: boolean;
};

const defaultContext: DefaultContext = {
  currentTimestamp: 0,
  selectedUtteranceIds: [],
  tenantId: '',
  segmentId: '',
  isReadonly: false,
};

export const createSegmentAudioContextStore = (args: {
  tenantId: string;
  segmentId: string;
  isReadonly: boolean;
}) => {
  return createStoreWithProducer(
    produce,
    { ...defaultContext, ...args },
    {
      updateTimestamp: (ctx, evt: { timestamp: number }) => {
        ctx.currentTimestamp = evt.timestamp;
      },
      selectUtteranceId: (ctx, evt: { utteranceId: string }) => {
        ctx.selectedUtteranceIds.push(evt.utteranceId);
      },
      deselectUtteranceId: (ctx, evt: { utteranceId: string }) => {
        const idx = ctx.selectedUtteranceIds.indexOf(evt.utteranceId);
        if (idx !== -1) {
          ctx.selectedUtteranceIds.splice(idx, 1);
        }
      },
      clearSelectedUtteranceIds: (ctx) => {
        ctx.selectedUtteranceIds = [];
      },
    },
  );
};

type SegmentStore = ReturnType<typeof createSegmentAudioContextStore>;

export type SegementAudioContextValue = {
  store: SegmentStore;
  audioPlayerHandle: React.RefObject<AudioPlayerHandle>;
};

export const SegmentAudioContext =
  React.createContext<SegementAudioContextValue | null>(null);

export const useSegmentAudioContext = () => {
  const context = React.useContext(SegmentAudioContext);
  if (context === null) {
    throw new Error(
      'useSegmentAudioContext must be used within a SegmentAudioContextProvider',
    );
  }
  return context;
};
