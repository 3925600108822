import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from '@xstate/store/react';
import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useRef } from 'react';
import ReactJson from 'react-json-view';
import { match } from 'ts-pattern';

import { useApiClient } from '@eluve/api-client-provider';
import { cacheUtils } from '@eluve/apollo-client';
import { AudioPlayerHandle } from '@eluve/audio-player';
import {
  TooltipLabel,
  TranscriptText,
  TranscriptTimestampDivider,
} from '@eluve/blocks';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTrigger,
  FCC,
  HStack,
  Icon,
  NewButton,
  P,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';
import { FragmentOf } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';
import { EluveAdminOnly } from '@eluve/smart-blocks';
import { toSentences } from '@eluve/utils';

import {
  SegementAudioContextValue,
  SegmentAudioContext,
  createSegmentAudioContextStore,
  useSegmentAudioContext,
} from './SegmentAudioContextStore';
import { SegmentAudioControls } from './SegmentAudioControls';
import { SegmentSpeakerControls } from './SegmentSpeakerControls';
import { MemoizedTranscriptWord } from './TranscriptWord';
import {
  getUtteranceAndFragmentsQuery,
  segmentFragment,
  segmentUtterancesFragment,
  updateUtteranceTextMutation,
} from './operations';
import { useSegmentAudioData } from './useTranscriptAudioAnalysisData';

type SegmentAudioContextProviderProps = {
  index: number;
  tenantId: string;
  appointmentId: string;
  segmentId: string;
  seg: FragmentOf<typeof segmentFragment>;
  isReadonly?: boolean;
};

export const SegmentAudioContextProvider: FCC<
  SegmentAudioContextProviderProps
> = (props) => {
  const { tenantId, segmentId, isReadonly = false } = props;
  const audioPlayerRef = useRef<AudioPlayerHandle>(null);
  const store = useMemo(
    () => createSegmentAudioContextStore({ tenantId, segmentId, isReadonly }),
    [tenantId, segmentId, isReadonly],
  );

  const value: SegementAudioContextValue = useMemo(
    () => ({
      store,
      audioPlayerHandle: audioPlayerRef,
    }),
    [store],
  );

  return (
    <SegmentAudioContext.Provider value={value}>
      <SegmentAudio {...props} />
    </SegmentAudioContext.Provider>
  );
};

const SegmentAudio: FCC<SegmentAudioContextProviderProps> = ({
  appointmentId,
  seg,
  tenantId,
  index,
  isReadonly = false,
}) => {
  const userId = useUserIdFromSession();
  const showConfidenceStyles = true;

  const { store, audioPlayerHandle } = useSegmentAudioContext();

  const selectedUtteranceIds = useSelector(
    store,
    (state) => state.context.selectedUtteranceIds,
  );

  const client = useApiClient();

  const [updateUtterance] = useMutation(updateUtteranceTextMutation);

  const [getUtteranceAndFragments] = useLazyQuery(
    getUtteranceAndFragmentsQuery,
  );

  const onUtteranceChanged = (id: string, newUtteranceText: string) => {
    if (isReadonly) return;
    updateUtterance({
      variables: {
        tenantId,
        id,
        text: newUtteranceText,
      },
      optimisticResponse: () => ({
        updateUtterancesByPk: {
          __typename: 'Utterances',
          id,
          text: newUtteranceText,
          updatedById: userId,
          hasHumanUpdatedText: true,
        },
      }),
    });
  };

  const splitUtterance = async ({
    utteranceId,
    fragmentId,
  }: {
    utteranceId: string;
    fragmentId: string;
  }) => {
    const response = await client.reviewer.splitUtterance({
      params: {
        tenantId,
        appointmentId,
        utteranceId,
      },
      body: {
        fragmentId,
      },
    });

    if (response.status === 201) {
      const { newUtteranceId } = response.body;

      const result = await getUtteranceAndFragments({
        variables: {
          tenantId,
          ids: [newUtteranceId, utteranceId],
        },
      });

      // we need to add the new utterance to the list of utterances in Apollo
      cacheUtils.updateFragment(
        {
          fragment: segmentUtterancesFragment,
          key: { id: seg.id },
        },
        (existing) => {
          // find the index of the split from utterance
          // and insert the new one immediately after
          if (!existing) {
            return existing;
          }

          return produce(existing, (draft) => {
            const idx = draft.utterances.findIndex((u) => u.id === utteranceId);

            if (idx === -1) {
              return;
            }

            const newUtterance = result.data?.utterances.find(
              (u) => u.id === newUtteranceId,
            );

            if (!newUtterance) {
              return;
            }

            draft.utterances.splice(idx + 1, 0, newUtterance);
          });
        },
      );
    } else {
      toast.error('Failed to split');
    }
  };

  const timestamp = useSelector(
    store,
    (state) => state.context.currentTimestamp,
  );

  const { isLoading, result } = useSegmentAudioData({
    appointmentId,
    seg,
    tenantId,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (result.type === 'no_audio') {
    return <div>No audio available</div>;
  }

  const ctx = result.context;

  return (
    <>
      <VStack className="sticky top-0 z-10 bg-white">
        <TranscriptTimestampDivider
          timestamp={ctx.startedAt}
          action={index === 0 ? 'started' : 'resumed'}
        />
        {ctx.audioUrl && <SegmentAudioControls audioUrl={ctx.audioUrl} />}
        <EluveAdminOnly>
          {!isEmpty(ctx.rawMetadata) && (
            <Dialog>
              <DialogTrigger asChild>
                <NewButton type="outlineFilled" text="Raw Metadata" />
              </DialogTrigger>
              <DialogContent className="max-w-[90vw]">
                <VStack className="max-h-[600px] w-full overflow-scroll">
                  <ReactJson enableClipboard={false} src={ctx.rawMetadata} />
                </VStack>
              </DialogContent>
            </Dialog>
          )}
        </EluveAdminOnly>
      </VStack>

      {match(seg.utterances.length)
        .with(0, () => (
          <TranscriptText>{toSentences(seg.transcript ?? '')}</TranscriptText>
        ))
        .otherwise(() => {
          const initialSpeakerNames = new Set(
            seg.utterances.map((u) => u.speaker).filter(Boolean) as string[],
          );

          return (
            <>
              <SegmentSpeakerControls
                tenantId={tenantId}
                segmentId={seg.id}
                initialSpeakerNames={[...initialSpeakerNames]}
              />
              <VStack className="grid max-h-[600px] grid-cols-[min-content_min-content_min-content_1fr] gap-x-5 overflow-scroll">
                {seg.utterances.map((u, uIdx) => {
                  const showSpeaker =
                    u.speaker &&
                    (uIdx === 0 ||
                      u.speaker !== seg.utterances[uIdx - 1]?.speaker);
                  return (
                    <React.Fragment key={u.id}>
                      <div className="col-start-1">
                        <Checkbox
                          checked={selectedUtteranceIds.includes(u.id)}
                          onCheckedChange={(c) => {
                            if (c) {
                              store.send({
                                type: 'selectUtteranceId',
                                utteranceId: u.id,
                              });
                            } else {
                              store.send({
                                type: 'deselectUtteranceId',
                                utteranceId: u.id,
                              });
                            }
                          }}
                        />
                      </div>
                      {showSpeaker && (
                        <P
                          className={textStyles.body({
                            size: 'l',
                            className:
                              'col-start-2 whitespace-nowrap underline',
                          })}
                        >
                          {u.speaker}
                        </P>
                      )}

                      <P
                        className={textStyles.body({
                          className: `col-start-3 cursor-pointer hover:underline ${
                            timestamp >= (u.startAt ?? -1) &&
                            timestamp <= (u.endAt ?? -1)
                              ? 'bg-orange text-orangeContrast'
                              : ''
                          }`,
                          color: 'tertiary',
                        })}
                        onClick={() => {
                          if (u.startAt) {
                            audioPlayerHandle?.current?.seekTo(u.startAt);
                          }
                        }}
                      >
                        {u.startAt?.toFixed(2)}
                      </P>

                      <HStack
                        gap={1}
                        wrap
                        className="col-start-4 min-h-4"
                        contentEditable={!isReadonly}
                        suppressContentEditableWarning
                        onBlur={(e) => {
                          const newUtterance = e.target.innerText
                            .split('\n')
                            .join(' ');

                          if (newUtterance !== u.text) {
                            onUtteranceChanged(u.id, newUtterance);
                          }
                        }}
                      >
                        {match(u.hasHumanUpdatedText)
                          .with(false, () => (
                            <HStack gap={1} wrap>
                              {u.fragments.map((f, fIdx) => (
                                <HStack
                                  gap={0}
                                  key={f.id}
                                  className="group inline-flex"
                                  wFull={false}
                                >
                                  <MemoizedTranscriptWord
                                    showConfidenceStyles={showConfidenceStyles}
                                    w={{
                                      confidence: f.confidence,
                                      end: f.endAt,
                                      start: f.startAt,
                                      word: f.text,
                                    }}
                                    className={
                                      timestamp >= (f.startAt ?? -1) &&
                                      timestamp <= (f.endAt ?? -1)
                                        ? 'bg-orange text-orangeContrast'
                                        : ''
                                    }
                                  />

                                  {Boolean(
                                    !isReadonly && u.fragments[fIdx + 1],
                                  ) && (
                                    <div className="invisible w-0.5 -translate-x-4 cursor-pointer opacity-0 transition-all duration-300 group-hover:visible group-hover:block group-hover:size-6 group-hover:translate-x-0 group-hover:opacity-100">
                                      <TooltipLabel label="Split to a new speaker">
                                        <div
                                          onClick={() =>
                                            splitUtterance({
                                              utteranceId: u.id,
                                              fragmentId: f.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            size="md"
                                            name="SeparatorVertical"
                                          />
                                        </div>
                                      </TooltipLabel>
                                    </div>
                                  )}
                                </HStack>
                              ))}
                            </HStack>
                          ))
                          .otherwise(() => (
                            <P
                              className={textStyles.body({
                                className: 'text-positive950',
                              })}
                            >
                              {u.text}
                            </P>
                          ))}
                      </HStack>
                    </React.Fragment>
                  );
                })}

                {ctx.pausedAt && (
                  <TranscriptTimestampDivider
                    className="col-span-4"
                    timestamp={ctx.pausedAt}
                    action="paused"
                  />
                )}
              </VStack>
            </>
          );
        })}
    </>
  );
};
