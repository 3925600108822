import { z } from 'zod';

export const differentialDiagnosisSchema = z.object({
  diagnosis: z.string(),
  description: z.string().optional(),
  positiveIndicators: z.array(z.string()),
  negativeIndicators: z.array(z.string()),
  unknownIndicators: z.array(z.string()).optional(),
});

export const differentialDiagnosesSchema = z.array(differentialDiagnosisSchema);

export type DifferentialDiagnosisOutputSchema = z.infer<
  typeof differentialDiagnosisSchema
>;

export type DifferentialDiagnosesOutputSchema = z.infer<
  typeof differentialDiagnosesSchema
>;
