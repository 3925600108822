import differenceInYears from 'date-fns/differenceInYears';
import format from 'date-fns/format';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { HStack, VStack, textStyles } from '@eluve/components';
import { basicPatientDetailsFragment } from '@eluve/frontend-appointment-hooks';
import { TabNav } from '@eluve/smart-blocks';
import { formatHumanName } from '@eluve/utils';

export interface PatientDetailsPageProps {}

export const PatientDetailsPage: React.FC<PatientDetailsPageProps> = () => {
  const { patientId } = useParams() as {
    patientId: string;
  };

  const basicDetails = useCompleteFragment({
    fragment: basicPatientDetailsFragment,
    key: {
      id: patientId,
    },
  });

  const name = formatHumanName(basicDetails?.firstName, basicDetails?.lastName);
  const initials = `${basicDetails?.firstName?.charAt(0) ?? ''}${basicDetails?.lastName?.charAt(0) ?? ''}`;
  const dob = basicDetails?.dateOfBirth
    ? format(new Date(basicDetails.dateOfBirth), 'MMMM d, yyy') // July 4, 1776
    : null;
  const age = basicDetails?.dateOfBirth
    ? differenceInYears(new Date(), new Date(basicDetails.dateOfBirth))
    : null;

  return (
    <VStack gap={8} className="py-3 md:p-8" wFull>
      {/* Patient Details */}
      <HStack gap={6}>
        <div
          className={`flex aspect-square size-16 items-center justify-center rounded-full bg-brandGray900 leading-none text-white privacy-text md:size-24 ${textStyles.heading({ size: 'm' })}`}
        >
          {initials}
        </div>

        <VStack>
          <p className={`privacy-text ${textStyles.title({ size: 'l' })}`}>
            {name}
          </p>

          <VStack gap={0}>
            {dob && (
              <p className={`privacy-text ${textStyles.body({ size: 'm' })}`}>
                Date of Birth: <span className="font-bold">{dob}</span>
              </p>
            )}
            {age && (
              <p className={`privacy-text ${textStyles.body({ size: 'm' })}`}>
                Age: <span className="font-bold">{age} years old</span>
              </p>
            )}
          </VStack>
        </VStack>
      </HStack>

      <VStack wFull>
        {/* Tab View */}
        <TabNav
          tabs={[
            {
              name: 'Appointments',
              link: 'appointments',
            },
            {
              name: 'Imported Notes',
              link: 'imported-notes',
            },
          ]}
        />
        <Outlet />
      </VStack>
    </VStack>
  );
};
