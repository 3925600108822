import React, { useState } from 'react';
import Markdown from 'react-markdown';

import { useCompleteFragment } from '@eluve/apollo-client';
import { HStack, Icon, P, VStack, textStyles } from '@eluve/components';
import { patientOverviewFragment } from '@eluve/frontend-appointment-hooks';
import { patientOverviewSchema } from '@eluve/llm-outputs';
import { nestedObjToMarkdown } from '@eluve/utils';

const COLLAPSED_DETAILS_LENGTH = 300;

interface PatientOverviewProps {
  patientOverviewId: string;
}

export const PatientOverview: React.FC<PatientOverviewProps> = ({
  patientOverviewId,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const patientOverview = useCompleteFragment({
    fragment: patientOverviewFragment,
    key: { id: patientOverviewId },
    strict: false,
  });
  const parsedPatientOverview = patientOverviewSchema.safeParse(
    patientOverview?.content ?? '',
  );

  if (!parsedPatientOverview.success) {
    return null;
  }
  const { details: rawDetails, reasonForVisit: rawReasonForVisit } =
    parsedPatientOverview.data;

  const details = nestedObjToMarkdown({ details: rawDetails }).details;
  const reasonForVisit = nestedObjToMarkdown({
    reasonForVisit: rawReasonForVisit,
  }).reasonForVisit;

  if (!details || !details.length) {
    return null;
  }

  const canOpenDetails = details.length > COLLAPSED_DETAILS_LENGTH;

  let formattedDetails = details;
  if (canOpenDetails) {
    formattedDetails = isDetailsOpen
      ? `${details} **View Less**`
      : `${details?.substring(0, COLLAPSED_DETAILS_LENGTH).trim()}... **View More**`;
  }

  return (
    <VStack wFull align="stretch" gap={6}>
      {rawReasonForVisit.length > 0 && (
        <HStack
          align="start"
          className="rounded-lg bg-brandGray100 p-3 pr-6"
          gap={3}
        >
          <div className="rounded-md bg-purple p-2.5 text-purpleContrast">
            <Icon name="Lightbulb" size="sm" />
          </div>
          <VStack className="text-secondary" gap={0}>
            <P
              className={textStyles.body({
                color: 'secondary',
                weight: 'bold',
                size: 'l',
              })}
            >
              Reason for visit
            </P>
            <Markdown
              className={textStyles.body({
                color: 'tertiary',
                weight: 'medium',
                size: 's',
              })}
            >
              {reasonForVisit}
            </Markdown>
          </VStack>
        </HStack>
      )}

      <div
        className={canOpenDetails ? 'cursor-pointer' : ''}
        onClick={
          canOpenDetails ? () => setIsDetailsOpen(!isDetailsOpen) : undefined
        }
      >
        <Markdown
          className={textStyles.body({
            className:
              'prose mx-1 [&>ul>li:not(:last-child)]:mb-3 [&>ul]:list-disc',
            color: 'secondary',
            weight: 'medium',
            size: 'm',
          })}
          components={{
            strong({ children }) {
              return (
                <strong className="inline-block font-semibold">
                  {children}
                </strong>
              );
            },
          }}
        >
          {formattedDetails}
        </Markdown>
      </div>
    </VStack>
  );
};

PatientOverview.displayName = 'PatientOverview';
