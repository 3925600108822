import { graphql } from '@eluve/graphql.tada';

// Fragments

export const llmModelPromptTemplatesDetailsFragment = graphql(`
  fragment LlmModelPromptTemplatesDetails on LlmModelPromptTemplates @_unmask {
    __typename
    id
    isDefault
    isActive
    modelArgsId
    promptTemplateId
    feedback_aggregates {
      __typename
      id
      averageRating
      negativeFeedbackCount
      positiveFeedbackCount
      totalFeedbackCount
    }
    model_args {
      __typename
      id
      modelType
      args
    }
  }
`);

export const promptTemplateModelsFragment = graphql(
  `
    fragment PromptTemplateModels on PromptTemplates @_unmask {
      __typename
      id
      prompt_models {
        ...LlmModelPromptTemplatesDetails
      }
    }
  `,
  [llmModelPromptTemplatesDetailsFragment],
);

export const promptTemplateSettingsFragment = graphql(`
  fragment PromptTemplateSettings on PromptTemplates @_unmask {
    __typename
    outputType
    isActive
    isCurrentDefault
    userSettingsAggregate {
      __typename
      aggregate {
        count
      }
    }
  }
`);

export const promptTemplateVariantFragment = graphql(`
  fragment PromptTemplateVariant on PromptTemplateVariants @_unmask {
    __typename
    promptTemplateId
    variantNumber
    template
    isCurrentDefault
    variantNotes
    createdAt
    creator {
      __typename
      id
      firstName
      lastName
    }
  }
`);

export const promptTemplateVariantsFragment = graphql(
  `
    fragment PromptTemplateVariants on PromptTemplates {
      __typename
      id
      prompt_template_variants(orderBy: { variantNumber: DESC }) {
        ...PromptTemplateVariant
      }
    }
  `,
  [promptTemplateVariantFragment],
);

// Operations

export const getTenantOutputTemplatesQuery = graphql(`
  query getTenantOutputTemplates {
    externalEhr {
      __typename
      id
      vendor
      domain
    }
    llmOutputTemplates {
      __typename
      id
      name
      description
      tenantId
      createdAt
      isActive
      variants(orderBy: { variantNumber: DESC }) {
        __typename
        outputTemplateId
        variantNumber
        isCurrentDefault
      }

      external_chart_templates {
        __typename
        id
        externalEhrId
        externalChartTemplateId
        externalChartTemplateOwnerId
      }
    }
  }
`);

export const getExistingPromptTemplateQuery = graphql(
  `
    query getExistingPromptTemplate($id: uuid!) {
      promptTemplatesByPk(id: $id) {
        __typename
        id
        name
        description
        outputType
        prompt_template_variants(orderBy: { variantNumber: DESC }) {
          ...PromptTemplateVariant
        }
      }
    }
  `,
  [promptTemplateVariantFragment],
);

export const setDefaultPromptTemplateVariantMutation = graphql(`
  mutation setDefaultPromptTemplateVariant(
    $promptTemplateId: uuid!
    $variantNumber: numeric!
  ) {
    updatePromptTemplateVariants(
      where: {
        _and: {
          isCurrentDefault: { _eq: true }
          promptTemplateId: { _eq: $promptTemplateId }
        }
      }
      _set: { isCurrentDefault: false }
    ) {
      returning {
        __typename
        promptTemplateId
        variantNumber
        isCurrentDefault
      }
    }
    updatePromptTemplateVariantsByPk(
      pkColumns: {
        promptTemplateId: $promptTemplateId
        variantNumber: $variantNumber
      }
      _set: { isCurrentDefault: true }
    ) {
      __typename
      promptTemplateId
      variantNumber
      isCurrentDefault
    }
  }
`);

export const updateTenantOutputTemplateMutation = graphql(`
  mutation updateTenantOutputTemplate($isActive: Boolean!, $templateId: uuid!) {
    updateLlmOutputTemplatesByPk(
      pkColumns: { id: $templateId }
      _set: { isActive: $isActive }
    ) {
      __typename
      id
      isActive
    }
  }
`);

export const createPromptTemplateVariantMutation = graphql(
  `
    mutation createPromptTemplateVariant(
      $promptTemplateId: uuid!
      $template: jsonb!
      $variantNotes: String
    ) {
      insertPromptTemplateVariantsOne(
        object: {
          promptTemplateId: $promptTemplateId
          template: $template
          variantNotes: $variantNotes
        }
      ) {
        ...PromptTemplateVariant
      }
    }
  `,
  [promptTemplateVariantFragment],
);

export const searchPromptTemplatesQuery = graphql(
  `
    query getPromptTemplates(
      $filter: PromptTemplatesBoolExp
      $offset: Int
      $orderBy: [PromptTemplatesOrderBy!]
      $limit: Int
    ) {
      promptTemplates(
        where: $filter
        offset: $offset
        limit: $limit
        orderBy: $orderBy
      ) {
        __typename
        id
        name
        outputType
        createdAt
        prompt_template_variants {
          ...PromptTemplateVariant
        }
        promptTemplateVariantsAggregate {
          __typename
          aggregate {
            __typename
            count
          }
        }
        prompt_models {
          __typename
          id
          llm_outputs {
            __typename
            id
            feedback {
              __typename
              id
              rating
            }
          }
        }
        ...PromptTemplateSettings
      }
      promptTemplatesAggregate(where: $filter) {
        __typename
        aggregate {
          __typename
          count
        }
      }
    }
  `,
  [promptTemplateSettingsFragment, promptTemplateVariantFragment],
);
