import { Slot } from '@radix-ui/react-slot';
import React, { useId } from 'react';

import { Box } from './box';
import { textStyles } from './textStyles';

interface InputLabelProps
  extends Omit<React.HTMLProps<HTMLLabelElement>, 'label'> {
  label: React.ReactNode;
}

export const InputLabel = React.forwardRef<HTMLLabelElement, InputLabelProps>(
  ({ children, className, label, ...props }, ref) => {
    const id = useId();

    return (
      <Box vStack fullWidth>
        <label
          {...props}
          ref={ref}
          className={textStyles.label({
            className:
              'flex flex-col gap-2 uppercase text-contentSupporting ' +
              className,
          })}
          htmlFor={id}
        >
          {label}
        </label>
        <Slot id={id}>{children}</Slot>
      </Box>
    );
  },
);
