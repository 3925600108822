import { ExternalToast, Toaster as Sonner, toast as sonnerToast } from 'sonner';

import { Icon } from './Icon';
import { tv } from './cn';
import { textStyles } from './textStyles';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const styles = tv({
  slots: {
    toast:
      'group toast group-[.toaster]:border-border group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:shadow-lg',
    icon: 'm-0 size-5',
    title: textStyles.body({
      size: 'm',
      weight: 'semibold',
      className: 'group-[.toaster]:text-inherit',
    }),
    description: textStyles.body({
      size: 'm',
      weight: 'regular',
      className: 'group-[.toaster]:text-inherit',
    }),
    actionButton:
      'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
    cancelButton:
      'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
    closeButton:
      '-right-4 left-auto top-2 -m-2 h-8 w-8 rounded-full border-8 border-transparent bg-clip-padding p-px group-[.toast-error]:bg-negative950 group-[.toast-info]:bg-brandGray950 group-[.toast-success]:bg-positive950 group-[.toast-warning]:bg-warning950 group-[.toast-error]:text-negative50 group-[.toast-info]:text-brandGray50 group-[.toast-success]:text-positive50 group-[.toast-warning]:text-warning50 group-[.toast]:hover:border-transparent group-[.toast]:hover:bg-clip-padding',
    base: 'group items-start gap-3 rounded-[8px] px-4 py-3 toast group-[.toaster]:border-none group-[.toaster]:shadow-md',
  },
  variants: {
    status: {
      error: {
        base: 'group toast-error group-[.toaster]:bg-negative50 group-[.toaster]:text-negative950',
      },
      info: {
        base: 'group toast-info group-[.toaster]:bg-brandGray100 group-[.toaster]:text-brandGray800',
      },
      success: {
        base: 'group toast-success group-[.toaster]:bg-positive50 group-[.toaster]:text-positive950',
      },
      warning: {
        base: 'group toast-warning group-[.toaster]:bg-warning50 group-[.toaster]:text-warning950',
      },
    },
  },
});

const {
  actionButton,
  base,
  cancelButton,
  closeButton,
  description,
  icon,
  title,
  toast,
} = styles();

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="group toaster"
      theme="system"
      visibleToasts={5}
      position="bottom-right"
      icons={{
        error: <Icon name="AlertCircle" size="sm" />,
        info: <Icon name="Info" size="sm" />,
        success: <Icon name="CheckCircle2" size="sm" />,
        warning: <Icon name="AlertTriangle" size="sm" />,
      }}
      toastOptions={{
        classNames: {
          toast: toast(),
          icon: icon(),
          title: title(),
          description: description(),
          actionButton: actionButton(),
          cancelButton: cancelButton(),
          closeButton: closeButton(),
          error: base({ status: 'error' }),
          info: base({ status: 'info' }),
          success: base({ status: 'success' }),
          warning: base({ status: 'warning' }),
        },
      }}
      {...props}
    />
  );
};

const bannerToastBaseData = {
  closeButton: true,
  duration: Infinity,
  position: 'top-center' as const,
};

type BannerToastData = Omit<
  ExternalToast,
  'closeButton' | 'position' | 'duration'
>;

const bannerToast = {
  error: (message: string | React.ReactNode, data?: BannerToastData) =>
    sonnerToast.error(message, { ...bannerToastBaseData, ...(data ?? {}) }),
  info: (message: string | React.ReactNode, data?: BannerToastData) =>
    sonnerToast.info(message, { ...bannerToastBaseData, ...(data ?? {}) }),
  success: (message: string | React.ReactNode, data?: BannerToastData) =>
    sonnerToast.success(message, { ...bannerToastBaseData, ...(data ?? {}) }),
  warning: (message: string | React.ReactNode, data?: BannerToastData) =>
    sonnerToast.warning(message, { ...bannerToastBaseData, ...(data ?? {}) }),
};

export { bannerToast, sonnerToast as toast, Toaster };
