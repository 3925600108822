import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Divider,
  HStack,
  InputLabel,
  Label,
  NewButton,
  P,
  ReskinContent,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  VStack,
  textStyles,
} from '@eluve/components';
import { PatientOverview } from '@eluve/feature-patients';
import { useAppointmentPatient } from '@eluve/frontend-appointment-hooks';
import { AppointmentLanguageSelector } from '@eluve/frontend-feature-appointment';
import { medicalNotesTemplatesFragment } from '@eluve/frontend-feature-user-settings';
import { LlmOutputTypesLookup } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import {
  AppointmentName,
  AppointmentUserMicSettings,
  FeatureFlaggedComponent,
  NewAppointmentTemplateSelector,
  TenantOrUserFeatureFlaggedComponent,
  useIsFeatureFlagEnabled,
  useSelectedMicrophoneDevice,
} from '@eluve/smart-blocks';

import { EluveAdminControls } from '../EluveAdminControls';
import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../machines/transcription/TranscriptionMachineProvider';

import { PlayingDisclaimer } from './PlayingDisclaimer';
import { UploadAudioFileButton } from './UploadAudioFileButton';

interface NewAppointmentContentProps {
  appointmentId: string;
  tenantId: string;
}

const AppointmentTemplateFragment = graphql(`
  fragment AppointmentTemplate on Appointments {
    __typename
    id
    initialPromptTemplateId
    initialLlmOutputTemplateId
  }
`);

export const NewAppointmentContent: React.FC<NewAppointmentContentProps> = ({
  appointmentId,
  tenantId,
}) => {
  const actor = useTranscriptionMachineActor();
  const isPlayingDisclaimer = useTranscriptionMachineSelector((state) =>
    state.matches({ InSession: 'PlayingDisclaimer' }),
  );
  const [selectedMicId, setSelectedMicId] = useSelectedMicrophoneDevice();
  const [hasPreviouslySelectedMicId, setHasPreviouslySelectedMicId] =
    useState(true);
  const [playDisclaimer, setPlayDisclaimer] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const postHog = usePostHog();

  const appointmentData = useCompleteFragment({
    fragment: AppointmentTemplateFragment,
    key: {
      id: appointmentId,
    },
  });

  const startSession = async () => {
    const microphoneName = (
      await navigator.mediaDevices.enumerateDevices()
    ).find((device) => device.deviceId === selectedMicId)?.label;

    postHog.capture('started_session', {
      appointmentId,
      microphoneName,
    });

    actor.send({
      type: 'START',
      appointmentId,
      deviceId: selectedMicId ?? undefined,
      microphoneName,
      playDisclaimer,
    });
  };

  const medicalNotesTemplatesData = useCompleteFragment({
    fragment: medicalNotesTemplatesFragment,
    key: QUERY_ROOT_ID,
  });

  const selectedTemplateId = appointmentData?.initialPromptTemplateId;
  const templates = medicalNotesTemplatesData?.activePromptTemplates ?? [];
  const selectedTemplate = templates.find(
    (template) => template.id === selectedTemplateId,
  );

  const isTemplateSelected =
    selectedTemplate?.outputType === LlmOutputTypesLookup.DYNAMIC_OUTPUT
      ? Boolean(appointmentData?.initialLlmOutputTemplateId)
      : true;

  const canStartSession =
    Boolean(selectedMicId) && isTemplateSelected && hasPreviouslySelectedMicId;

  const patient = useAppointmentPatient();
  const patientOverview = patient?.patient_overviews?.[0];

  const isPatientOverviewEnabled = useIsFeatureFlagEnabled('PATIENT_OVERVIEW');
  const shouldShowPatientOverview =
    isPatientOverviewEnabled && patientOverview?.content != null;
  const shouldShowSettings = settingsOpen || !shouldShowPatientOverview;

  const NewAppointmentForm = (
    <VStack wFull gap={6} className="justify-stretch pt-8">
      <VStack wFull gap={2}>
        <AppointmentName />
        <EluveAdminControls />
      </VStack>

      {shouldShowPatientOverview && (
        <PatientOverview patientOverviewId={patientOverview.id} />
      )}

      {shouldShowPatientOverview && <Divider className="m-0" />}

      <VStack wFull align="stretch" gap={0}>
        <Collapsible open={shouldShowSettings} onOpenChange={setSettingsOpen}>
          {shouldShowPatientOverview && (
            <HStack className="mb-5" align="stretch" justify="between" wFull>
              <P
                className={textStyles.body({
                  color: 'primary',
                  weight: 'semibold',
                  size: 'm',
                })}
              >
                Session Settings
              </P>
              <CollapsibleTrigger asChild>
                <NewButton
                  size="s"
                  text={`View ${shouldShowSettings ? 'Less' : 'More'} Options`}
                  type="outline"
                />
              </CollapsibleTrigger>
            </HStack>
          )}

          <AppointmentUserMicSettings
            isCompact={shouldShowPatientOverview}
            selectedMicId={selectedMicId}
            setSelectedMicId={setSelectedMicId}
            setHasPreviouslySelectedMicId={setHasPreviouslySelectedMicId}
          />

          <CollapsibleContent
            className='h-0 data-[state="open"]:h-auto data-[state="closed"]:overflow-hidden data-[state="open"]:pt-5'
            data-state={shouldShowSettings ? 'open' : 'closed'}
            forceMount
          >
            <NewAppointmentTemplateSelector
              tenantId={tenantId}
              appointmentId={appointmentId}
            />

            <TenantOrUserFeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <div className="mt-5">
                <AppointmentLanguageSelector inputLanguage outputLanguage />
              </div>
            </TenantOrUserFeatureFlaggedComponent>

            <FeatureFlaggedComponent flag="SHOW_PLAY_DISCLAIMER_OPTION">
              <InputLabel className="mt-5" label="Disclaimer">
                <HStack
                  className="rounded-md border border-input bg-background px-3 py-2"
                  gap={3}
                >
                  <Switch
                    id="play-disclaimer"
                    checked={playDisclaimer}
                    onCheckedChange={setPlayDisclaimer}
                  />
                  <Label
                    className="text-sm font-normal text-contentSecondary"
                    htmlFor="play-disclaimer"
                  >
                    Play disclaimer before recording
                  </Label>
                </HStack>
              </InputLabel>
            </FeatureFlaggedComponent>
          </CollapsibleContent>
        </Collapsible>
      </VStack>

      <HStack className="mb-8 mt-2" wFull justify="end">
        <FeatureFlaggedComponent flag="ALLOW_AUDIO_FILE_UPLOAD_FOR_APPOINTMENT">
          <UploadAudioFileButton appointmentId={appointmentId} />
        </FeatureFlaggedComponent>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <NewButton
                onClick={startSession}
                size="l"
                disabled={!canStartSession}
                text="Start Session"
                wFull
              />
            </TooltipTrigger>
            {(!selectedMicId || !hasPreviouslySelectedMicId) && (
              <TooltipContent>
                {'Select a microphone to start the session.'}
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </HStack>
    </VStack>
  );

  return (
    <ReskinContent variant="smallContainer">
      {isPlayingDisclaimer ? <PlayingDisclaimer /> : NewAppointmentForm}
    </ReskinContent>
  );
};
