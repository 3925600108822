import { useSubscription } from '@apollo/client';
import { useMemo } from 'react';

import { LlmOutputTypesEnum } from '@eluve/graphql-types';

import { useAppointmentContext } from './appointment.context';
import { listenForAppointmentLlmOutputsSubscription } from './insights.operations';

export const useListenForAppointmentLlmJobs = ({
  outputType,
  lastJobCompletedAt,
  onDataReceived,
}: {
  outputType: LlmOutputTypesEnum;
  lastJobCompletedAt: string | null;
  onDataReceived: () => void;
}) => {
  const { tenantId, appointmentId } = useAppointmentContext();

  const initialValue = useMemo(
    () => lastJobCompletedAt ?? new Date().toISOString(),
    [lastJobCompletedAt],
  );

  const { data } = useSubscription(listenForAppointmentLlmOutputsSubscription, {
    variables: {
      tenantId,
      appointmentId,
      outputType,
      initialValue,
    },
  });

  if (data?.appointmentLlmJobsStream.length === 1) {
    onDataReceived();
  }
};
