import { useQuery } from '@apollo/client';
import { match } from 'ts-pattern';

import { LlmOutputTypesEnum, LlmOutputTypesLookup } from '@eluve/graphql-types';
import {
  DifferentialDiagnosesOutputSchema,
  RedFlagsOutputSchema,
  differentialDiagnosesSchema,
  redFlagsOutputSchema,
} from '@eluve/llm-outputs';

import { useAppointmentContext } from './appointment.context';
import { getLatestAppointmentLlmOutputByOutputTypeQuery } from './insights.operations';

export type SupportedOutputTypes = Extract<
  LlmOutputTypesEnum,
  'DIFFERENTIAL_DIAGNOSES' | 'DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS'
>;

export interface useLatestAppointmentOutputProps {
  outputType: SupportedOutputTypes;
}

type AppointmentLlmOutput = {
  content: DifferentialDiagnosesOutputSchema | RedFlagsOutputSchema;
  llmOutputId: string;
  completedAt: string;
};

/**
 * Returns the latest appointment output of the given output type.
 *
 * Currently supports output types:
 *   - DIFFERENTIAL_DIAGNOSES
 *   - DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS
 *
 * When adding support for more output types, add a new case to the parsing
 * logic in the match statement below.
 */
export const useLatestAppointmentLlmOutput = ({
  outputType,
}: useLatestAppointmentOutputProps) => {
  const { appointmentId, tenantId } = useAppointmentContext();

  const { data, refetch } = useQuery(
    getLatestAppointmentLlmOutputByOutputTypeQuery,
    {
      variables: {
        tenantId,
        appointmentId,
        outputType,
      },
    },
  );

  const latestLlmOutput = data?.appointmentsByPk?.appointment_llm_outputs?.[0];

  if (!latestLlmOutput) {
    return { appointmentLlmOutput: null, refetch };
  }

  const parsedLlmOutput: AppointmentLlmOutput | null = match(
    latestLlmOutput.output.metadata?.prompt_template?.outputType,
  )
    .with(LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES, () => {
      const parsed = differentialDiagnosesSchema.safeParse(
        latestLlmOutput.output.content,
      );

      if (parsed.success && parsed.data.length > 1) {
        return {
          llmOutputId: latestLlmOutput.llmOutputId,
          content: parsed.data,
          completedAt: latestLlmOutput.output.completedAt!,
        };
      }

      return null;
    })
    .with(LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS, () => {
      const parsed = redFlagsOutputSchema.safeParse(
        latestLlmOutput.output.content,
      );

      if (parsed.success) {
        return {
          llmOutputId: latestLlmOutput.llmOutputId,
          content: parsed.data,
          completedAt: latestLlmOutput.output.completedAt!,
        };
      }

      return null;
    })
    .otherwise(() => {
      throw new Error('Unsupported output type');
    });

  return {
    appointmentLlmOutput: parsedLlmOutput,
    refetch,
  };
};
