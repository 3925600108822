import { useMutation, useSuspenseQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import {
  DynamicArtifactTemplateBuilder,
  templateBuilderStore,
} from '@eluve/blocks';
import { H4, HStack, PageTitle, VStack, toast } from '@eluve/components';
import { TemplateVariantCard } from '@eluve/frontend-feature-prompt-templates';
import { FragmentOf } from '@eluve/graphql.tada';
import { dynamicArtifactTemplateSchema } from '@eluve/llm-outputs';
import { formatHumanName } from '@eluve/utils';

import {
  getOutputTemplateQuery,
  llmOutputTemplateVariantFragment,
  setOutputTemplateAsDefaultMutation,
} from './operations';

export const OutputTemplateDetailsPage: React.FC<{
  showJsonSchema?: boolean;
}> = ({ showJsonSchema = true }) => {
  const { outputTemplateId, variantNumber, tenantId } = useParams() as {
    outputTemplateId: string;
    variantNumber?: string;
    tenantId?: string;
  };
  const [selectedVariantNumber, setSelectedVariantNumber] = useState<
    number | null
  >(null);
  const navigate = useNavigate();
  const location = useLocation();

  const isAdminDash = location.pathname.includes('admin');
  const adminBasePath = `/admin/output-templates/${outputTemplateId}`;
  const basePath = isAdminDash
    ? adminBasePath
    : `/tenants/${tenantId}/output-templates/${outputTemplateId}`;

  const {
    data: { llmOutputTemplatesByPk: outputTemplateData },
  } = useSuspenseQuery(getOutputTemplateQuery, {
    context: eluveAdminHasuraContext,
    variables: {
      id: outputTemplateId,
    },
  });

  const [setOutputTemplateAsDefault] = useMutation(
    setOutputTemplateAsDefaultMutation,
    {
      onError: () => {
        toast.error('We could not set this variant as the default.');
      },
    },
  );
  const selectedVariantTemplate = useMemo(() => {
    let variantTemplate: FragmentOf<
      typeof llmOutputTemplateVariantFragment
    > | null = null;

    if (variantNumber) {
      variantTemplate =
        outputTemplateData?.variants.find(
          (variant) => variant.variantNumber === Number(variantNumber),
        ) ?? null;
    }

    if (!variantTemplate) {
      variantTemplate =
        outputTemplateData?.variants.find(
          (variant) => variant.isCurrentDefault,
        ) ?? null;
    }

    if (!variantTemplate) {
      return undefined;
    }

    const parsed = dynamicArtifactTemplateSchema.safeParse(
      variantTemplate?.template,
    );
    setSelectedVariantNumber(variantTemplate.variantNumber);

    if (!parsed.success) {
      return undefined;
    }

    return parsed.data;
  }, [outputTemplateData?.variants, variantNumber]);

  useEffect(() => {
    if (selectedVariantTemplate) {
      templateBuilderStore.send({
        type: 'hydrateArtifact',
        artifactTemplate: selectedVariantTemplate,
        outputType: outputTemplateData?.llmOutputType ?? 'SOAP_NOTE',
        isImported: Boolean(
          outputTemplateData?.external_chart_templates?.length,
        ),
      });
    }
  }, [
    selectedVariantTemplate,
    outputTemplateData?.llmOutputType,
    variantNumber,
    outputTemplateData?.external_chart_templates?.length,
  ]);

  const currentDefaultVariantNum = outputTemplateData?.variants?.find(
    (t) => t.isCurrentDefault,
  )?.variantNumber;

  return (
    <>
      <PageTitle>
        {selectedVariantTemplate?.name ?? outputTemplateData?.name}
      </PageTitle>
      <HStack gap={8} align="start">
        <DynamicArtifactTemplateBuilder
          showJsonSchema={showJsonSchema}
          isReadOnly={true}
        />

        <VStack className="flex-1 sm:min-w-60 md:min-w-72">
          <H4>Variants</H4>
          {(outputTemplateData?.variants ?? []).map((variant) => (
            <TemplateVariantCard
              isSelected={variant.variantNumber === selectedVariantNumber}
              key={variant.variantNumber}
              onClick={() => {
                navigate(`${basePath}/variant/${variant.variantNumber}`);
              }}
              onDuplicateClick={() => {
                navigate(
                  `${adminBasePath}/variant-add/${variant.variantNumber}`,
                );
              }}
              onSetAsDefaultClick={() => {
                setOutputTemplateAsDefault({
                  variables: {
                    outputTemplateId,
                    variantNumber: variant.variantNumber,
                  },
                  optimisticResponse: () => ({
                    updateLlmOutputTemplateVariants: {
                      returning: [
                        {
                          __typename: 'LlmOutputTemplateVariants' as const,
                          outputTemplateId,
                          variantNumber: currentDefaultVariantNum!,
                          isCurrentDefault: false,
                        },
                      ],
                    },
                    updateLlmOutputTemplateVariantsByPk: {
                      __typename: 'LlmOutputTemplateVariants' as const,
                      outputTemplateId,
                      variantNumber: variant.variantNumber,
                      isCurrentDefault: true,
                    },
                  }),
                });
              }}
              variant={{
                ...variant,
                createdBy: variant.creator
                  ? formatHumanName(
                      variant.creator.firstName,
                      variant.creator.lastName,
                    )
                  : undefined,
              }}
            />
          ))}
        </VStack>
      </HStack>
    </>
  );
};
