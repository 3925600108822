// Patched up version of usePermission
// Source: https://github.com/streamich/react-use/blob/master/src/usePermission.ts
import { useEffect, useState } from 'react';

import { isAndroidWebView } from '@eluve/utils';

type IState = PermissionState | '';

function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  ...args: Parameters<T['addEventListener']> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(
      ...(args as Parameters<HTMLElement['addEventListener']>),
    );
  }
}

function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args:
    | Parameters<T['removeEventListener']>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(
      ...(args as Parameters<HTMLElement['removeEventListener']>),
    );
  }
}

type ExtendedPermissionDescriptor = Omit<PermissionDescriptor, 'name'> & {
  name: 'push' | 'midi' | 'camera' | 'microphone' | 'speaker';
};

export const usePermission = (
  permissionDesc: ExtendedPermissionDescriptor,
): IState => {
  const [state, setState] = useState<IState>('');

  useEffect(() => {
    let mounted = true;
    let permissionStatus: PermissionStatus | null = null;

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(() => permissionStatus?.state ?? '');
    };

    const getAudioDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter(
          (device) => device.kind === 'audioinput' && device.deviceId,
        );
        return audioDevices;
      } catch (e) {
        return [];
      }
    };

    const checkNavigatorPermissions = async () => {
      if (navigator.permissions) {
        try {
          const status = await navigator.permissions.query(
            permissionDesc as PermissionDescriptor,
          );
          permissionStatus = status;
          on(permissionStatus, 'change', onChange);
          onChange();
        } catch (e) {
          // do nothing
        }
      } else {
        const audioDevices = await getAudioDevices();
        if (audioDevices.length) {
          setState('granted');
        }
      }
    };

    checkNavigatorPermissions();

    return () => {
      permissionStatus && off(permissionStatus, 'change', onChange);
      mounted = false;
      permissionStatus = null;
    };
  }, [permissionDesc]);

  // Android permissions are managed through Android Permissioning Model. If Eluve is launched in Webview, WebView should have RECORD_AUDIO permission.
  if (isAndroidWebView) {
    return 'granted';
  }
  return state;
};
