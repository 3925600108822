import { useReactTable } from '@tanstack/react-table';
import React from 'react';
import { match } from 'ts-pattern';

import {
  ColDefBuilder,
  ServerDataTable,
  TableSearch,
  VStack,
  useDataTableForQuery,
} from '@eluve/components';
import { PatientsOrderBy } from '@eluve/graphql-types';
import { ResultOf, graphql } from '@eluve/graphql.tada';

const searchPatients = graphql(`
  query searchPatients(
    $filter: PatientsBoolExp
    $offset: Int
    $orderBy: [PatientsOrderBy!]
    $limit: Int
  ) {
    patients(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      id
      firstName
      lastName
      dateOfBirth
      sex
      metrics {
        __typename
        id
        appointmentCount
        mostRecentAppointmentStartedAt
      }
    }
    patientsAggregate(where: $filter) {
      __typename
      aggregate {
        __typename
        count
      }
    }
  }
`);

type Row = Omit<
  ResultOf<typeof searchPatients>['patients'][number],
  'appointmentsAggregate' | 'metrics'
> & { appointmentCount: number; lastAppointmentDate: string | null };

const columns = new ColDefBuilder<Row>()
  .detailsLink((r) => `${r.id}`, 'Details')
  .defaultSortable('firstName', { label: 'First Name', isPrivate: true })
  .defaultSortable('lastName', { label: 'Last Name', isPrivate: true })
  .dateSortable('lastAppointmentDate', 'Last Appointment')
  .dateSortable('dateOfBirth', { label: 'Date of Birth', isPrivate: true })
  .defaultSortable('appointmentCount', '# Appointments')
  .build();

const convertSearchResultsToRows = (
  results: ResultOf<typeof searchPatients>,
): Row[] => {
  return results.patients.map((p) => {
    const { metrics, ...rest } = p;

    return {
      ...rest,
      appointmentCount: metrics?.appointmentCount ?? 0,
      lastAppointmentDate: metrics?.mostRecentAppointmentStartedAt ?? null,
    };
  });
};

export const PatientsList: React.FC = () => {
  const { data, rows, reactTableOptions, setSearch, search, isPending } =
    useDataTableForQuery({
      query: searchPatients,
      convertToRows: convertSearchResultsToRows,
      convertSearchParamsToVariables: ({ offset, limit, search, sorting }) => ({
        offset,
        limit,
        filter: search
          ? {
              _or: [
                {
                  firstName: { _ilike: `%${search}%` },
                },
                {
                  lastName: { _ilike: `%${search}%` },
                },
              ],
            }
          : {},

        orderBy:
          sorting && sorting.length
            ? sorting.map(({ desc, id }) => ({
                ...match<keyof Row, PatientsOrderBy>(id)
                  .with('lastAppointmentDate', () => ({
                    metrics: {
                      mostRecentAppointmentStartedAt: desc
                        ? 'DESC_NULLS_LAST'
                        : 'ASC',
                    },
                  }))
                  .with('appointmentCount', () => ({
                    metrics: {
                      appointmentCount: desc ? 'DESC_NULLS_LAST' : 'ASC',
                    },
                  }))
                  .otherwise(() => ({
                    [id]: desc ? 'DESC_NULLS_LAST' : 'ASC',
                  })),
              }))
            : null,
      }),
    });

  const rowCount = data?.patientsAggregate?.aggregate?.count ?? 0;

  const table = useReactTable({
    data: rows,
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <VStack gap={0} className="rounded-md border p-2">
      <TableSearch value={search ?? ''} onChange={setSearch} />
      <ServerDataTable<Row> table={table} isPending={isPending} />
    </VStack>
  );
};
