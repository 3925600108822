import { graphql } from '@eluve/graphql.tada';

import { appointmentActivityFragment } from './useAppointmentActivity';
import { appointmentStatusFragment } from './useAppointmentStatus';

export const externalChartFragment = graphql(`
  fragment externalChart on ExternalCharts @_unmask {
    __typename
    id
    signedAt
    chartUrl
    externalChartId
    externalPatientId
    externalAppointmentId
    appointmentId
    patientId
  }
`);

export const outputTemplateMetadataFragment = graphql(`
  fragment OutputTemplateMetadata on LlmOutputsMetadata @_unmask {
    __typename
    llmOutputId
    tenantId
    output_template {
      __typename
      id
      name
      llmOutputType
    }
    output_variant {
      __typename
      outputTemplateId
      variantNumber
      template
    }
  }
`);

export const promptTemplateMetadataFragment = graphql(`
  fragment PromptTemplateMetadata on LlmOutputsMetadata @_unmask {
    __typename
    llmOutputId
    tenantId
    prompt_template {
      __typename
      id
      name
      outputType
    }
    prompt_variant {
      __typename
      promptTemplateId
      variantNumber
      template
    }
  }
`);

export const externalChartsFragment = graphql(
  `
    fragment externalCharts on Appointments @_unmask {
      __typename
      id
      external_charts {
        ...externalChart
      }
    }
  `,
  [externalChartFragment],
);

export const latestHumanSOAPNoteFragment = graphql(
  `
    fragment LatestHumanSOAPNote on Appointments @_unmask {
      __typename
      id
      humanOutputs(
        where: { output: { outputType: { _eq: SOAP_NOTE } } }
        limit: 1
        orderBy: { output: { updatedAt: DESC } }
      ) {
        __typename
        humanOutputId
        appointmentId
        output {
          __typename
          id
          content
          userId
          editedFromLlmOutputId
          updatedAt
          llm_output {
            __typename
            id
            metadata {
              ...OutputTemplateMetadata
            }
          }
        }
      }
    }
  `,
  [outputTemplateMetadataFragment],
);

export const latestHumanSOAPNoteWithPromptTemplateFragment = graphql(
  `
    fragment LatestHumanSOAPNoteWithPromptTemplate on Appointments @_unmask {
      __typename
      id
      humanOutputs(
        where: { output: { outputType: { _eq: SOAP_NOTE } } }
        limit: 1
        orderBy: { output: { updatedAt: DESC } }
      ) {
        __typename
        humanOutputId
        appointmentId
        output {
          __typename
          id
          content
          userId
          editedFromLlmOutputId
          updatedAt
          llm_output {
            __typename
            id
            modelPromptTemplate {
              __typename
              id
              model_args {
                __typename
                id
                modelType
                args
              }
            }
            metadata {
              ...PromptTemplateMetadata
              ...OutputTemplateMetadata
            }
          }
        }
      }
    }
  `,
  [promptTemplateMetadataFragment, outputTemplateMetadataFragment],
);

export const appointmentTranscriptFragment = graphql(`
  fragment appointmentTranscription on Appointments @_unmask {
    __typename
    id
    transcription {
      __typename
      id
      transcript
      updatedAt
    }
  }
`);

export const getLatestTranscriptQuery = graphql(
  `
    query getLatestTranscript($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...appointmentTranscription
      }
    }
  `,
  [appointmentTranscriptFragment],
);

export const appointmentSegmentsFragment = graphql(`
  fragment appointmentSegments on Appointments @_unmask {
    __typename
    id
    segments {
      __typename
      id
      transcript
      recordingStartedAt
      recordingDuration
      updatedAt
    }
  }
`);

export const appointmentReadonlyDataFragment = graphql(`
  fragment ReadonlyData on Appointments {
    __typename
    id
    userId
    is_closed_for_modification
  }
`);

export const appointmentTranscriptAndSegmentsFragment = graphql(
  `
    fragment appointmentTranscriptionAndSegments on Appointments @_unmask {
      __typename
      id
      ...appointmentSegments
      ...appointmentTranscription
    }
  `,
  [appointmentSegmentsFragment, appointmentTranscriptFragment],
);

export const getCurrentSummary = graphql(
  `
    query getCurrentSummary($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...LatestHumanSOAPNote
      }
    }
  `,
  [latestHumanSOAPNoteFragment],
);

export const allCompletedSoapNotesFragment = graphql(
  `
    fragment AllCompletedSoapNotes on Appointments @_unmask {
      __typename
      id
      appointment_llm_outputs(
        where: {
          output: {
            _and: [
              { status: { _eq: COMPLETED } }
              {
                _or: [
                  {
                    modelPromptTemplate: {
                      template: { outputType: { _eq: SOAP_NOTE } }
                    }
                  }
                  { outputTemplate: { llmOutputType: { _eq: SOAP_NOTE } } }
                ]
              }
            ]
          }
        }
        orderBy: { output: { completedAt: ASC_NULLS_LAST } }
      ) {
        __typename
        llmOutputId
        output {
          __typename
          id
          content
          status
          completedAt
          modelPromptTemplate {
            __typename
            id
          }
          metadata {
            ...PromptTemplateMetadata
            ...OutputTemplateMetadata
          }
        }
      }
    }
  `,
  [promptTemplateMetadataFragment, outputTemplateMetadataFragment],
);

export const getCurrentSummaryForEluveAdmin = graphql(
  `
    query getCurrentSummaryForEluveAdmin(
      $tenantId: uuid!
      $appointmentId: uuid!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...LatestHumanSOAPNoteWithPromptTemplate
      }
    }
  `,
  [latestHumanSOAPNoteWithPromptTemplateFragment],
);

export const getAllSummaries = graphql(
  `
    query getAllSummaries($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...AllCompletedSoapNotes
      }
    }
  `,
  [allCompletedSoapNotesFragment],
);

export const updateSoapNote = graphql(`
  mutation updateSoapNote(
    $tenantId: uuid!
    $humanOutputId: uuid!
    $content: jsonb!
    $llmOutputId: uuid!
  ) {
    updateHumanOutputsByPk(
      pkColumns: { tenantId: $tenantId, id: $humanOutputId }
      _set: { content: $content, editedFromLlmOutputId: $llmOutputId }
    ) {
      __typename
      id
      content
      editedFromLlmOutputId
    }
  }
`);

export const updateAppointmentPromptTemplateId = graphql(`
  mutation updateAppointmentPromptTemplateId(
    $tenantId: uuid!
    $appointmentId: uuid!
    $initialPromptTemplateId: uuid!
  ) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $appointmentId }
      _set: { initialPromptTemplateId: $initialPromptTemplateId }
    ) {
      id
      __typename
      initialPromptTemplateId
    }
  }
`);

export const updateAppointmentOutputTemplateId = graphql(`
  mutation updateAppointmentOutputTemplateId(
    $tenantId: uuid!
    $appointmentId: uuid!
    $initialLlmOutputTemplateId: uuid
  ) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $appointmentId }
      _set: { initialLlmOutputTemplateId: $initialLlmOutputTemplateId }
    ) {
      id
      __typename
      initialLlmOutputTemplateId
    }
  }
`);

export const appointmentLlmOutputTemplateFragment = graphql(`
  fragment llmOutputTemplate on Appointments @_unmask {
    llm_output_template {
      id
      __typename
      name
      external_chart_templates {
        __typename
        id
        externalChartTemplateId
      }
    }
  }
`);

export const appointmentBillingCodeRecommendationsFragment = graphql(`
  fragment appointmentBillingCodeRecommendations on Appointments @_unmask {
    __typename
    id
    billing_code_recommendations(
      where: {
        visible: { _eq: true }
        recommendationSource: { _isNull: false }
      }
    ) {
      __typename
      appointmentId
      codeId
      id
      code
      codeType
      label
      supportingEvidence
      excerpts
    }
  }
`);

export const dismissBillingCodeRecommendationMutation = graphql(`
  mutation dismissBillingCodeRecommendation($id: uuid!) {
    updateAppointmentBillingCodeRecommendationsByPk(
      pkColumns: { id: $id }
      _set: { visible: false }
    ) {
      __typename
      id
      visible
    }
  }
`);

export const getBillingCodeRecommendationsQuery = graphql(
  `
    query getBillingCodeRecommendations(
      $tenantId: uuid!
      $appointmentId: uuid!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...appointmentBillingCodeRecommendations
      }
    }
  `,
  [appointmentBillingCodeRecommendationsFragment],
);

export const baseAppointmentInfoFragment = graphql(`
  fragment baseAppointmentInfo on Appointments @_unmask {
    name
    startDate
    startedAt
    endDate
    completedAt
    locationId
    initialPromptTemplateId
    initialLlmOutputTemplateId
    patientId
    userId
    inputLanguage
    outputLanguage
    totalDuration
    updatedAt
    session_type {
      __typename
      id
      name
    }
    external_appointments_infos {
      __typename
      id
      externalAppointmentId
    }
  }
`);

export const AppointmentPatientIdFragment = graphql(`
  fragment AppointmentPatientId on Appointments {
    __typename
    id
    patientId
  }
`);

export const patientOverviewFragment = graphql(`
  fragment PatientOverview on PatientOverviews @_unmask {
    id
    __typename
    isCurrent
    content
  }
`);

export const basicPatientDetailsFragment = graphql(`
  fragment basicDetails on Patients @_unmask {
    __typename
    id
    firstName
    lastName
    dateOfBirth
  }
`);

export const patientFragment = graphql(
  `
    fragment patient on Patients @_unmask {
      __typename
      ...basicDetails
      patient_overviews(where: { isCurrent: { _eq: true } }, limit: 1) {
        ...PatientOverview
      }
      external_patients_info {
        __typename
        id
        externalPatientId
        cellPhoneNumber
        homePhoneNumber
        workPhoneNumber
        email
        external_ehr {
          __typename
          id
          vendor
          domain
        }
      }
    }
  `,
  [patientOverviewFragment, basicPatientDetailsFragment],
);

export const appointmentPatientFragment = graphql(
  `
    fragment appointmentPatient on Appointments @_unmask {
      patient {
        ...patient
      }
    }
  `,
  [patientFragment],
);

export const doctorInteractionFragment = graphql(`
  fragment doctorInteraction on AppointmentDoctorInteractions @_unmask {
    __typename
    appointmentId
    additionalNotes
    noteSignedAt
    externalChartUrl
    updatedAt
    user {
      __typename
      id
      firstName
      lastName
    }
    appointment {
      __typename
      id
      is_closed_for_modification
    }
  }
`);

export const appointmentDoctorInteractionFragment = graphql(
  `
    fragment appointmentDoctorInteraction on Appointments @_unmask {
      __typename
      id
      doctor_interaction {
        ...doctorInteraction
      }
    }
  `,
  [doctorInteractionFragment],
);

export const appointmentLocationFragment = graphql(`
  fragment appointmentLocation on Appointments @_unmask {
    locationId
    location {
      __typename
      id
      name
    }
  }
`);

export const appointmentLanguagesFragment = graphql(`
  fragment SessionType on Appointments @_unmask {
    __typename
    id
    status
    inputLanguage
    outputLanguage
  }
`);

export const activeAppointmentLayoutFragment = graphql(
  `
    fragment ActiveAppointmentLayout on Appointments {
      ...appointmentStatus
      ...baseAppointmentInfo
      ...appointmentPatient
      ...appointmentDoctorInteraction
      ...appointmentActiveState
      ...appointmentTranscriptionAndSegments
      ...appointmentLocation
      ...llmOutputTemplate
      ...ReadonlyData
    }
  `,
  [
    appointmentStatusFragment,
    baseAppointmentInfoFragment,
    appointmentPatientFragment,
    appointmentDoctorInteractionFragment,
    appointmentActivityFragment,
    appointmentTranscriptAndSegmentsFragment,
    appointmentLocationFragment,
    appointmentLlmOutputTemplateFragment,
    appointmentReadonlyDataFragment,
  ],
);

export const signedAppointmentLayoutFragment = graphql(
  `
    fragment SignedAppointmentLayout on Appointments {
      ...baseAppointmentInfo
      ...appointmentPatient
      ...appointmentDoctorInteraction
      ...externalCharts
      ...ReadonlyData
    }
  `,
  [
    baseAppointmentInfoFragment,
    appointmentPatientFragment,
    appointmentDoctorInteractionFragment,
    externalChartsFragment,
    appointmentReadonlyDataFragment,
  ],
);

export const getAppointmentDetailsQuery = graphql(
  `
    query getAppointmentDetails($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...ActiveAppointmentLayout
        ...SignedAppointmentLayout
      }
    }
  `,
  [activeAppointmentLayoutFragment, signedAppointmentLayoutFragment],
);

export const listenAppointmentStatusSubscription = graphql(
  `
    subscription listenAppointmentStatus(
      $tenantId: uuid!
      $appointmentId: uuid!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        chiefComplaint
        ...appointmentActiveState
        ...appointmentStatus
      }
    }
  `,
  [appointmentActivityFragment, appointmentStatusFragment],
);
