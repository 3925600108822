import { LlmOutputTypesEnum } from '@eluve/graphql-types';

export const LlmOutputTypeLabels: Record<LlmOutputTypesEnum, string> = {
  BILLING_CODE_RECOMMENDATION: 'Billing Code Recommendation',
  CLIENT_RECAP: 'Client Recap',
  DIFFERENTIAL_DIAGNOSES: 'Differential Diagnoses',
  FACT_VERIFICATION: 'Fact Verification',
  GENERIC_OUTPUT: 'Generic Output',
  REVIEW_OF_SYSTEMS: 'Review of Systems',
  SOAP_NOTE: 'SOAP Note',
  PFSH: 'Past, Family and Social History',
  FACT_GENERATION: 'Fact Generation',
  CHIEF_COMPLAINT: 'Chief Complaint',
  PII_REMOVAL: 'PII Removal',
  DYNAMIC_OUTPUT: 'Dynamic Output',
  TRANSLATION: 'Translation',
  MEDICINE_RECOMMENDATION: 'Medicine Recommendation',
  PATIENT_OVERVIEW: 'Patient Overview',
  DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS: 'Differential Diagnoses + Red Flags',
  DIAGNOSES_JUSTIFICATION: 'Diagnoses Justification',
};
