import { useReactTable } from '@tanstack/react-table';
import isNil from 'lodash/isNil';
import meanBy from 'lodash/meanBy';
import { CopyPlusIcon } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Button,
  ColDefBuilder,
  DataTableFilterField,
  ServerDataTable,
  TableSearch,
  VStack,
  useDataTableForQuery,
} from '@eluve/components';
import {
  LlmOutputTypesLookup,
  PromptTemplatesBoolExp,
  PromptTemplatesOrderBy,
} from '@eluve/graphql-types';
import { ResultOf } from '@eluve/graphql.tada';

import { TogglePromptTemplateActiveAction } from './TogglePromptTemplateActiveAction';
import { searchPromptTemplatesQuery } from './prompt.operations';

type PromptTemplateRow = {
  id: string;
  name: string;
  type: string;
  feedbackReceived: number;
  averageRating: number | null;
  isActive: boolean;
  isCurrentDefault: boolean;
  createdAt: string;
  hasDbTemplate: boolean;
  usersWithDefault: number;
  variantCount: number;
};

const columns = new ColDefBuilder<PromptTemplateRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .defaultSortable('type')
  .defaultBoolean('isCurrentDefault', 'Is Default')
  .defaultBoolean('isActive', 'Active')
  .dateSortable('createdAt', 'Created At')
  .colDef({
    id: 'feedbackReceived',
    accessorKey: 'feedbackReceived',
    header: '# Feedback',
    cell: ({ row }) => row.original.feedbackReceived,
  })
  .colDef({
    id: 'averageRating',
    accessorKey: 'averageRating',
    header: 'Average Rating',
    cell: ({ row }) => row.original.averageRating?.toFixed(2) ?? '',
  })
  .defaultSortable('variantCount', '# Variants')
  .defaultSortable('usersWithDefault', '# User Default')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => {
      const showCloneOption =
        row.original.hasDbTemplate && row.original.isActive;

      return (
        <Box hStack gap={1}>
          <TogglePromptTemplateActiveAction
            promptTemplateId={row.original.id}
          />
          {showCloneOption && (
            <TooltipLabel
              label={`Create a new Prompt Template using '${row.original.name}' as a starting point`}
            >
              <Link to={`./clone/${row.original.id}`}>
                <Button size="icon" variant="outline">
                  <CopyPlusIcon />
                </Button>
              </Link>
            </TooltipLabel>
          )}
        </Box>
      );
    },
  })
  .build();

const convertSearchResultsToRows = (
  results: ResultOf<typeof searchPromptTemplatesQuery>,
): PromptTemplateRow[] => {
  return results.promptTemplates.map<PromptTemplateRow>((p) => {
    const allFeedback = p.prompt_models
      .flatMap((pm) => pm.llm_outputs)
      .flatMap((lo) => lo.feedback);

    const averageRating = meanBy(
      allFeedback.filter((f) => !isNil(f.rating)),
      (f) => f.rating ?? 0,
    );

    const currentDefaultVariant = p.prompt_template_variants.find(
      (variant) => variant.isCurrentDefault,
    );

    return {
      id: p.id,
      name: p.name,
      type: p.outputType,
      createdAt: p.createdAt,
      feedbackReceived: allFeedback.length,
      averageRating: isNaN(averageRating) ? null : averageRating,
      isActive: p.isActive ?? false,
      isCurrentDefault: p.isCurrentDefault,
      hasDbTemplate: !isNil(currentDefaultVariant?.template),
      usersWithDefault: p.userSettingsAggregate?.aggregate?.count ?? 0,
      variantCount: p.promptTemplateVariantsAggregate?.aggregate?.count ?? 0,
    };
  });
};

export const PromptTemplatesList: React.FC = () => {
  const filterFields: DataTableFilterField<PromptTemplateRow>[] = [
    {
      id: 'type',
      label: 'Output Type',
      options: Object.keys(LlmOutputTypesLookup)
        .sort()
        .map((type) => ({
          label: type,
          value: type,
        })),
    },
    {
      id: 'isActive',
      label: 'Active',
      options: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
    },
  ];

  const { data, rows, setSearch, search, reactTableOptions, isPending } =
    useDataTableForQuery({
      query: searchPromptTemplatesQuery,
      filterFields,
      convertToRows: convertSearchResultsToRows,
      convertSearchParamsToVariables: ({
        offset,
        limit,
        search,
        sorting,
        filters,
      }) => {
        const filterConditions: PromptTemplatesBoolExp[] = [];

        if (filters.type) {
          filterConditions.push({
            outputType: {
              _in: filters.type as (keyof typeof LlmOutputTypesLookup)[],
            },
          });
        }

        if (filters.isActive) {
          filterConditions.push({
            isActive: { _eq: filters.isActive[0] === 'true' },
          });
        }

        if (search) {
          filterConditions.push({
            name: {
              _ilike: `%${search}%`,
            },
          });
        }

        return {
          offset,
          limit,
          filter: {
            _and: filterConditions,
          },
          orderBy:
            sorting && sorting.length
              ? sorting.map(({ desc, id }) => ({
                  ...match<keyof PromptTemplateRow, PromptTemplatesOrderBy>(id)
                    .with('type', () => ({ outputType: desc ? 'DESC' : 'ASC' }))
                    .with('usersWithDefault', () => ({
                      user_settingsAggregate: {
                        count: desc ? 'DESC' : 'ASC',
                      },
                    }))
                    .with('variantCount', () => ({
                      prompt_template_variantsAggregate: {
                        count: desc ? 'DESC' : 'ASC',
                      },
                    }))
                    .otherwise(() => ({
                      [id]: desc ? 'DESC' : 'ASC',
                    })),
                }))
              : null,
        };
      },
    });

  const rowCount = data?.promptTemplatesAggregate?.aggregate?.count;

  const table = useReactTable({
    data: rows,
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <VStack gap={2} className="rounded-md border">
      <TableSearch
        value={search ?? ''}
        onChange={setSearch}
        placeholder="Search by name..."
      />
      <ServerDataTable<PromptTemplateRow>
        table={table}
        filterFields={filterFields}
        isPending={isPending}
      />
    </VStack>
  );
};
